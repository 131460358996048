import { Component } from '@angular/core';
import { ToastService } from '../toast.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  showToast = false;
  message = '';
  toastClass = '';

  constructor(private toastService: ToastService) {
    this.toastService.toast$.subscribe((toast) => {
      if (toast) {
        this.message = toast.message;
        this.toastClass = toast.type;
        this.showToast = true;

        setTimeout(() => {
          this.showToast = false;
        }, 3000); // Hide toast after 3 seconds
      }
    });
  }
}
