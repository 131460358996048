<!-- places-search.component.html -->
<div
  class="col-lg-12 col-12 rounded12 d-flex justify-content-center"
  *ngIf="page == 'header'"
>
  <input
    type="text"
    #searchBox
    placeholder="Search Electrician"
    class="p-2 ms-0 ms-lg-5 col-10 rounded12 search-bg-elec text-white bordernone"
  />
  <img src="../../assets/Search.png" class="search-image" />
</div>

<div
  class="col-lg-12 col-12 d-flex justify-content-center elect-page mb-3"
  *ngIf="page == 'electrician'"
>
  <input
    type="text"
    #searchBox
    placeholder="Search your place"
    class="p-2 col-12 rounded-2 text-black bordernone elect-page"
  />
  <!-- <img src="../../assets/Search.png" class="search-image" /> -->
</div>

<div class="search-container py-4" *ngIf="page == 'home'">
  <input
    type="text"
    placeholder="Search electrician for your local area"
    #searchBox
  />
  <button type="submit" class="search-btn">
    <img src="/assets/search-icon.png" alt="Search Icon" class="ms-1 ms-lg-0" />
  </button>
  <img
    src="/assets/map-icon.png"
    alt="World Map Icon"
    class="map-icon ms-3 ps-lg-0"
  />
</div>
