<div
  class="d-flex col-12 justify-content-center align-items-center py-0 py-lg-5 my-0 mb-5 my-lg-5 flex-column"
>
  <div
    class="d-flex col-lg-8 col-12 bgwhiteshadow py-3 py-lg-5 mt-3 mt-lg-5 justify-content-between"
  >
    <div class="flex-column ps-5 lh35 pt-3">
      <div class="font24 fw400">Signin or Create Account</div>
      <div class="font14 fw400 lh25 text-light-brown">
        To access your Addresses, Orders & Wishlist.
      </div>
    </div>
    <div class="pe-5">
      <img src="../../assets/footerlogo.png" alt="logo" class="logo" />
    </div>
  </div>
  <div class="d-flex flex-column col-12 align-items-center">
    <div
      class="bgorangeshadow py-2 py-lg-5 d-flex col-lg-8 col-12"
      *ngIf="!otpRequested"
    >
      <!-- Mobile Number Input for OTP Generation -->
      <div
        class="d-flex py-5 flex-column col-12 justify-content-center align-items-center"
      >
        <div class="text-white font20 fw400 lh25 col-8">
          Enter Your Mobile Number
        </div>
        <div
          class="text-black font20 fw400 lh25 col-8 flex-row d-flex py-3 rounded4"
        >
          <div class="col-2 col-lg-1 bgwhite py-3 ps-3 rounded4">+91</div>
          <form #myForm="ngForm" class="col-10 col-lg-11 rounded4">
            <input
              type="text"
              id="mobilenumber"
              name="mobilenumber"
              [(ngModel)]="userPhoneNumber"
              #mobilenumber="ngModel"
              required
              pattern="^\d{10}$"
              (ngModelChange)="onInputChange()"
              [maxlength]="10"
              class="bordernone col-12 py-3 ps-3 bgwhite rounded4"
            />
          </form>
        </div>
        <div class="text-white font12 fw400 justify-content-around col-8">
          By continuing, you agree to our Terms of Service  and Privacy & Legal
          Policy
        </div>
        <div class="justify-content-around col-8">
          <button
            [disabled]="!myForm.valid"
            id="submitBtn"
            class="font18 fw400 rounded4 col-12 py-3 bordernone mt-3"
            (click)="getOTP()"
          >
            Request OTP
          </button>
        </div>
      </div>
    </div>
    <!-- OTP Verification -->
    <div
      class="bgorangeshadow py-2 py-lg-5 d-flex col-lg-8 col-12"
      *ngIf="otpRequested"
    >
      <div
        class="d-flex py-5 flex-column col-12 justify-content-center align-items-center"
      >
        <div class="text-white font20 fw400 lh25 col-8">
          An SMS with 6 digit OTP was sent to your number
        </div>
        <div
          class="text-black font20 fw400 lh25 col-8 flex-row d-flex py-3 rounded4"
        >
          <form #otpForm="ngForm" class="col-12 rounded4">
            <input
            type="text"
            class="form-control otp-input"
            id="otp"
            name="otp"
            [(ngModel)]="otp"
            required
            maxlength="6"
            #otpInput="ngModel"
            placeholder="------"
          />
          </form>
        </div>
        <div
          class="text-white font12 fw400 justify-content-end align-items-end col-8"
        >
          Waiting for OTP ...
        </div>
        <div class="justify-content-around col-8">
          <button
            [disabled]="!otpForm.valid"
            id="verifyOTP"
            class="font18 fw400 rounded4 col-12 py-3 bordernone mt-3"
            (click)="validateOtp()"
          >
            Verify OTP
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
