<div class="bgimage py-5">
  <!-- <div class="d-flex justify-content-center align-items-center text-white py-5">
    <span class="font24 fw400">Why Choose Power Bihar?</span>
  </div> -->
  <div class="d-flex justify-content-center col-12 flex-column align-items-center">
    <div
      class="d-flex justify-content-around col-8 text-center  flex-column flex-lg-row gap-5 mt-4  minh text-center"
    >
      <div
        class=""
        (mouseenter)="isHovered = true"
        (mouseleave)="isHovered = false"
      >
        <img src="/assets/wide-range.png" alt="" class="" *ngIf="!isHovered" />
        <img
          src="/assets/wide-range_hover.png"
          alt=""
          class=""
          *ngIf="isHovered"
        />
        <p class="text-white font13 lh25 fw400 mt-2 mb-0">
          Wide Range Of Products
        </p>
        <p
          *ngIf="isHovered"
          class="font11 fw400 lh17 text-white text-center col-12"
        >
          From cables and wires to lighting <br />
          solutions and electrical appliances, <br />
          we have everything you need.
        </p>
      </div>
      <div
        class=""
        (mouseenter)="isHovered1 = true"
        (mouseleave)="isHovered1 = false"
      >
        <img src="/assets/quality.png" alt="" *ngIf="!isHovered1" />
        <img src="/assets/quality_hover.png" alt="" *ngIf="isHovered1" />
        <p class="text-white font13 lh25 fw400 mt-2 mb-0">Quality Assurance</p>
        <p
          *ngIf="isHovered1"
          class="font11 fw400 lh17 text-white text-center col-12"
        >
          We source our products from top <br />
          brands and trusted suppliers <br />
          to ensure the best quality.
        </p>
      </div>
      <div
        class=""
        (mouseenter)="isHovered2 = true"
        (mouseleave)="isHovered2 = false"
      >
        <img src="/assets/affordable.png" alt="" *ngIf="!isHovered2" />
        <img src="/assets/affordable_hover.png" alt="" *ngIf="isHovered2" />
        <p class="text-white font13 lh25 fw400 mt-2 mb-0">Affordable Prices</p>
        <p
          *ngIf="isHovered2"
          class="font11 fw400 lh17 text-white text-center col-12"
        >
          Enjoy competitive pricing on all our <br />
          products without compromising <br />
          on quality.
        </p>
      </div>
      <div
        class=""
        (mouseenter)="isHovered3 = true"
        (mouseleave)="isHovered3 = false"
      >
        <img src="/assets/fast-delivery.png" alt="" *ngIf="!isHovered3" />
        <img src="/assets/fast-delivery_hover.png" alt="" *ngIf="isHovered3" />
        <p class="text-white font13 lh25 fw400 mt-2 mb-0">Fast Delivery</p>
        <p
          *ngIf="isHovered3"
          class="font11 fw400 lh17 text-white text-center col-12"
        >
          we offer quick and reliable delivery<br />
          services across south bihar.
        </p>
      </div>
      <div
        class=""
        (mouseenter)="isHovered4 = true"
        (mouseleave)="isHovered4 = false"
      >
        <img src="/assets/customer-support.png" alt="" *ngIf="!isHovered4" />
        <img
          src="/assets/customer-support_hover.png"
          alt=""
          *ngIf="isHovered4"
        />
        <p class="text-white font13 lh25 fw400 mt-2 mb-0">Customer Support</p>
        <p
          *ngIf="isHovered4"
          class="font11 fw400 lh17 text-white text-center col-12"
        >
          Our dedicated support team is here<br />
          to help you with any queries<br />
          or issues.
        </p>
      </div>
    </div>
    <div class="col-12 d-flex align-items-center justify-content-center">
      <img src="../../assets/curve-line.png" alt="" class="col-10 col-lg-6" />
    </div>
  </div>
</div>
