<div
  class="d-flex flex-column col-12 align-items-center justify-content-center py-5"
>
  <div
    class="d-flex col-lg-11 col-12 px-lg-0 px-3 text-center bg-light-yellow align-items-center justfy-content-center rounded-2"
  >
    <!-- <div class="col-2">
      <img src="../../assets/left-electrician.png" alt="Electrician" class="electrician-left" />
    </div> -->
    <div
      class="d-flex flex-column col-12 py-4 align-items-center justify-content-center"
    >
      <span class="text-yellow font36 fw400 lh35"
        >Book a Trusted Electrician Near You
      </span>
      <p class="font20 fw400 font-black lh35">
        "Find the best electricians in your area with our easy-to-use Google
        Maps feature."
      </p>
      <!-- <div>
        <button class="bg-grey text-white font24 fw400 rounded4 lh35">
          Find Electrician
        </button>
      </div> -->
    </div>
    <!-- <div class="col-2">
      <img src="../../assets/righ-electrician.png" alt="Electrician" class="electrician-right" />
    </div> -->
  </div>
  <!-- <div class="col-11 px-5 orange-bg rounded22">
    <div class="d-flex justify-content-center align-items-center">
      <div class="bg-lightest-yellow rounded2 col-3 text-white font13 fw400 px-4 py-3 my-4">
        <div class="px-2">
          <p>— Emily</p>
          <p class="review-text">
            "Professional, punctual, and knowledgeable! John fixed our complex
            electrical issue quickly and efficiently. Highly recommended!" -
            Emily R.
          </p>
          <div class="stars">
            <img src="path-to-star-image" alt="5 stars" />
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>

<div class="d-flex px-lg-5 px-1 mx-lg-5 mx-1 gap-4 flex-lg-row flex-column">
  <div class="col-lg-3 col-12">
    <div class="search rounded12 bg-grey">
      <div class="px-4 py-2">
        <div class="font14 fw400 text-white lh45">Location</div>
        <div>
          <div
            class="col-lg-12 col-12 d-flex justify-content-center elect-page mb-3"
          >
            <input
              type="text"
              #searchBox
              placeholder="Search your place"
              class="p-2 col-12 rounded-2 text-black bordernone elect-page"
            />
            <!-- <img src="../../assets/Search.png" class="search-image" /> -->
          </div>
        </div>
        <!-- <div class="text-center text-white font10 fw400">or</div> -->
        <!-- <div class="mt-3">
          <div class="text-center bg-white rounded4 font-black py-2 font14 fw400">
            Choose Your Current Location
          </div>
        </div> -->

        <div class="distance-slider-container">
          <div>
            <div class="font14 fw400 text-white lh45 py-2">Distance</div>
            <div class="distance-label">{{ distance }} KM</div>
            <div class="mt-2 mb-3">
              <input
                type="range"
                min="0"
                max="100"
                value="0"
                class="distance-slider"
                id="distanceRange"
                (ngModelChange)="handleDistanceChange()"
                style="width: 100%"
                [(ngModel)]="distance"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-12">
    <!-- {{electricianCordinates | json}} -->
    <div class="electrician-list-section">
      <div class="map-panel position-relative">
        <div *ngIf="isMapVisible" class="rounded-map" id="map">
          <!-- <app-map [showSearch]="false" [latitude]="latitude" [longitude]="longitude" [zoom]="10"
            [coordinates]="electricianCordinates"></app-map> -->
          <div
            #mapContainer
            style="height: 600px"
            class="rounded2 justify-content-center align-items-center d-flex"
          ></div>
        </div>
        <div
          role="button"
          class="hide-map-btn text-white px-3 py-2"
          (click)="toggleMap()"
        >
          {{ isMapVisible ? "Hide Map" : "Show Map" }}
        </div>
        <div *ngIf="!isMapVisible" class="map-placeholder"></div>
      </div>
      <!-- {{list |json}} -->

      <!-- Electrician Card -->
      <div
        *ngFor="let electrician of paginatedList; let e = index"
        class="col-12 electrician-card d-flex align-items-starcenter p-3 mt-4 flex-lg-row flex-column"
      >
        <div
          class="col-lg-1 d-lg-flex d-none flex-column justfy-content-center text-center"
        >
          <div class="d-flex align-items-center">
            <img
              [src]="
                electrician.profileImage ||
                '../../assets/electrician-avatar.png'
              "
              class=""
              alt="Electrician Image"
              style="
                height: 64px;
                width: 64px;

                border-radius: 50%;
              "
            />
          </div>
          <!-- <div class="font14 fw400 font-primary" role="button" (click)="goToDetails(electrician.identifier)">
            View Profile
          </div> -->
        </div>
        <div class="col-lg-4 col-12 d-flex flex-column">
          <div
            class="font32 fw400 font-primary d-flex flex-column mb-0 align-items-center align-items-lg-start"
          >
            <!-- <div class=" d-flex align-items-center"> -->
            <img
              [src]="
                electrician.profileImage ||
                '../../assets/electrician-avatar.png'
              "
              class="d-flex d-lg-none me-2"
              alt="Electrician Image"
              style="height: 64px; width: 64px; border-radius: 50%"
            />
            <!-- </div> -->
            {{ electrician.firstName }} {{ electrician.lastName }}
            <span class="d-flex d-lg-none font15 font-black"
              >{{ electrician.experience }}+ years of experience</span
            >
          </div>
          <div class="font-black font15 mb-0 d-lg-flex d-none">
            {{ electrician.experience }}+ years of experience
          </div>
          <div class="mt-lg-4 mt-0 d-lg-flex d-none">
            <button
              class="rounded4 font15 fw400 px-5 py-2 btn-warning"
              (click)="openCalendly(electrician.calender)"
            >
              Book Now
            </button>
          </div>
        </div>
        <div
          class="col-lg-4 col-12 d-flex align-items-lg-start align-items-center justify-content-center py-2 py-lg-0 flex-column"
        >
          <div
            class="font15 fw400 font-black justify-content-center mt-lg-2 mt-0 d-flex flex-column justfy-content-center align-items-center align-items-lg-start"
            style="margin-bottom: 0px"
          >
            <span class="font-primary font16 fw500 ms-3">Specialization</span>
            <div
              *ngFor="
                let specialization of electrician.electrician_specialization;
                let i = index
              "
            >
              <span
                *ngIf="
                  (i < electrician.initialTechSpecs &&
                    electrician.showAll == false) ||
                  electrician.showAll == true
                "
                class="mb-0"
              >
                <ul class="ms-0 mb-0">
                  <li class="ms-0 mb-0">{{ specialization }}</li>
                </ul>
              </span>
            </div>
          </div>
          <div
            *ngIf="
              electrician.electrician_specialization.length >
                initialTechSpecs && electrician.showAll == false
            "
            class="font-primary font14 fw400 mt-0 ms-lg-4 ms-0"
            role="button"
            (click)="electrician.showAll = true"
          >
            See All
          </div>
          <div
            *ngIf="
              electrician.electrician_specialization.length >
                initialTechSpecs && electrician.showAll == true
            "
            class="font-primary font14 fw400 mt-0 ms-lg-4 ms-0"
            role="button"
            (click)="
              electrician.initialTechSpecs = 2; electrician.showAll = false
            "
          >
            See Less
          </div>
        </div>
        <div
          class="electrician-rating col-lg-3 col-12 d-flex flex-lg-column flex-row align-items-lg-end align-items-center gap-2 justify-content-lg-center justify-content-between"
        >
          <div class="mt-lg-4 mt-0 d-flex d-lg-none">
            <button
              class="rounded4 font15 fw400 px-lg-5 px-1 py-2 btn-warning"
              (click)="openCalendly(electrician.calender)"
            >
              Book Now
            </button>
          </div>
          <!-- <div class="stars">★★★★☆</div> -->
          <div class="font-black font16 fw400">
            <!-- {{ electrician.electrician_rating || "No Rating" }} -->
          </div>
          <div class="py-1 d-flex align-items-end align-items-center">
            <img
              src="../../assets/phonecall.png"
              alt=""
              class="img-fluid pe-2"
              style="max-width: 20px"
            />9035814717
          </div>
          <div class="mt-1">
            <button class="font15 fw400 px-lg-3 px-1 py-2 btn-primary">
              {{ electrician.slotsAvailable || "Slots Available" }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="list.length == 0">
        <!-- Electrician Card -->
        <div
          class="col-12 electrician-card d-flex align-items-starcenter p-3 mt-4 flex-lg-row flex-column"
        >
          <div
            class="col-lg-1 d-lg-flex d-none flex-column justfy-content-center text-center"
          >
            <div class="d-flex align-items-center">
              <img
                [src]="'../../assets/electrician-avatar.png'"
                class=""
                alt="Electrician Image"
                style="
                  height: 64px;
                  width: 64px;

                  border-radius: 50%;
                "
              />
            </div>
            <!-- <div class="font14 fw400 font-primary" role="button" (click)="goToDetails(electrician.identifier)">
          View Profile
        </div> -->
          </div>
          <div class="col-lg-4 col-12 d-flex flex-column">
            <div
              class="font32 fw400 font-primary d-flex flex-wrap mb-0 align-items-center"
            >
              <!-- <div class=" d-flex align-items-center"> -->
              <img
                [src]="'../../assets/electrician-avatar.png'"
                class="d-flex d-lg-none me-2"
                alt="Electrician Image"
                style="height: 64px; width: 64px; border-radius: 50%"
              />
              <!-- </div> -->
              Suraj Kumar
              <span class="d-flex d-lg-none font15 font-black"
                >8+ years of experience</span
              >
            </div>
            <div class="font-black font15 mb-0 d-lg-flex d-none">
              8+ years of experience
            </div>
            <div class="mt-lg-4 mt-0 d-lg-flex d-none">
              <button
                class="rounded4 font15 fw400 px-5 py-2 btn-warning"
                (click)="openCalendly(defaultCalendlyUrl)"
              >
                Book Now
              </button>
            </div>
          </div>
          <div
            class="col-lg-4 col-12 d-flex align-items-lg-center align-self-start justify-content-lg-center justify-content-start py-2 py-lg-0"
          >
            <ul class="font15 fw400 font-black" style="margin-bottom: 0px"></ul>
          </div>
          <div
            class="electrician-rating col-lg-3 col-12 d-flex flex-lg-column flex-row align-items-lg-end align-items-center gap-1 justify-content-lg-center justify-content-between"
          >
            <div class="mt-lg-4 mt-0 d-flex d-lg-none">
              <button
                class="rounded4 font15 fw400 px-lg-5 px-1 py-2 btn-warning"
                (click)="openCalendly(defaultCalendlyUrl)"
              >
                Book Now
              </button>
            </div>
            <!-- <div class="stars">★★★★☆</div> -->
            <div class="font-black font16 fw400">
              <!-- {{ electrician.electrician_rating || "No Rating" }} -->
            </div>
            <div class="py-1 d-lg-flex align-items-end align-items-center">
              <img
                src="../../assets/phonecall.png"
                alt=""
                class="img-fluid pe-2"
                style="max-width: 20px"
              />9035814717
            </div>
            <div class="mt-1">
              <button class="font15 fw400 px-lg-3 px-1 py-2 btn-primary">
                {{ "Slots Available" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 justify-content-lg-start justify-content-center d-flex mb-5"
      >
        <div class="d-flex gap-2 justify-content-center align-items-center">
          <button
            (click)="previousPage()"
            [disabled]="currentPage === 1"
            class="bordernone bg-transparent"
            *ngIf="currentPage > 1 || totalPages == 1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M0.560453 8.45071C-0.108387 8.06959 -0.113845 7.10736 0.550627 6.71868L11.7423 0.172246C12.4068 -0.216431 13.2428 0.259959 13.2472 1.02975L13.3207 13.9952C13.3251 14.765 12.4945 15.2509 11.8257 14.8697L0.560453 8.45071Z"
                fill="#414040"
              />
            </svg>
          </button>
          <span *ngFor="let i of pageArray">
            <span
              role="button"
              (click)="goToPage(i)"
              class="index-page"
              [ngClass]="{ 'active-index': i === currentPage }"
              >{{ i === -1 ? "..." : i }}
            </span></span
          >
          <button
            (click)="nextPage()"
            [disabled]="currentPage === totalPages"
            class="bordernone bg-transparent"
            *ngIf="currentPage < totalPages || totalPages === 1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
            >
              <path
                d="M13.5004 6.62451C14.1683 7.00726 14.1714 7.9695 13.506 8.35655L2.29834 14.8755C1.63292 15.2626 0.79804 14.7841 0.79556 14.0143L0.753788 1.04873C0.751308 0.278929 1.58309 -0.204878 2.25099 0.177872L13.5004 6.62451Z"
                fill="#414040"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="justify-content-center align-items-center d-flex col-12 text-center mb-5"
>
  <div class="bg d-flex flex-lg-row text-white col-11 flex-column">
    <div
      class="bg1 col-31 d-flex justify-content-center align-items-center px-2 py-5"
      (mouseenter)="onMouseEnter(1)"
      (mouseleave)="onMouseLeave()"
    >
      <div *ngIf="!isHovered(1)">
        <img class="" src="../../assets/Map.png" alt="Electricians" />
        <p class="">Locate Nearby Electricians on Google Maps</p>
      </div>
      <div class="hoverbg1" *ngIf="isHovered(1)">
        <div class="">
          <!-- <img class="" src="../../assets/Map.png" /> -->
          <p class="">
            Use our integrated Google Maps feature to discover qualified
            electricians near your location. Simply enter your area or allow
            location access to see a list of electricians available in
            real-time.
          </p>
        </div>
      </div>
    </div>

    <div
      class="bg2 col-31 d-flex justify-content-center align-items-center px-2 py-5"
      (mouseenter)="onMouseEnter(2)"
      (mouseleave)="onMouseLeave()"
    >
      <div *ngIf="!isHovered(2)">
        <img src="../../assets/Booking.png" alt="" />
        <p>Book an Electrician Slot with Ease</p>
      </div>
      <div class="hoverbg2" *ngIf="isHovered(2)">
        <div>
          <!-- <img class="bg3" src="../../assets/Booking.png" /> -->
          <p>
            Conveniently book a time slot that fits your schedule. Our booking
            system allows you to choose available slots directly from each
            electrician's profile.
          </p>
        </div>
      </div>
    </div>
    <div
      class="bg3 col-31 d-flex justify-content-center align-items-center px-2 py-5"
      (mouseenter)="onMouseEnter(3)"
      (mouseleave)="onMouseLeave()"
    >
      <div *ngIf="!isHovered(3)">
        <img src="../../assets/Check.png" alt="" />
        <p>Trusted and Verified Professionals</p>
      </div>
      <div class="hoverbg3" *ngIf="isHovered(3)">
        <div>
          <!-- <img class="bg4" src="../../assets/Check.png" /> -->
          <p>
            All electricians listed on our platform are verified and reviewed by
            previous customers, ensuring quality and reliability for every
            service.
          </p>
        </div>
      </div>
    </div>

    <div
      class="bg4 col-31 d-flex justify-content-center align-items-center px-2 py-5"
      (mouseenter)="onMouseEnter(4)"
      (mouseleave)="onMouseLeave()"
    >
      <div *ngIf="!isHovered(4)">
        <img class="slide7-img" src="../../assets/hours.png" alt="" />
        <p>24/7 Emergency Support</p>
      </div>
      <div class="hoverbg4" *ngIf="isHovered(4)">
        <div>
          <!-- <img class="bg5" src="../../assets/hours.png" /> -->
          <p>
            In case of an emergency, you can locate electricians available 24/7.
            Quick response and professional help are just a click away.
          </p>
        </div>
      </div>
    </div>

    <div
      class="bg5 col-31 d-flex justify-content-center align-items-center px-2 py-5"
      (mouseenter)="onMouseEnter(5)"
      (mouseleave)="onMouseLeave()"
    >
      <div *ngIf="!isHovered(5)">
        <img src="../../assets/Wallet.png" alt="" />
        <p>Flexible Payment Options</p>
      </div>
      <div *ngIf="isHovered(5)" class="hoverbg5">
        <div>
          <!-- <img class="bg6" src="../../assets/Wallet.png" /> -->
          <p>
            We offer multiple payment options, including online payment, to make
            the process hassle-free.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="d-flex justify-content-center col-12 align-items-center mb-5 font32 fw400 flex-column"
>
  <div class="col-12 justify-content-center d-flex">FAQ</div>
  <div
    class="d-flex col-11 my-5 flex-lg-row flex-column-reverse justify-content-evenly align-items-center"
  >
    <div>
      <div *ngFor="let item of QAList">
        <p class="mb-0 font20 fw500">
          <img src="../../assets/que.png" alt="" class="me-3" />{{ item.que }}
        </p>
        <p class="mt-1 font16 mb-4">
          <img src="../../assets/ans.png" alt="" class="me-3" />{{ item.ans }}
        </p>
      </div>
    </div>
    <div><img src="../../assets/elec-faq.png" alt="" /></div>
  </div>
</div>
