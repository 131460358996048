import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../app/auth-service.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  private baseUrl = environment.API_URL + '/wishlist';

  constructor(private http: HttpClient, private authService: AuthService) {}

  getWishlist() {
    // console.log(this.baseUrl);
    const url = `${this.baseUrl}/list`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  addWishlist(productId: any,userId: any) {
    // console.log(this.baseUrl);
    const url = `${this.baseUrl}/add`;
    const addWishListData ={
      productId: productId,
      userId: userId
    }
    return this.http
      .post<any>(url,addWishListData, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  deleteWishlist(id: any) {
    // console.log(this.baseUrl);
    const url = `${this.baseUrl}/delete/${id}`;
    return this.http
      .post<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
}
