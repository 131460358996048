<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  <!-- {{ header }} -->
</div>

<div class="px-5 mx-5">
  <div class="d-flex col-12 mt-5">
    <div class="col-4 align-items-end d-flex">
      <div class="font15 fw400">Order Date: {{ todayDate }}</div>
    </div>
    <div class="col-4 align-items-end d-flex justify-content-center">
      <div class="text-center">
        <img src="../../assets/giftbox.png" alt="" />
        <p class="mb-0 font-primary font24 fw400">Your Order Has Been Placed</p>
        <!-- <p class="mb-0 font15 fw400">
          Your {{ totalQuantity }} Will Be Delivered On 19-03-2024
        </p> -->
      </div>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <div class="d-flex align-items-end">
        <button
          class="bgorange text-white text-center rounded4 px-5 py-3"
          style="border: none"
          (click)="navigateToOrderListing()"
        >
          Go To My Orders
        </button>
      </div>
    </div>
  </div>

  <hr class="my-4" />

  <div class="box mb-5" *ngFor="let order of orderDetails">
    <div class="d-flex mx-5 my-5">
      <div class="col-5 d-flex justify-content-between">
        <div>
          <span class="font20 fw400">Delivery Address</span>
          <div class="my-3 font16 fw400">
            {{ order.userName }}, {{ order.address.firstLine }},
            {{ order.address.secondLine }}, <br />
            {{ order.address.city }}, {{ order.address.state }},
            {{ order.address.pinCode }}, {{ order.address.country }}
            <!-- {{order.address | json }} -->
          </div>
          <p class="font16 fw400 mb-0">
            Phone Number : {{ order.address.phoneNumber }}
          </p>
          <div class="font16 fw400 my-3">
            Your Item Will Be
            <p class="mb-0 font20 lh35">
              <span class="font-primary fw400">Delivered by</span>
              {{ deliveryDate }}
            </p>
          </div>
        </div>
        <div class="vr"></div>
      </div>
      <div class="vr"></div>
      <ng-container>
        <div class="col-4 d-flex justify-content-between ps-5">
          <div class="col-11">
            <div class="d-flex">
              <div class="img">
                <img
                  [src]="order.productDetail.productImage[0].imagePublicUrl"
                  alt="{{ order.productDetail.productHeading }}"
                  style="max-width: 100%; max-height: 100%; object-fit: cover"
                />
              </div>
              <div class="col-5 d-flex flex-column justify-content-center">
                <div class="">
                  <div class="stars-bordered">
                    <span
                      [ngClass]="{
                        rating0: roundedAverage === 0,
                        'rating0-5': roundedAverage === 0.5,
                        rating1: roundedAverage === 1,
                        'rating1-5': roundedAverage === 1.5,
                        rating2: roundedAverage === 2,
                        'rating2-5': roundedAverage === 2.5,
                        rating3: roundedAverage === 3,
                        'rating3-5': roundedAverage === 3.5,
                        rating4: roundedAverage === 4,
                        'rating4-5': roundedAverage === 4.5,
                        rating5: roundedAverage === 5
                      }"
                    ></span>
                  </div>
                  <span class="font16 fw400 ps-2">{{
                    order.productDetail.ratings.overallRating | number : "1.1-1"
                  }}</span>
                </div>
                <div class="mt-2">
                  {{ order.productDetail.ratings.totalRating }} Ratings
                </div>
              </div>
            </div>
            <div class="mt-3 font16 fw400 lh26">
              <div class="two-line-text">
                {{ order.productDetail.productHeading }}
              </div>
              <div class="two-line-text">
                {{ order.productDetail.productDescription }}
              </div>
            </div>
          </div>
          <div class="vr"></div>
        </div>
        <div class="col-3 ps-5">
          <div class="font24 fw400">
            Total:
            <span class="font24 ps-3"
              >₹{{ order.price | number : "1.2-2" }}</span
            >
          </div>
          <div class="mt-3">
            <span
              *ngIf="order.order_status !== 'Cancelled'; else cancelledText"
              class="font16 fw400"
              role="button"
              (click)="cancelOrderItem(order.identifier)"
            >
              Cancel Order
              <img class="cancel" src="../../assets/cancel.png" alt=""
            /></span>
            <ng-template #cancelledText>
              <span class="font16 fw400 text-danger">Cancelled</span>
            </ng-template>
          </div>
          <div class="col-6 mt-4 font-primary">
            <span class="font18 fw400">Need More Help?</span>

            <p class="mb-0 font16 fw400 mt-3">
              Contact our customer support team at support &#64;powerbihar.com
              or call
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
