<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage">
  Create Account
</div>

<div class="d-flex col-lg-10 col-12 justify-content-center align-items-center py-5 flex-column my-0 my-lg-4 gap-5">
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5">
      Personal Information
    </div>
    <div class="col-12 col-lg-4 my-4 my-lg-0">
      <input type="text" id="fname" placeholder="First Name" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <input type="text" id="lname" placeholder="Last Name" class="col-10 p-2" />
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5">
      Email Address
    </div>
    <div class="col-12 col-lg-4 my-4 my-lg-0">
      <input type="text" id="email" placeholder="Email" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <button class="col-10 p-2 text-white bgorange bordernone font20 fw400 lh25 rounded4">
        <img src="../../assets/addimage.png" class="pe-2" /> ADD PROFILE PHOTO
      </button>
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5">
      Mobile Number
    </div>
    <div class="col-12 col-lg-4 my-4 my-lg-0">
      <input type="number" id="mobilenumber" placeholder="Mobile Number" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <button class="col-10 col-lg-6 p-2 text-white bgorange bordernone font20 fw400 lh25 rounded4">
        SAVE
      </button>
    </div>
  </div>
  <div class="col-11 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-4 py-0">
    <!-- <hr class="text-black"/> -->
    <div style="background: rgba(0, 0, 0, 0.28); height: 1px" class="col-12 align-items-center ms-0 ms-lg-5"></div>
  </div>
</div>

<div class="d-flex col-lg-10 col-12 justify-content-center align-items-center py-0 flex-column mb-0 mb-lg-5 gap-5">
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5">
      Add Address
    </div>
    <div class="col-12 col-lg-8 mt-4 mt-lg-0">
      <button class="bordernone text-white font24 fw400 lh25 bg-grey py-2 px-5 rounded4">
        <img src="../../assets/locationicon.png" alt="location" class="pe-2 py-2 col mb-1" />Locate on Map
      </button>
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5 d-none d-lg-flex"></div>
    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
      <input type="text" id="fnameAddress" placeholder="First Name" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <input type="text" id="mobileAddress" placeholder="Mobile Number" class="col-10 p-2" />
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5 d-none d-lg-flex"></div>
    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
      <input type="text" id="pinCode" placeholder="Pin Code" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <input type="text" id="locality" placeholder="Locality" class="col-10 p-2" />
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5 d-none d-lg-flex"></div>
    <div class="col-12 col-lg-8 mb-3 mb-lg-0">
      <textarea type="text" id="address" placeholder="Address (Area, Street)" class="col-10 col-lg-11 p-2"
        rows="5"></textarea>
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5 d-none d-lg-flex"></div>
    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
      <input type="text" id="city" placeholder="City/District/Town" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <select id="state" placeholder="State" class="col-10 p-2">
        <option>State</option>
      </select>
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5 d-none d-lg-flex"></div>
    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
      <input type="text" id="landmark" placeholder="LandMark" class="col-10 p-2" />
    </div>
    <div class="col-12 col-lg-4">
      <input type="text" id="altPhone" placeholder="Alternate Phone" class="col-10 p-2" />
    </div>
  </div>
  <div
    class="col-10 flex-row d-flex justify-content-between align-items-center ms-0 ms-lg-5 ps-0 ps-lg-5 flex-lg-row flex-column">
    <div class="col-12 col-lg-4 font24 font-primary lh25 ms-0 ms-lg-5 d-none d-lg-flex"></div>
    <div class="col-12 col-lg-8 mb-3 mb-lg-0 font20 fw400 font-primary d-flex gap-5">
      Address Type
      <form class="d-flex gap-4 text-black">
        <div>
          <label>
            <input type="radio" value="home" />
            Home
          </label>
        </div>
        <div>
          <label>
            <input type="radio" value="work" />
            Work
          </label>
        </div>
      </form>
    </div>
  </div>
  <div
    class="col-12 col-lg-2  d-flex justify-content-lg-between justify-content-start align-items-center ms-0 ms-lg-5 ps-0 ps-lg-0 flex-lg-row flex-column mb-5 pb-3">

    <button class="bgorange font24 fw400 col-5 col-lg-12 py-2 bordernone text-white rounded4">
      SAVE
    </button>
  </div>
</div>