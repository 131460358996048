import { Routes } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { FaqComponent } from './faq/faq.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HomeComponent } from './home/home.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { SigninComponent } from './signin/signin.component';
import { CreateaccountComponent } from './createaccount/createaccount.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { ProductListComponent } from './productlist/productlist.component';
import { NewProductResolver } from './resolvers/product.resolver';
import { AuthGuard } from './auth.guard';
import { CartComponent } from './cart/cart.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductListHorizontalComponent } from './productlist-horizontal/productlist-horizontal.component';
import { CartAddressComponent } from './cart-address/cart-address.component';
import { CartPaymentComponent } from './cart-payment/cart-payment.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { BuyerOrderListingComponent } from './buyer-order-listing/buyer-order-listing.component';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { DeliveryAddressComponent } from './delivery-address/delivery-address.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { ElectricianSearchPageComponent } from './electrician-search-page/electrician-search-page.component';
import { ElectricianDetailComponent } from './electrician-detail/electrician-detail.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'aboutus',
    component: AboutusComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'contactus',
    component: ContactusComponent,
  },
  {
    path: 'productcard',
    component: ProductCardComponent,
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'createaccount',
    component: CreateaccountComponent,
  },
  {
    path: 'electricianSearch',
    component: ElectricianSearchPageComponent,
  },
  {
    path: 'electricianDetail/:electricianId',
    component: ElectricianDetailComponent,
  },
  {
    path: 'myaccount',
    component: MyaccountComponent,
    canActivate: [AuthGuard], // Protect the route with the AuthGuard
  },
  {
    path: 'all-notifications',
    component: NotificationListComponent,
    canActivate: [AuthGuard], // Protect the route with the AuthGuard
  },
  {
    path: 'deliveryAddress',
    component: DeliveryAddressComponent,
    canActivate: [AuthGuard], // Protect the route with the AuthGuard
  },
  {
    path: 'newproducts',
    component: ProductListHorizontalComponent,
    resolve: {
      newArrivals: NewProductResolver,
    },
  },
  {
    path: 'bestseller',
    component: ProductListHorizontalComponent,
    resolve: {
      bestSellers: NewProductResolver,
    },
  },
  {
    path: 'allproducts',
    component: ProductListHorizontalComponent,
    resolve: {
      allProducts: NewProductResolver,
    },
  },
  {
    path: 'category/:category',
    component: ProductListHorizontalComponent,
    resolve: {
      allProducts: NewProductResolver,
    },
  },
  {
    path: 'subcategory/:subcategory',
    component: ProductListHorizontalComponent,
    resolve: {
      allProducts: NewProductResolver,
    },
  },
  {
    path: 'brand/:brandId',
    component: ProductListHorizontalComponent,
    resolve: {
      allProducts: NewProductResolver,
    },
  },
  {
    path: 'cart',
    component: CartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cart/address',
    component: CartAddressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cart/payment',
    component: CartPaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order/confirmation',
    component: OrderConfirmationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buyer/order/listing',
    component: BuyerOrderListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buyer/order/tracking/:orderIdentifier',
    component: OrderTrackingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'wishlist',
    component: WishlistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'productcategory',
    component: ProductCategoryComponent,
  },
  {
    path: 'product/:productid',
    component: ProductDetailsComponent,
    resolve: {
      allProducts: NewProductResolver,
    },
  },
];
