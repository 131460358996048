import { Component } from '@angular/core';

@Component({
  selector: 'app-electrician-detail',
  standalone: true,
  imports: [],
  templateUrl: './electrician-detail.component.html',
  styleUrl: './electrician-detail.component.scss'
})
export class ElectricianDetailComponent {

}
