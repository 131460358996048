<div class="col-12 d-flex justify-content-between align-items-center py-5 px-5">
  <div class="d-flex col-12 justify-content-between flex-lg-row flex-column">

    <!-- Left carousel -->
    <div class="col-lg-7 col-12 d-flex flex-lg-row justify-content-center align-items-center p-3 p-lg-0"
      style="position: relative;">
      <div class="carousel-inner d-flex align-items-center justify-content-center"
        style="transition: transform 0.5s ease;">
        <!-- <div *ngFor="let pics of leftOfferPics; let i = index" class="d-flex gap-5"> -->
        <div class="">
          <img src="{{ leftOfferPics[currentLeftIndex].url }}" alt="" class="img-fluid" />
        </div>
        <!-- </div> -->
      </div>

      <!-- Dots for left carousel -->
      <div class="dots-container mb-4 mb-lg-2">
        <span *ngFor="let pic of leftOfferPics; let i = index" class="dot" [class.active]="i === currentLeftIndex"
          (click)="goToLeftSlide(i)"></span>
      </div>
    </div>

    <!-- Right carousel -->
    <div class="col-lg-5 col-12">
      <div class="d-flex flex-lg-row justify-content-center align-items-center ms-lg-4 ms-0"
        style="position: relative;">
        <div class="carousel-inner d-flex align-items-center justify-content-center"
          style="transition: transform 0.5s ease;">
          <!-- <div *ngFor="let pics of rtOfferPics; let i = index" class="d-flex gap-5"> -->
          <div class="" style="max-width: 90%;">
            <img src="{{ rtOfferPics[currentRightIndex].url }}" alt="" class="img-fluid" />
          </div>
          <!-- </div> -->
        </div>

        <!-- Dots for right carousel -->
        <div class="dots-container">
          <span *ngFor="let pic of rtOfferPics; let i = index" class="dot" [class.active]="i === currentRightIndex"
            (click)="goToRightSlide(i)"></span>
        </div>
      </div>

      <!-- Bottom right carousel -->
      <div class="d-flex flex-lg-row justify-content-center align-items-center mt-5 ms-lg-4 ms-0 me-lg-4 me-4"
        style="position: relative;">
        <div class="carousel-inner d-flex align-items-center justify-content-center"
          style="transition: transform 0.5s ease;">
          <!-- <div *ngFor="let pics of rbOfferPics; let i = index" class="d-flex gap-5"> -->
          <div class="" style="max-width: 90%;">
            <img src="{{ rbOfferPics[currentBottomRightIndex].url }}" alt="" class="img-fluid" />
          </div>
          <!-- </div> -->
        </div>

        <!-- Dots for bottom right carousel -->
        <div class="dots-container">
          <span *ngFor="let pic of rbOfferPics; let i = index" class="dot"
            [class.active]="i === currentBottomRightIndex" (click)="goToBottomRightSlide(i)"></span>
        </div>
      </div>
    </div>
  </div>
</div>