import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../app/auth-service.service';
import { catchError, first, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private baseUrl = environment.API_URL + '/notification';
  constructor(private http: HttpClient, private authService: AuthService) {
    console.log(
      `NotificationService initialized with baseUrl: ${this.baseUrl}`
    );
  }

  getNotification() {
    const url = `${this.baseUrl}/user`;
    console.log(`Making HTTP GET request to URL: ${url}`);
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first(), catchError(this.handleError));
  }
  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError('Something went wrong; please try again later.');
  }
}
