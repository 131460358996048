<div class="d-flex justify-content-center col-12 flex-column align-items-center my-lg-5 my-3 flex-column">
  <div class="font20 fw400 justify-content-center">Questions and Answers</div>
  <div class="d-flex justify-content-center col-lg-5 col-9 my-4">
    <input type="text" placeholder="Have a Question? Search for Answers"
      class="w-100 p-2 text-light-grey border-grey font12 fw300" />
    <img src="../../assets/Magnifier.png" alt="" class="img-fluid"
      style="margin-left: -30px; margin-top: 1.5%; height: 17px" width="17px" height="17px" />
  </div>
</div>
<div class="align-items-center col-12 d-flex justify-content-center ps-1">
  <div
    class="d-flex justify-content-start col-11 flex-column align-items-self-start flex-column border-grey p-5 gap-4 ms-lg-5 ms-0 bg-light-grey-1">
    <div *ngFor="let qa of prodQA; let i = index">
      <div class="d-flex flex-column gap-1" *ngIf="i <= initialQACount">
        <span class="font-primary font14 fw500">Q:{{ qa.question }}?</span>
        <span class="font14 fw400" *ngIf="qa.answer">Ans:{{ qa.answer }}</span>
        <span class="font14 fw400" *ngIf="!qa.answer">No Answers yet.</span>
        <span class="text-light-black font12">{{ qa.userName }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-row gap-4 align-items-center">
      <div class="d-flex justify-content-center font-primary font14 fw500"
        *ngIf="prodQA.length > 5 && !(prodQA.length < initialQACount)" role="button" (click)="viewMore()">
        See More
      </div>
      <div class="d-flex justify-content-center font-primary font14 fw500"
        *ngIf="(prodQA.length < initialQACount || initialQACount > 4) && prodQA.length>0" role="button"
        (click)="viewLess()">
        See Less
      </div>
      <div class="d-flex justify-content-center text-white font14 fw500 cartButtonOrange px-4 py-2"
        *ngIf="prodQA.length < initialQACount" role="button" (click)="openQA()">
        Ask a Question?
      </div>
    </div>
  </div>
</div>
<ng-template class="modal fade mt-5 center" #submitQAModal tabindex="-1" aria-label="Review" aria-hidden="true">
  <div class="modal-header text-center d-flex align-items-center justify-content-center">
    Ask A Question
  </div>
  <div class="modal-body text-center d-flex align-items-center justify-content-center">
    <textarea rows="3" name="Question" id="Question" placeholder="Kindly describe your issue" [(ngModel)]="question"
      class="w-100 ps-3">
    </textarea>
  </div>
  <div class="text-center d-flex align-items-center justify-content-center mb-3">
    <button class="px-3 py-1 cartButtonOrange" (click)="submitQA(prodQA[0].product_id)">
      Submit
    </button>
  </div>
</ng-template>