<div class="d-flex col-12 justify-content-center font36 fw400 mt-5">
  Best Selling Products
</div>
<div class="ms-3 ms-lg-0 ps-2 ps-lg-0 mb-5">
  <div class="col-12 d-flex py-4 py-lg-5" *ngIf="productList.length > 0">
    <div
      class="align-items-center col-12 d-flex flex-column flex-lg-row flex-lg-wrap justify-content-center products-container py-0 py-lg-5"
    >
      <div
        *ngFor="let product of productList"
        class="product-card"
        role="button"
        (click)="navigateToProductDetails(product.productId, product.listingId)"
      >
        <!-- {{product}} -->
        <div class="product-card-header">
          <div class="image-placeholder">
            <img
              [src]="
                product.productImageList[0]?.imagePublicUrl ||
                '/assets/default-image.png'
              "
              [alt]="product.productHeading"
              class="product-image"
            />
          </div>
        </div>
        <div class="product-details">
          <div class="product-name">{{ product.productHeading }}</div>
          <div class="product-price">
            <div class="discounted-price font15 fw400 lh-1">
              ₹{{ product.finalAmtWithTax | number : "1.2-2" }}
            </div>
            <div class="original-price font12 fw400 lh-1 mt-1">
              MRP: ₹{{ product.mrp | number : "1.2" }}
            </div>
          </div>
          <div
            class="my-3 justify-content-center align-items-center d-flex"
            *ngIf="product.avgRatings"
          >
            <div class="rating-stars">
              <div
                class="star"
                *ngFor="let star of starsArray; let i = index"
                [ngClass]="{
                  filled: i < getFloor(product.avgRatings.overallRating),
                  'half-filled':
                    i >= getFloor(product.avgRatings.overallRating) &&
                    i < product.avgRatings.overallRating
                }"
              >
                ★
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
