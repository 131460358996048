import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../app/auth-service.service';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { CartUpdateDTO } from '../app/dto/CartUpdateDTO';
import { ProductDTO } from '../app/dto/ProductDTO';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private baseUrl = environment.API_URL + '/cart';
  private cartItemCount = new BehaviorSubject<number>(0);
  cartItemCount$ = this.cartItemCount.asObservable();
  private pinCode = 800001;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}

  addCart(productDetails: ProductDTO): Observable<any> {
    const url = `${this.baseUrl}/addproduct`;
    return this.http
      .post<any>(url, productDetails, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getCartList() {
    // console.log(this.baseUrl);
    const url = `${this.baseUrl}/list`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  updateCartQuantity(
    productIdentifier: string,
    cartUpdateData: CartUpdateDTO
  ): Observable<any> {
    const url = `${this.baseUrl}/update/${productIdentifier}`;
    return this.http
      .post<any>(url, cartUpdateData, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  deleteCartItem(cartIdentifier: string) {
    const url = `${this.baseUrl}/delete/${cartIdentifier}`;
    return this.http
      .post<any>(url, cartIdentifier, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  updateCartItemCount(count: number): void {
    this.cartItemCount.next(count);
  }
  checkAvailability() {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/checkAvailability/${this.pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  checkAvailabilityByPinCode(pinCode: any) {
    const url = `${this.baseUrl}/checkAvailability/${pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getDeliveryTime(pinCode: any) {
    const url = `${this.baseUrl}/getDeliveryTime/${pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getProductCount(productId: any) {
    ///checkItemInCart/{productId}
    const url = `${this.baseUrl}/checkItemInCart/${productId}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
}
