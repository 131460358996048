import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-qa',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './product-qa.component.html',
  styleUrl: './product-qa.component.scss',
})
export class ProductQaComponent implements OnInit {
  @Input() prodQA: any;
  @ViewChild('submitQAModal') modal: any;
  constructor(
    private modalService: NgbModal,
    private productService: ProductService
  ) {}
  initialQACount: number = 4;
  question: any = '';
  ngOnInit(): void {
    this.initialQACount = 4;
  }
  viewMore() {
    this.initialQACount += 5;
  }
  viewLess() {
    this.initialQACount -= 5;
  }
  openQA(){
    this.modalService.open(this.modal);
  }
  submitQA(id: any) {
    
    this.productService
      .submitQA(id, this.question, null)
      .subscribe((data) => {
        this.modalService.dismissAll();
      });
  }
}
