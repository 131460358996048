<div class="d-flex flex-column">
  <div class="col-12 d-flex flex-column flex-lg-row orange-bg px-5 py-2">
    <div class="col-12 col-lg-6 ps-0 ps-lg-5 py-5">
      <div class="col-12 col-lg-8 grey-bg py-2">
        <div class="font128 lh80 mt-5 px-0 px-lg-5 text-white">
          Featured
          <p class="mb-5 pb-3">Products</p>
        </div>
        <p class="text-white d-flex b font32 d-flex align-items-center mb-0">
          Explore Now
          <img role="button" src="/assets/button-grey-circle.png" alt="" class="px-3" />
        </p>
      </div>
    </div>
    <div class="align-items-center col-12 col-lg-6 d-flex position-relative overflow-hidden justify-content-between">
      <!-- Left Arrow -->
      <button class="scroll-btn left-arrow col-1" (click)="scrollLeft()">
        &#10094;
      </button>

      <!-- Scrollable Content -->
      <div class="scroll-container d-flex col-9" style="overflow-x: auto">
        <div class="d-flex flex-column align-items-center  gap-3 justify-content-end p-2 rounded-2" style=" max-width:
          fit-content" *ngFor="let item of featuredProducts; let i = index" [ngClass]="{ bgorange: i % 2 === 0 }"
          role="button" (click)="goToCategory(item.categoryName)">
          <img class="mx-4 pt-3" style="width: 128px; height: 128px" alt="Led Bulbs" [src]="item.image.image" />
          <p class="font24 mb-0 text-center text-white" style="text-wrap: nowrap">
            {{ item.categoryName }}
          </p>
        </div>
      </div>

      <!-- Right Arrow -->
      <button class="scroll-btn right-arrow col-1" (click)="scrollRight()">
        &#10095;
      </button>
    </div>
  </div>
</div>