<div
  *ngIf="showToast"
  class="toast-container"
  [ngClass]="toastClass"
  style="
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  "
>
  {{ message }}
</div>
