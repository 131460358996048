import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-arrivals',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './new-arrivals.component.html',
  styleUrl: './new-arrivals.component.scss',
})
export class NewArrivalsComponent implements OnInit {
  productList: any[] = [];
  constructor(private productService: ProductService,private router: Router) {}
  ngOnInit(): void {
    this.getNewArrivals();
  }
  getNewArrivals() {
    this.productService.getNewArrivals().subscribe((data) => {
      this.productList = data;
    
      this.productService.getBestSeller().subscribe((best) => {
        // console.log(best);
        // Loop through productList and check if the product ID exists in best
        this.productList = this.productList.map((product) => {
          // Check if the product ID exists in the best seller list
          const isBestSeller = best.some((bestItem: { productId: any; }) => bestItem.productId === product.productId);
          // console.log(isBestSeller);
          // Toggle a flag if the product is a best seller
          return {
            ...product,
            isBestSeller: isBestSeller
          };
        });
      });
    });
    
  }
  navigateToProductDetails(id: string){
    this.router.navigate([`/product/${id}`]);
  }
}
