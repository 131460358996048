import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UsersService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAddressDTO } from '../dto/UserAddressDTO';
import { AddAddressComponent } from '../add-address/add-address.component';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-delivery-address',
  standalone: true,
  imports: [CommonModule, FormsModule, AddAddressComponent, MapComponent],
  templateUrl: './delivery-address.component.html',
  styleUrl: './delivery-address.component.scss',
})
export class DeliveryAddressComponent implements OnInit {
  userDTO: any = { address: {}, billingAddress: {} }; // Define the user object
  addressDTO: any = {};
  isSeller: boolean = false;
  saveSuccess: boolean = false;
  addressId: any;
  showMap: any;
  isEditing: boolean = false;
  selectedAddress: any = {};

  parseInteger(nvar: string): number {
    const parsedValue = parseFloat(nvar);
    if (!isNaN(parsedValue)) {
      console.log('Parsed Value:', parsedValue);
      return parsedValue;
    } else {
      console.error('Invalid latitude or longitude value:', nvar);
      return 0;
    }
  }

  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // this.user();
    this.allAddress();
  }
  getAddresses(addressId: string) {
    this.userService.getAddressById(addressId).subscribe(
      (data) => {
        this.addressDTO = data;
        this.isEditing = true;
      },
      (error) => {
        console.error('Error fetching address:', error);
      }
    );
  }
  allAddress() {
    this.userService.getAllAddress().subscribe((data) => {
      console.log('getAllAddress Response:', data);
      // Sort so that default and billing addresses are on top
      this.addressDTO = data.sort((a: any, b: any) => {
        if (a.isDefault) return -1;
        if (b.isDefault) return 1;
        if (a.isBilling) return -1;
        if (b.isBilling) return 1;
        return 0;
      });
      if (this.addressDTO.length > 0) {
        this.selectedAddress = this.addressDTO[0];
        this.addressDTO.userId = this.selectedAddress.userId;
      }
    });
  }

  setAsBillingAddress(addressId: any) {
    if (confirm('Are you Sure to set as billing Address')) {
      this.userService.setAsBillingAddress(addressId).subscribe((data) => {
        window.location.reload();
      });
    }
  }

  markAddressDefault(addressId: string) {
    const userId = this.addressDTO.userId;
    if (!userId) {
      console.error('User ID is missing');
      return;
    }
    if (confirm('Are you Sure to set as default Address')) {
      this.userService
        .markAddressAsDefault(userId, addressId)
        .subscribe((data) => {
          window.location.reload();
        });
    }
  }

  editAddress(addressId: number): void {
    this.isEditing = true;

    // Check if addressDTO exists and is an array
    if (this.addressDTO && Array.isArray(this.addressDTO)) {
      // Try to find the address from addressDTO array
      this.selectedAddress =
        this.addressDTO.find((adr: { id: number }) => adr.id === addressId) ||
        {};

      // If no matching address is found, log an error
      if (
        !this.selectedAddress ||
        Object.keys(this.selectedAddress).length === 0
      ) {
        console.error('Address not found for the given addressId:', addressId);
        this.selectedAddress = {}; // Reset if no address is found
      }
    } else {
      console.error('addressDTO is not a valid array');
    }

    console.log(this.selectedAddress);
  }

  // editAddress(addressId: number): void {
  //   // this.originalAddress = { ...this.selectedAddress };
  //   this.isEditing = true;

  //   // First, check if addressDTO exists and is an array
  //   if (this.addressDTO && Array.isArray(this.addressDTO)) {
  //     // Try to find the address from addressDTO array
  //     this.selectedAddress =
  //       this.addressDTO.find((adr: { id: number }) => adr.id === addressId) ||
  //       {};
  //   }

  //   // If addressDTO doesn't contain the address, check the userDTO for address or billingAddress
  //   if (
  //     !this.selectedAddress ||
  //     Object.keys(this.selectedAddress).length === 0
  //   ) {
  //     // Check if the provided addressId matches the user's address or billing address
  //     if (this.userDTO.address && this.userDTO.address.id === addressId) {
  //       this.selectedAddress = this.userDTO.address;
  //     } else if (
  //       this.userDTO.billingAddress &&
  //       this.userDTO.billingAddress.id === addressId
  //     ) {
  //       this.selectedAddress = this.userDTO.billingAddress;
  //     } else {
  //       // If no matching address is found in either addressDTO or userDTO
  //       console.error('Address not found for the given addressId:', addressId);
  //       this.selectedAddress = {}; // Reset if no address is found
  //     }
  //   }
  //   console.log(this.selectedAddress);
  // }

  saveEditedAddress(): void {
    console.log('Saving Address:', this.selectedAddress);
    // Ensure the selected address has the updated lat/long
    this.selectedAddress.latitude = this.addressDTO.latitude;
    this.selectedAddress.longitude = this.addressDTO.longitude;

    this.userService.editAddress(this.selectedAddress).subscribe(
      (data) => {
        this.saveSuccess = true;
        this.isEditing = false;
      },
      (error) => {
        console.error('Error editing address:', error);
      }
    );
  }

  saveAddress() {
    this.userService.addAddress(this.addressDTO!).subscribe(
      (data) => {
        this.saveSuccess = true;
      },
      (error) => {
        console.error('Error saving address:', error);
      }
    );
  }

  deleteAddress(addressId: number) {
    if (confirm('Are you Sure to Delete the Address')) {
      this.userService.deleteAddress(addressId).subscribe(
        (data) => {
          console.log('Address deleted successfully:', data);
          window.location.reload();
        },
        (error) => {
          console.error('Error deleting address:', error);
        }
      );
    }
  }
  cancelEdit() {
    this.isEditing = false;
    this.selectedAddress = {};
  }

  onLocationSelected(location: { lat: number; lng: number }) {
    console.log('Selected Location:', location);
    // Use the latitude and longitude as needed
    this.addressDTO!.latitude = location.lat.toString();
    this.addressDTO!.longitude = location.lng.toString();
  }
}
