<div class="px-2 px-lg-5 text-center-1 my-3 my-lg-5">
  <div class="font36 fw400 text-brown" style="line-height: 37px">
    "Need an <span class="font-primary">Electrician?</span><br />
    <span class="text-light-brown">We Deliver Quality You Can Trust!</span>"
  </div>
  <div class="d-flex col-12 col-lg-5 mt-5">
    <div
      class="col-12 col-lg-10 mt-4 mb-4 mb-lg-0 me-0 justify-content-lg-start justify-content-center ms-0 ms-lg-2"
    >
      <app-google-map-search [page]="'home'"></app-google-map-search>
    </div>
  </div>
</div>
<div class="">
  <div
    class="d-flex align-items-center justify-content-center flex-column man-bg"
  >
    <div class="col-12 col-lg-5 d-flex flex-column align-self-start p-5">
      <div class="font16 fw400 text-white text-center-1">
        Looking for a dependable electrician to handle all your electrical
        needs? Our team of certified professionals is here to provide top-notch
        electrical services, ensuring safety, efficiency, and satisfaction.
        Whether you need repairs, installations, or routine maintenance, we have
        the expertise and experience to get the job done right. Trust us to
        brighten your home or business with our reliable and affordable
        solutions. Contact us today to schedule an appointment and experience
        the best in electrical services.
      </div>
      <button
        class="book-appoint rounded17 orange-primary py-3 mt-3 mt-lg-5 mb-3"
        (click)="navigateToElectricianSearchPage()"
      >
        <span
          class="mx-0 px-0 mx-lg-5 px-lg-5 text-white font20 fw400 text-center-1"
          >BOOK AN APPOINTMENT</span
        >
      </button>
    </div>
    <div class="d-flex col-12 col-lg-12 justify-content-center">
      <img src="../../assets/orangeline.png" class="img-width" />
    </div>
  </div>
</div>
<div class="d-lg-flex col-12 justify-content-end px-4 d-none">
  <div class="man">
    <img src="/assets/man.png" alt="Man Image" class="" />
  </div>
</div>
