import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/user.service';
import { UserAddressDTO } from '../dto/UserAddressDTO';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-add-address',
  standalone: true,
  imports: [CommonModule, FormsModule, MapComponent],
  templateUrl: './add-address.component.html',
  styleUrl: './add-address.component.scss',
})
export class AddAddressComponent implements OnInit {
  saveSuccess: boolean = false;
  @Input() addressDTO: UserAddressDTO = new UserAddressDTO();
  addressId: any;
  showMap: boolean = false;
  parseInteger(nvar: string): number {
    const parsedValue = parseFloat(nvar);
    if (!isNaN(parsedValue)) {
      console.log('Parsed Value:', parsedValue);
      return parsedValue;
    } else {
      console.error('Invalid latitude or longitude value:', nvar);
      return 0;
    }
  }

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {}

  saveAddress(form: NgForm) {
    this.userService.addAddress(this.addressDTO!).subscribe(
      (data) => {
        this.saveSuccess = true;
        form.resetForm();
        window.location.reload();
      },
      (error) => {
        console.error('Error saving address', error);
        alert('Failed to save address. Please try again.');
      }
    );
  }

  getAddress(addressId: string) {
    this.userService.getAddressById(addressId).subscribe(
      (data) => {
        this.addressDTO = data;
        console.log(this.addressDTO);
      },
      (error) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  onLocationSelected(location: { lat: number; lng: number }) {
    console.log('Selected Location:', location);
    // Use the latitude and longitude as needed
    this.addressDTO!.latitude = location.lat.toString();
    this.addressDTO!.longitude = location.lng.toString();
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }
}
