<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage">
  {{ header }}
</div>
<div class="d-flex justify-content-center col-12 mt-5" *ngIf="paginatedProducts.length > 0">
  <div class="col-2 font24 fw400 lh45 d-lg-flex d-none">Filter</div>
  <div class="col-7 justify-content-center d-lg-flex d-none">
    <div class="d-flex gap-2 justify-content-center align-items-center">
      <button (click)="previousPage()" [disabled]="currentPage === 1" class="bordernone bg-transparent">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
          <path
            d="M0.560453 8.45071C-0.108387 8.06959 -0.113845 7.10736 0.550627 6.71868L11.7423 0.172246C12.4068 -0.216431 13.2428 0.259959 13.2472 1.02975L13.3207 13.9952C13.3251 14.765 12.4945 15.2509 11.8257 14.8697L0.560453 8.45071Z"
            fill="#414040" />
        </svg>
      </button>
      <span *ngFor="let i of pageArray">
        <span role="button" (click)="goToPage(i)" class="index-page"
          [ngClass]="{ 'active-index': i === currentPage }">{{ i === -1 ? "..." : i }}
        </span></span>
      <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="bordernone bg-transparent">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
          <path
            d="M13.5004 6.62451C14.1683 7.00726 14.1714 7.9695 13.506 8.35655L2.29834 14.8755C1.63292 15.2626 0.79804 14.7841 0.79556 14.0143L0.753788 1.04873C0.751308 0.278929 1.58309 -0.204878 2.25099 0.177872L13.5004 6.62451Z"
            fill="#414040" />
        </svg>
      </button>
    </div>
  </div>
  <div
    class="col-lg-2 col-12 d-flex justify-content-lg-end justify-content-center align-items-center position-relative">
    <div class="bgorange px-5 py-1 text-white rounded-2" style="max-width: fit-content" role="button"
      (click)="sortOption = !sortOption">
      Sort by <img src="../../assets/Menu.png" alt="" class="ms-2 mb-1" />
    </div>

    <!-- Dropdown Options -->
    <div *ngIf="sortOption" class="sort-dropdown bg-white shadow-sm mt-1 position-absolute rounded-2 px-4 py-2"
      style="right: 0; top: 100%; z-index: 1000">
      <div (click)="toggleSortOrder('desc'); sortOption = !sortOption" class="dropdown-item" role="button">
        Price: High to Low
      </div>
      <div (click)="toggleSortOrder('asc'); sortOption = !sortOption" class="dropdown-item" role="button">
        Price: Low to High
      </div>
    </div>
  </div>
</div>
<div class="d-lg-flex  justify-content-center gap-3 col-12 mb-5 mt-2">
  <div class="d-lg-flex d-none justify-content-start p-4 flex-column col-2 gap-4 mt-5 filter-section"
    *ngIf="products.length > 0">
    <!-- Brand Filter -->
    <div class="font20 fw400 lh45">
      Brands
      <div *ngFor="let brand of uinqueBrandSet" class="font14 lh20">
        <input type="checkbox" (change)="updateBrandFilter(brand)" />
        <span class="ps-2">{{ brand }} </span>
      </div>
    </div>

    <!-- Category Filter -->
    <div class="font20 fw400 lh45">
      Categories
      <div *ngFor="let subCat of uniqueSubCatSet" class="font14 lh20">
        <input type="checkbox" (change)="updateSubCatFilter(subCat)" />
        <span class="ps-2">{{ subCat }} </span>
      </div>
    </div>

    <!-- Rating Filter -->
    <div class="font20 fw400 lh45">
      Ratings
      <div *ngFor="let rating of [5, 4, 3, 2, 1]" class="font14 lh20">
        <input type="checkbox" (change)="updateRatingFilter(rating)" />
        <span class="ps-2 lh20">{{ rating }} Stars</span>
      </div>
    </div>
    <!-- Price Range Filter -->
    <div class="font20 fw400 lh45">
      Price Range
      <div *ngFor="let range of priceRanges; let i = index" class="font14 lh20">
        <input type="checkbox" (change)="updatePriceRangeFilter(i)" />
        <span *ngIf="i < 3" class="ps-2">₹{{ range.min }} - ₹{{ range.max }}</span>
        <span *ngIf="i == 3" class="ps-2">₹{{ range.min }} & above</span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-start flex-column col-lg-9 col-12 p-2 p-lg-0 gap-4 mt-5"
    *ngIf="paginatedProducts.length > 0">
    <div *ngFor="let product of paginatedProducts" class="bg-light-grey-1 m-2 m-lg-0">
      <div
        class="d-flex flex-column flex-lg-row align-self-lg-start align-self-center justify-content-between col-12 col-lg-12 ms-0 product-container p-3 p-lg-0">
        <div
          class="d-flex col-lg-3 col-12 align-self-lg-start align-self-center justify-content-center justify-content-lg-between mb-lg-0 mb-3">
          <ng-container *ngIf="
              product.productImageList && product.productImageList.length > 0
            ">
            <div class="">
              <img class="thumbnail-box rounded-2" [src]="product.productImageList[0].imagePublicUrl"
                alt="Product Image" />
            </div>
          </ng-container>
          <ng-container *ngIf="
              !product.productImageList || product.productImageList.length === 0
            ">
            <div class="">
              <img class="thumbnail-box" src="../../../assets/no-images.jpg" alt="Product Image" />
            </div>
          </ng-container>
        </div>
        <div class="d-flex col-lg-6 col-12 flex-column py-3 gap-2">
          <div class="d-flex flex-column flex-lg-row justify-content-center">
            <div
              class="col align-items-lg-start mb-lg-0 mb-4 mb-md-0 text-center text-lg-start align-items-center flex-column">
              <div *ngIf="product.brandInfo">
                <img src="{{ product.brandInfo.brandLogoUrl }}" alt="{{ product.brandInfo.brandName }}" width="80px" />
              </div>
              <div class="mb-0 font24 fw400 two-line-text" role="button"
                (click)="navigateToProductDetails(product.productId)">
                {{ product.productHeading }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-lg-start justify-content-center font16 fw400">
            <div class="justify-content-center align-items-center d-flex" *ngIf="product.avgRatings">
              <div class="rating-stars">
                <div class="star" *ngFor="let star of starsArray; let i = index" [ngClass]="{
                    filled: i < getFloor(product.avgRatings.overallRating),
                    'half-filled':
                      i >= getFloor(product.avgRatings.overallRating) &&
                      i < product.avgRatings.overallRating
                  }">
                  ★
                </div>
              </div>
            </div>
            <div class="justify-content-center align-items-center d-flex">
              {{ product.avgRatings.totalRating }} Rated
            </div>
          </div>
          <div class="d-flex text-center text-lg-start font11 fw400 flex-column">
            <div class="mb-2">Sold By</div>
            <div class="font12">
              <span class="search-bg p-2" style="max-width: fit-content">{{
                product.sellerName
                }}</span>
            </div>
          </div>
          <div class="fw400 d-flex justify-content-center gap-2 justify-content-lg-start flex-lg-row flex-row">
            <div class="font-primary font32 lh45 align-items-center">
              ₹ {{ product.finalAmtWithTax | number : "1.2-2" }}
            </div>
            <div
              class="font12 fw400 pt-1 font-strike d-flex align-items-center text-light-grey justify-content-lg-start justify-content-center">
              M.R.P.: ₹ {{ product.mrp | number : "1.2-2" }}
            </div>
            <div class="font20 text-black d-flex align-items-center justify-content-lg-start justify-content-center "
              *ngIf="product.discountAmount > 0">
              ({{ product.discountAmount / product.mrp | percent : "1.0-0" }}
              Off)
            </div>
          </div>
          <div
            class="font13 text-lg-start text-center d-flex flex-row align-items-center justify-content-center justify-content-lg-start"
            *ngIf="product.isAvailableAtShippingAddress">
            Free Delivery By {{
            formatDate(product.deliveryTime) }} <span class="font12 fw500 font-primary font13">&nbsp;(Delivering
              to Pincode: {{pinCode}})&nbsp;</span>
            <span role="button" (click)="editPincode()" class="d-flex flex-row align-items-center"><img
                src="../../assets/edit.png" class="img-fluid ms-3" />
              <span class="bgorange text-white font12 ms-3 p-2 d-lg-flex d-none">&nbsp; Check at Pincode</span></span>
          </div>
          <div
            class="font13 text-lg-start text-center d-flex flex-row align-items-center justify-content-center justify-content-lg-start"
            *ngIf="!product.isAvailableAtShippingAddress">
            <span class="font12 fw500 text-danger">(Not
              Delivering
              to Pincode: {{pinCode}})</span>
            <span role="button" (click)="editPincode()" class="d-flex flex-row align-items-center"><img
                src="../../assets/edit.png" class="img-fluid ms-3" />
              <span class="bgorange text-white font12 ms-3 p-2 d-lg-flex d-none"> Check at Pincode</span></span>
          </div>
        </div>

        <div
          class="col-lg-2 col-12 d-flex gap-2 flex-lg-column flex-row justify-content-between justify-content-lg-start align-items-end pe-lg-5 pe-0 pt-5">
          <button class="cartButton px-2 font14" (click)="addToCart(product)">
            Add To Cart
          </button>
          <button class="detailsButton px-2 font14" (click)="navigateToProductDetails(product.productId)">
            View Details
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center font32 text-danger flex-column col-9 gap-4 mt-5"
    *ngIf="!(paginatedProducts.length > 0) && products.length > 0">
    There are no Products for Filtered Criteria.
    <button class="button-danger bgorange bordernone text-white font24" (click)="resetAllFilters()">
      Reset Filters
    </button>
  </div>
  <div class="d-flex justify-content-center align-items-center font32 text-danger flex-column col-9 gap-4 mt-5"
    *ngIf="!(products.length > 0)">
    No items for selected category available.
  </div>
</div>
<div class="col-12 justify-content-center d-flex mb-5" *ngIf="paginatedProducts.length > 0">
  <div class="d-flex gap-2 justify-content-center align-items-center">
    <button (click)="previousPage()" [disabled]="currentPage === 1" class="bordernone bg-transparent">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path
          d="M0.560453 8.45071C-0.108387 8.06959 -0.113845 7.10736 0.550627 6.71868L11.7423 0.172246C12.4068 -0.216431 13.2428 0.259959 13.2472 1.02975L13.3207 13.9952C13.3251 14.765 12.4945 15.2509 11.8257 14.8697L0.560453 8.45071Z"
          fill="#414040" />
      </svg>
    </button>
    <span *ngFor="let i of pageArray">
      <span role="button" (click)="goToPage(i)" class="index-page" [ngClass]="{ 'active-index': i === currentPage }">{{
        i === -1 ? "..." : i }}
      </span></span>
    <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="bordernone bg-transparent">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
        <path
          d="M13.5004 6.62451C14.1683 7.00726 14.1714 7.9695 13.506 8.35655L2.29834 14.8755C1.63292 15.2626 0.79804 14.7841 0.79556 14.0143L0.753788 1.04873C0.751308 0.278929 1.58309 -0.204878 2.25099 0.177872L13.5004 6.62451Z"
          fill="#414040" />
      </svg>
    </button>
  </div>
</div>

<ng-template class="modal fade mt-5 center" #pinCodeModal tabindex="-1" aria-label="Review" aria-hidden="true">
  <div class="d-flex gap-4 p-4 flex-column justify-content-center h-25 align-items-center">
    <div class="d-flex flex-column justify-content-center align-items-center gap-3">
      <label for="pinCode">Delivery PinCode</label>
      <input type="text" name="pinCode" [(ngModel)]="pinCode" class="text-center" maxlength="6" pattern="[0-9]*"
        placeholder="Enter Pin Code" />
    </div>
    <button class="cartButton" (click)="setPinCode()" [disabled]="pinCode.length<6">Save</button>
  </div>
</ng-template>