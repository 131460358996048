import { Injectable } from '@angular/core';

interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache = new Map<string, CacheEntry<any>>();
  private readonly CACHE_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

  get<T>(key: string): T | null {
    const entry = this.cache.get(key);
    // console.log(`Fetching from cache for key: ${key}`, entry);
    if (entry) {
      // Check if the cached data is still valid
      if (Date.now() - entry.timestamp < this.CACHE_DURATION) {
        return entry.data;
      }
      // Remove outdated cache
      this.cache.delete(key);
    }
    return null;
  }

  set<T>(key: string, data: T): void {
    // console.log(`Setting from cache for key: ${key}`, key,data);
    this.cache.set(key, { data, timestamp: Date.now() });
  }
}
