import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-whychoose',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './whychoose.component.html',
  styleUrl: './whychoose.component.scss'
})
export class WhychooseComponent {
  isHovered: boolean =false;
  isHovered1: boolean =false;
  isHovered2: boolean =false;
  isHovered3: boolean =false;
  isHovered4: boolean =false;

}
