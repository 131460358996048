<app-carousel></app-carousel>
<app-whychoose></app-whychoose>
<app-sale-card-home></app-sale-card-home>
<app-new-arrivals></app-new-arrivals>
<app-homepageoffers></app-homepageoffers>
<app-seasonaloffers></app-seasonaloffers>
<app-featured-products-card></app-featured-products-card>
<app-electrician-home-page></app-electrician-home-page>
<app-bestseller></app-bestseller>
<app-our-brands-card></app-our-brands-card>
