import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-category',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-category.component.html',
  styleUrl: './product-category.component.scss',
})
export class ProductCategoryComponent implements OnInit {
  categoryList: any[] = [];

  constructor(private productService: ProductService, private router: Router) {}
  ngOnInit(): void {
    this.getAllCategory();
  }

  getAllCategory() {
    this.productService.getAllCategory().subscribe((data) => {
      this.categoryList = data;
      for (let i = 0; i < data.length; i++) {
        this.productService
          .getAllSubCategory(this.categoryList[i].id)
          .subscribe((data) => {
            this.categoryList[i].subCatList = data;
          });
      }
      // console.log(this.categoryList);
    });
  }
  goToCategory(category: any) {
    // console.log(category);
    this.router.navigate(['/category', category]);
  }
  goToSubCategory(subcategory: any) {
    this.router.navigate(['/subcategory', subcategory]);
  }
}
