import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-featured-products-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './featured-products-card.component.html',
  styleUrl: './featured-products-card.component.scss',
})
export class FeaturedProductsCardComponent implements OnInit, AfterViewInit {
  constructor(
    private productService: ProductService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  featuredProducts: any[] = [];

  ngOnInit() {
    this.productService.getFaeturedCategory().subscribe((data) => {
      this.featuredProducts = data;
    });
  }

  scrollContainer: HTMLElement | null = null;

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollContainer = document.querySelector('.scroll-container');
    }
  }

  scrollLeft() {
    if (this.scrollContainer) {
      this.scrollContainer.scrollBy({ left: -150, behavior: 'smooth' });
    }
  }

  scrollRight() {
    if (this.scrollContainer) {
      this.scrollContainer.scrollBy({ left: 150, behavior: 'smooth' });
    }
  }
  goToCategory(name: any) {
    this.router.navigate(['/category', name]);
  }
}
