<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Contact Us
</div>
<div class="d-flex col-12 my-4 my-lg-5 py-0 py-lg-4 justify-content-center">
  <div
    class="font40 fw400 col-12 col-lg-10 ps-2 ps-lg-4 d-flex flex-column flex-lg-row align-items-center"
  >
    Get in Touch with
    <div
      class="font-primary pt-3 pt-lg-0 ms-1 align-items-center align-items-lg-start d-flex"
    >
      Power Bihar
    </div>
  </div>
</div>
<div
  class="d-flex col-12 my-5 py-2 gap-5 justify-content-center flex-column flex-lg-row"
>
  <div class="col-12 col-lg-3 px-4 ms-2 px-lg-0">
    <div class="font24 fw400 lh25">
      <img alt="address" src="../../assets/address.png" class="me-1" />Address
    </div>
    <div class="mt-4 ps-2 font16 fw400 lh25">
      Brain Crowd Software Pvt. Ltd.<br />
      315 ,Blue Pearl, 2nd Floor, 7th Main, 14th B Cross Rd, HSR Layout,
      Bengaluru, Karnataka 560102
    </div>
  </div>
  <div class="col-12 col-lg-3 px-4 ms-2 px-lg-0">
    <div class="font24 fw400">
      <img alt="contact" src="../../assets/phonecall.png" class="me-3" />Contact
    </div>
    <div class="mt-4 ps-0 font16 fw400 lh25">
      Phone: +91-90358-14717 <br />
      Email: contact&#64;brain-crowd.com
    </div>
  </div>
  <div class="col-12 col-lg-3 px-4 ms-2 px-lg-0">
    <div class="font24 fw400">
      <img
        alt="businesshours"
        src="../../assets/Clock.png"
        class="me-3"
      />Working Hours
    </div>
    <div class="mt-4 ps-0 font16 fw400 lh25">
      Monday to Saturday: 9:00 AM - 6:00 PM <br />
      Sunday: Closed
    </div>
  </div>
</div>
<div class="d-block m-lg-5 p-lg-5 m-1 p-1 rounded-2">
  <app-map
    [showSearch]="false"
    [latitude]="12.914659456733133"
    [longitude]="77.63352283928678"
    [zoom]="17"
    [showIcon]="true"
  ></app-map>
</div>
<div
  class="d-flex justify-content-center col-10 col-lg-11 align-items-center ms-4 ms-lg-3 my-5 font24 fw400"
  style="text-align: center"
>
  Have questions or need assistance? Our customer support team is here to help
  you.
</div>
<div
  class="d-flex bgimage col-12 justify-content-center py-5 flex-column align-items-center rounded2"
>
  <div
    class="col-12 d-flex flex-column gap-5 col-8 justify-content-center align-items-center pt-3"
  >
    <span class="text-white font32 fw500">Submit Your Query</span>
    <form
      class="d-flex flex-column gap-5 col-10 col-lg-8 justify-content-center align-items-center mt-3"
    >
      <div
        class="flex-lg-row d-flex col-12 justify-content-between flex-column"
      >
        <input
          type="text"
          placeholder="Name"
          class="col-12 col-lg-5 py-3 ps-2 rounded2"
        />
        <input
          type="text"
          placeholder="Email"
          class="col-12 col-lg-5 mt-5 mt-lg-0 py-3 ps-2 rounded2"
        />
      </div>
      <input
        type="text"
        placeholder="Subject"
        class="col-12 py-3 ps-2 rounded2"
      />
      <textarea
        type="textarea"
        placeholder="Message"
        class="col-12 pt-2 ps-2 rounded2"
        rows="8"
      ></textarea>
      <button
        class="col-6 col-lg-3 text-white font20 fw400 d-flex orange-bg justify-content-center py-2 rounded2"
      >
        Submit
      </button>
    </form>
  </div>
  <div
    class="text-white font24 fw400 my-2 my-lg-5 py-5 px-3 px-lg-0"
    style="text-align: center"
  >
    Stay updated with our latest products and offers by following us on social
    media.
  </div>
  <div class="d-flex gap-5">
    <div class="orangecircle">
      <img
        src="../../assets/Instagram.png"
        alt="Instagram"
        class="ms-3 ps-1 pt-1 mt-3"
      />
    </div>
    <div class="orangecircle">
      <img
        src="../../assets/Twitter.png"
        alt="Twitter"
        class="ms-3 ps-1 pt-1 mt-3"
      />
    </div>
    <div class="orangecircle">
      <img
        src="../../assets/Facebook.png"
        alt="Facebook"
        class="ms-3 ps-1 pt-1 mt-3"
      />
    </div>
  </div>
</div>

<!-- <app-aboutus></app-aboutus> -->
