import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';
import { WishlistService } from '../../services/wishlist.service';
import { ToastService } from '../shared/toast.service';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent implements OnInit {
  wishlistIcon = '/assets/fav.png'; // Default icon
  page = 1;
  pageSize = 4; // Default page size
  newProduct: any[] = [];
  cart: any;
  starsArray = [1, 2, 3, 4, 5];
  wishListed: boolean = false;
  showWishListModal: boolean = false;
  wishListModalMessage: any;

  @Input() wishListedProducts: any[] = [];
  @Input() productcat: any;
  @Input() isAuth: boolean = false;
  @Input() isSeller: boolean = false;
  @Input() user: any;
  @Input() pinCode: number = 800001;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private router: Router,
    private toastService: ToastService,
    private wishlistService: WishlistService
  ) {}

  getFloor(arg0: any) {
    return Math.floor(arg0);
  }

  ngOnInit() {
    this.getNewArrivals();
    this.updatePageSize(); // Set initial page size based on the screen size
  }

  // Adjust pageSize based on screen width
  @HostListener('window:resize', [])
  updatePageSize() {
    const width = window.innerWidth;

    if (width >= 1200) {
      this.pageSize = 3; // Large screens
    } else if (width >= 768) {
      this.pageSize = 3; // Medium screens
    } else {
      this.pageSize = 1; // Small screens
    }
  }

  lastPage() {
    if (this.page > 1) {
      this.page -= 1;
    } else {
      this.page = Math.ceil(this.newProduct.length / this.pageSize);
    }
  }

  nextPage() {
    if (this.page < Math.ceil(this.newProduct.length / this.pageSize)) {
      this.page += 1;
    } else {
      this.page = 1;
    }
  }

  getNewArrivals() {
    this.productService.getProductsByCategory(this.productcat).subscribe(
      (data) => {
        if (this.wishListedProducts) {
          this.newProduct = data.map(
            (product: { productId: any; listingId: any }) => {
              const isWishlisted = this.wishListedProducts.some(
                (wish) =>
                  wish.productDetailDto.productId === product.productId &&
                  wish.listingId === product.listingId
              );
              return { ...product, wishlisted: isWishlisted };
            }
          );
        } else {
          this.newProduct = data;
        }
      },
      (error) => {
        console.error('Error fetching new arrivals:', error);
      }
    );
  }

  toggleWishlist(product: any): void {
    this.wishlistService
      .addWishlist(product.productId, this.user.id, product.listingId)
      .subscribe((data) => {
        if (product.wishlisted) {
          this.wishListModalMessage =
            'Product has been removed from your wishlist!';
        } else {
          this.wishListModalMessage =
            'Product has been added to your wishlist!';
        }
        product.wishlisted = !product.wishlisted;

        this.showWishListModal = true;
        setTimeout(() => {
          this.showWishListModal = false;
        }, 2000);
      });
  }

  addToCart(product: any) {
    if (!this.isAuth) {
      this.router.navigate(['/signin']);
      return;
    }
    this.isSeller = this.user.isSeller || false;

    if (!this.hasCompleteUserDetails()) {
      this.showProfileIncompleteModal();
      return;
    }

    const addProductToCartDto = {
      price: product.finalAmtWithTax,
      product_color_id: '1',
      product_id: product.productId,
      quantity: product.lotSize,
      sellerId: product.sellerId,
      tax: product.taxAmount * product.lotSize,
      mrp: product.mrp,
      listingId: product.listingId,
      pinCode: this.pinCode,
    };

    if (product.isAvailableAtShippingAddress) {
      this.cartService.addCart(addProductToCartDto).subscribe(
        (data) => {
          this.cart = data;
          this.toastService.showToast(
            'Product added to cart successfully!',
            'bg-success'
          );
          this.updateCartItemCount();
        },
        (error) => {
          console.error('Error adding product to cart:', error);
          this.toastService.showToast(
            'Failed to add product to cart.',
            'bg-danger'
          );
        }
      );
    } else {
      // alert('Item not available at Default Shipping Address');
      this.toastService.showToast(
        'Item not available at Default Shipping Address.',
        'bg-warning'
      );
    }
  }

  navigateToProductDetails(productId: string, listingId: string) {
    this.router.navigate([`/product/${productId}/${listingId}`]);
  }

  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      const totalCount = cartList.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      this.cartService.updateCartItemCount(totalCount);
    });
  }

  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.user;
    return firstName && lastName && phoneNumber && emailAddress;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }
}
