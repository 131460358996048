<div
  class="products-container d-lg-flex col-12 px-lg-5 py-lg-5 flex-column gap-4 justify-content-center align-items-center"
  *ngIf="newProduct.length > 1"
>
  <div class="font-primary font16 fw400 my-2 text-center">Similar Products</div>
  <div class="flex-row d-flex justify-content-center align-items-center">
    <button
      (click)="lastPage()"
      class="bordernone bg-white text-center font-primary font48 me-2"
    >
      &lt;
    </button>
    <div
      *ngFor="let product of newProduct; let i = index"
      class="justify-content-center gap-2 d-flex"
    >
      <div
        *ngIf="i < page * pageSize && i >= page * pageSize - pageSize"
        class="product-card col-3 mb-4 mb-lg-0 me-2"
        [ngClass]="
          i < page * pageSize && i >= (page - 1) * pageSize
            ? 'd-block'
            : 'd-none'
        "
      >
        <div class="product-card-header" role="button">
          <div class="image-placeholder">
            <img
              [src]="
                product.productImageList[0]?.imagePublicUrl ||
                '/assets/default-image.png'
              "
              [alt]="product.productHeading"
              class="product-image"
            />
          </div>
          <button
            class="wishlist-btn"
            (click)="toggleWishlist(product)"
            *ngIf="isAuth"
          >
            <img
              src="../../assets/fav.png"
              alt="Wishlist"
              *ngIf="!product.wishlisted"
            />
            <img
              src="../../assets/red-fav.png"
              alt="Wishlist"
              *ngIf="product.wishlisted"
            />
          </button>
        </div>
        <div
          class="product-details"
          (click)="
            navigateToProductDetails(product.productId, product.listingId)
          "
        >
          <h3 class="product-name">{{ product.productHeading }}</h3>
          <p class="product-price">
            <span class="original-price font12 fw400"
              >MRP: ₹{{ product.mrp | number : "1.2-2" }}</span
            >
            <span class="discounted-price font15 fw400"
              >₹{{ product.finalAmtWithTax | number : "1.2-2" }}</span
            >
          </p>
          <div
            class="my-3 justify-content-center align-items-center d-flex"
            *ngIf="product.avgRatings"
          >
            <div class="rating-stars">
              <div
                class="star"
                *ngFor="let star of starsArray; let i = index"
                [ngClass]="{
                  filled: i < getFloor(product.avgRatings.overallRating),
                  'half-filled':
                    i >= getFloor(product.avgRatings.overallRating) &&
                    i < product.avgRatings.overallRating
                }"
              >
                ★
              </div>
            </div>
          </div>
        </div>
        <div
          class="product-card-footer d-flex justify-content-center align-items-center"
        >
          <!-- <button class="buy-now-btn">Buy Now</button> -->
          <span class="font12 font-primary" *ngIf="product.lotSize > 1"
            >* Sold in Pack of {{ product.lotSize }}</span
          >
          <span class="font12 text-white" *ngIf="product.lotSize <= 1"
            >* Sold in Pack of {{ product.lotSize }}</span
          >
          <button class="cartButtonOrange col-11" (click)="addToCart(product)">
            Add To Cart
          </button>
        </div>
      </div>
    </div>
    <button
      (click)="nextPage()"
      class="bordernone bg-white text-center font-primary font48"
    >
      &gt;
    </button>
  </div>
</div>
<div
  *ngIf="showWishListModal"
  style="
    position: fixed !important;
    right: 10px !important;
    top: 5%;
    z-index: 1000;
  "
>
  <span
    class="p-4 d-flex justify-content-center align-items-center bg-white bgorangeshadow rounded-2 font-primary"
    >{{ wishListModalMessage }}</span
  >
</div>
