<!-- <div class="">
  <div class="scroll-container justify-content-around bgyellow" id="">
    <div class="py-3 d-flex justify-content-between">
      <div class="col-2 scroll-item" *ngFor="let items of catList">
        <div class="item-container" role="button" (click)="goToCategory(items.categoryName)">
          <div class="img-bg pt-4 px-4 pb-3" style="min-width: 180px">
            <img class="circle box" src="{{ items.image?.image }}" alt="" width="150px" height="150px" />
            <div class="font14 fw400 mt-2" role="button" (click)="goToCategory(items.categoryName)">
              {{ items.categoryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="bgyellow d-flex col-12 justify-content-center">
  <div
    class="col-lg-11 col-12 font12 text-black overflow-hidden d-flex align-items-center justify-content-around bgyellow py-4">
    <!-- <span>Available in</span> -->
    <div class="marquee">
      <div class="marquee-content">
        <div *ngFor="let items of catList" class="me-3">
          <div class="item-container align-items-center justify-content-center" role="button"
            (click)="goToCategory(items.categoryName)">
            <div class="align-items-center d-flex flex-column img-bg justify-content-center pb-3 pt-4 px-4"
              style="min-width: 180px">
              <img class="circle box" src="{{ items.image?.image }}" alt="" width="150px" height="150px" />
              <div class="font14 fw400 mt-2" role="button" (click)="goToCategory(items.categoryName)">
                {{ items.categoryName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Duplicate the content to create a continuous scroll effect -->
      <div class="marquee-content">
        <div *ngFor="let items of catList" class="me-3">
          <div class="item-container" role="button" (click)="goToCategory(items.categoryName)">
            <div class="align-items-center d-flex flex-column img-bg justify-content-center pb-3 pt-4 px-4"
              style="min-width: 180px">
              <img class="circle box" src="{{ items.image?.image }}" alt="" width="150px" height="150px" />
              <div class="font14 fw400 mt-2" role="button" (click)="goToCategory(items.categoryName)">
                {{ items.categoryName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <hr class="m-0" /> -->