<div class="carousel my-1">
  <div class="carousel-images" [style.transform]="transform">
    <div *ngFor="let item of items" class="carousel-image">
      <img [src]="item.src" [alt]="item.alt" />
    </div>
  </div>
  <button class="carousel-arrow left" (click)="prevSlide()">
    <img src="../../assets/left-double-arrow.png" alt="" />
  </button>
  <button class="carousel-arrow right" (click)="nextSlide()">
    <img src="../../assets/right-double-arrow.png" alt="" />
  </button>
  <div class="carousel-dots  text-center d-flex justify-content-center col-12 align-items-center">
    <div class="bg-light-grey rounded-5 px-3 py-2 align-items-center" style="max-width: fit-content !important;">
      <span *ngFor="let dot of items; let i = index" [class.active]="currentIndex === i" (click)="goToSlide(i)"></span>
    </div>
  </div>
</div>