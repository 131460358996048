<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Cart
</div>
<div
  class="flex-column-reverse flex-lg-row d-flex p-lg-5 p-0 m-2 m-lg-5 justify-content-between gap-5"
  *ngIf="cartItems && cartItems.length > 0; else emptyCartMessage"
>
  <div class="col-lg-3 col-12" *ngIf="cartItems && cartItems.length > 0">
    <div
      class="col-12 cart-price-details d-flex justify-content-between flex-column"
    >
      <div
        class="font20 fw400 text-white bg-grey px-4 py-3 rounded-top-2 mb-4 zindex2"
      >
        Price Details
      </div>
      <div
        class="font20 fw400 px-4 py-1 rounded4 mb-1 d-flex justify-content-between"
      >
        <div>Price Details {{ numberOfItems }} items:</div>
        <div>₹ {{ cartItems[0].total_cart_price | number : "1.2-2" }}</div>
      </div>
      <div
        class="font20 fw400 px-4 py-1 rounded4 mb-1 d-flex justify-content-between"
      >
        <div>Delivery Charges:</div>
        <div class="font18 font-primary">Free</div>
      </div>
      <div class="px-4 py-0 mb-2">
        <hr />
      </div>
      <div
        class="font20 fw400 px-4 py-1 rounded4 mb-4 font-primary d-flex justify-content-between"
      >
        <div>Total Amount :</div>
        <div>₹ {{ cartItems[0].total_cart_price | number : "1.2-2" }}</div>
      </div>
      <button
        class="font20 fw400 px-4 py-2 rounded4 mb-5 bgorange text-white text-center mx-4 bordernone"
        [disabled]="unAvailableItem"
        (click)="proceedToCheckout()"
      >
        Proceed to checkout
      </button>
    </div>
  </div>
  <div class="col-lg-9 col-12">
    <div class="d-flex px-lg-5 pb-4 flex-column">
      <div class="col-lg-12 mb-lg-0 flex-column flex-lg-row">
        <div class="cart-price-details mb-5" *ngFor="let product of cartItems">
          <div class="bg-grey py-3 px-4 font20 fw400 rounded-top-2">&nbsp;</div>
          <div class="rounded1 px-lg-5 px-3 py-4">
            <div class="d-flex flex-column flex-md-row justify-content-center">
              <div
                class="d-flex col-md-2 align-self-lg-start align-self-center mb-md-0"
              >
                <ng-container
                  *ngIf="
                    product.productImageList &&
                    product.productImageList.length > 0
                  "
                >
                  <div class="thumbnail-box">
                    <img
                      class=""
                      width="100"
                      [src]="product.productImageList[0].imagePublicUrl"
                      alt="Product Image"
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    !product.productImageList ||
                    product.productImageList.length === 0
                  "
                >
                  <div class="thumbnail-box">
                    <img
                      width="100"
                      src="../../../assets/no-images.jpg"
                      alt=""
                    />
                  </div>
                </ng-container>
              </div>
              <div class="col-12 col-lg-10">
                <div
                  class="d-flex flex-column flex-lg-row justify-content-center"
                >
                  <div
                    class="col align-items-lg-start mb-lg-3 mb-4 mb-md-0 text-center text-lg-start align-items-center"
                  >
                    <h3
                      class="mb-0 lh40 font-primary font24 fw400 two-line-text"
                      role="button"
                      (click)="navigateToProduct(product.product_id)"
                    >
                      {{ product.productName }}
                    </h3>
                    <p class="mb-0 font14 two-line-text">
                      {{ product.productDescription }}
                    </p>
                  </div>
                  <div
                    class="col align-items-lg-end align-items-center text-center mb-3 mb-lg-0"
                  >
                    <div class="d-flex flex-column align-items-md-end">
                      <p class="font18 mb-0">
                        Total Price
                        <span class="font-small"
                          >({{ product.quantity }} nos)</span
                        >
                        : ₹ {{ product.total_price | number : "1.2-2" }}
                        <span class="font12">(incl of GST)</span>
                      </p>
                      <p class="mb-2 fw-400 font14">
                        Unit Price <span class="font12">(incl of GST):</span>
                        <span
                          class="font-strike text-light-blue font14 mx-2"
                          *ngIf="product.mrp != product.price"
                          >₹ {{ product.mrp | number : "1.2-2" }}</span
                        >
                        <span class="font-larger"
                          >₹ {{ product.price | number : "1.2-2" }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-lg-between justify-content-center align-items-center flex-column flex-lg-row"
                >
                  <div
                    class="mt-2 d-flex justify-content-center flex-column align-items-center align-items-lg-start mb-4 mb-lg-0"
                  >
                    <div class="quantity-wrapper">
                      <button
                        class="quantity-btn"
                        [disabled]="product.quantity == 1"
                        (click)="
                          decrementQuantity(
                            product.identifier,
                            product.quantity
                          )
                        "
                      >
                        -
                      </button>

                      <span
                        class="mx-2 px-2 font20 fw400 lh40 align-items-center flex-row rounded-2"
                        ><input
                          type="number"
                          [(ngModel)]="product.quantity"
                          style="max-width: 3rem; max-height: 30px"
                          class="text-center ps-1 font12 rounded-2"
                          (ngModelChange)="
                            updatedQuantity(
                              product.identifier,
                              product.quantity
                            )
                          "
                      /></span>

                      <button
                        class="quantity-btn"
                        (click)="
                          incrementQuantity(
                            product.identifier,
                            product.quantity
                          )
                        "
                      >
                        +
                      </button>
                    </div>
                    <p
                      *ngIf="
                        product.quantity === null || product.quantity === 0
                      "
                      class="d-flex text-danger mb-0 font12 justify-content-end"
                    >
                      {{
                        product.quantity === null
                          ? "Quantity cannot be null"
                          : "Quantity can't be zero"
                      }}
                    </p>
                    <div *ngIf="!product.isAvailable">
                      <span class="text-danger font12"
                        >Item is not available at {{ pinCode }}</span
                      >
                    </div>
                  </div>
                  <div class="mt-lg-2 mt-1">
                    <span
                      class="bg-danger text-white px-3 rounded4 py-1"
                      (click)="deleteItem(product.identifier)"
                      role="button"
                    >
                      Remove from Cart
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="profileIncompleteModal"
      tabindex="-1"
      aria-labelledby="profileIncompleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="profileIncompleteModalLabel">
              Incomplete Profile
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Please complete your profile with first name, last name, phone
            number, and email address.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              (click)="navigateToProfile()"
            >
              Go to Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #emptyCartMessage class="col-12 d-flex my-5">
  <div class="d-flex justify-content-center align-items-center col-12">
    <!-- <div><img src="assets/big-cart.png" alt="" /></div> -->
    <div class="font20 fw-700 my-5 text-danger">
      Your shopping cart is currently empty.
    </div>
  </div>
</ng-template>
