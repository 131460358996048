import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';
import { WishlistService } from '../../services/wishlist.service';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent implements OnInit {
  wishlistIcon = '/assets/fav.png'; // Default icon
  newProduct: any[] = [];
  cart: any;
  starsArray = [1, 2, 3, 4, 5];
  wishListed: boolean = false;
  showWishListModal: boolean = false;
  wishListModalMessage: any;
  @Input() wishListedProducts: any[] = [];
  @Input() productcat: any;
  @Input() isAuth: boolean = false;
  @Input() isSeller: boolean = false;
  @Input() user: any;
  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private router: Router,
    private wishlistService: WishlistService
  ) {}

  ngOnInit() {
    this.getNewArrivals();
    // this.user();
  }
  getFloor(arg0: any) {
    return Math.floor(arg0);
  }

  getNewArrivals() {
    this.productService.getProductsByCategory(this.productcat).subscribe(
      (data) => {
        // Assume data is the list of new products
        this.newProduct = data.map((product: { productId: any }) => {
          // Check if the productId exists in the wishListedProducts array
          const isWishlisted = this.wishListedProducts.some(
            (wish) => wish.productDetailDto.productId === product.productId
          );
          console.log(isWishlisted);
          // Add a 'wishlisted' property to the product and set it to true or false
          return { ...product, wishlisted: isWishlisted };
        });

        // If you need to stop loading spinner or something, you can do it here
        // this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching new arrivals:', error);
        // this.isLoading = false; // Handle the loading state in case of error
      }
    );
  }

  toggleWishlist(product: any): void {
    // this.userDTO = data;
    this.wishlistService
      .addWishlist(product.productId, this.user.id)
      .subscribe((data) => {
        if (product.wishlisted) {
          this.wishListModalMessage =
            'Product has been removed from your wishlist!';
        } else {
          this.wishListModalMessage =
            'Product has been added to your wishlist!';
        }
        product.wishlisted = !product.wishlisted;

        this.showWishListModal = true;
        setTimeout(() => {
          this.showWishListModal = false;
        }, 2000);
      });
  }

  addToCart(product: any) {
    if (!this.isAuth) {
      this.router.navigate(['/signin']);
      return;
    }
    this.isSeller = this.user.isSeller || false;

    if (!this.hasCompleteUserDetails()) {
      this.showProfileIncompleteModal();
      return;
    }

    const addProductToCartDto = {
      price: product.finalAmtWithTax,
      product_color_id: '1',
      product_id: product.productId,
      quantity: '1',
      sellerId: product.sellerId,
      tax: product.taxAmount,
      mrp: product.mrp,
    };

    if (product.isAvailableAtShippingAddress) {
      this.cartService.addCart(addProductToCartDto).subscribe(
        (data) => {
          this.cart = data;
          alert('Product added to cart successfully.');
          this.updateCartItemCount();
        },
        (error) => {
          console.error('Error adding product to cart:', error);
        }
      );
    } else {
      alert('Item not available at Default Shipping Address');
    }
  }
  navigateToProductDetails(productId: string) {
    this.router.navigate([`/product/${productId}`]);
  }
  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      // Calculate total items count in the cart
      const totalCount = cartList.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      // Update cart item count in the service
      this.cartService.updateCartItemCount(totalCount);
    });
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.user;
    return firstName && lastName && phoneNumber && emailAddress;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }
}
