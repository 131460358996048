import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleMapSearchComponent } from '../google-map-search/google-map-search.component';

@Component({
  selector: 'app-electrician-home-page',
  standalone: true,
  imports: [GoogleMapSearchComponent],
  templateUrl: './electrician-home-page.component.html',
  styleUrl: './electrician-home-page.component.scss',
})
export class ElectricianHomePageComponent {
  constructor(private router: Router) {}
  navigateToElectricianSearchPage() {
    const lat = 25.5940947;
    const long = 85.1375645;
    this.router.navigate(['/electricianSearch'], {
      queryParams: { latitude: lat, longitude: long },
    });
  }
}
