import { Component, TransferState, makeStateKey } from '@angular/core';
import { ProductCardComponent } from '../product-card/product-card.component';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

const NEW_ARRIVALS_KEY = makeStateKey<any[]>('bestSeller');

@Component({
  selector: 'app-bestseller',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bestseller.component.html',
  styleUrl: './bestseller.component.scss',
})
export class BestsellerComponent {
  productList: any[] = [];
  starsArray = [1, 2, 3, 4, 5];
  constructor(
    private productService: ProductService,
    private transferState: TransferState,
    private router: Router
  ) {}
  ngOnInit(): void {
    const savedData = this.transferState.get(NEW_ARRIVALS_KEY, null);
    // console.log(NEW_ARRIVALS_KEY,savedData);
    if (savedData) {
      this.productList = savedData;
    } else {
      this.productService.getBestSeller().subscribe((data) => {
        this.productList = data;
        this.transferState.set(NEW_ARRIVALS_KEY, data);
        // console.log('New Arrivals:', this.productList);
      });
    }
  }
  getFloor(arg0: any) {
    return Math.floor(arg0);
  }
  navigateToProductDetails(id: string, listingId: any) {
    this.router.navigate([`/product/${id}/${listingId}`]);
  }
}
