import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { UsersService } from '../../services/user.service';
import { CreateOrderRequest } from '../dto/CreateOrderRequest';
import { OrderService } from '../../services/order.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../auth-service.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-cart-payment',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cart-payment.component.html',
  styleUrl: './cart-payment.component.scss',
})
export class CartPaymentComponent {
  deliveryFee: any;
  goToAddress() {
    throw new Error('Method not implemented.');
  }
  cartList: any[] = [];
  numberOfItems: number = 0;
  totalQuantity: number = 0;
  totalCartPrice: number = 0;
  isCodSelected: boolean = false;
  orderDetails: any;
  userDTO: any = { address: {} };
  isSeller: boolean = false;
  cart_id: string = '';
  loading: boolean = false;
  cartItems: any[] = [];
  selectedPayment: string = '';
  addressId: string = '';
  selectedAddressId: string = '';
  selectedAddress: any;
  showCard: any;
  showBank: any;
  showUPI: any;

  constructor(
    private cartService: CartService,
    private orderService: OrderService,
    private userService: UsersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.addressId = params['addressId']; // Capture the addressId passed from CartAddressComponent
      if (this.addressId) {
        this.selectedAddressId = this.addressId; // Assign the addressId to selectedAddressId
        this.getAddressDetails(this.addressId); // Fetch the selected address details
      }
      console.log('Selected Address ID:', this.selectedAddressId);
    });
    this.getCartList();
    this.user();
    this.getDeliveryFee();
  }
  getDeliveryFee() {
    this.cartService
      .getDeliveryFee(this.localStorage.getItem('pinCode'))
      .subscribe((data) => {
        this.deliveryFee = data;
      });
  }
  getAddressDetails(addressId: string) {
    this.userService.getAddressById(addressId).subscribe((data) => {
      this.selectedAddress = data; // Store fetched address
    });
  }

  selectPaymentMethod(paymentMethod: string) {
    this.isCodSelected = true;
    this.selectedPayment = paymentMethod; // Set the selected payment method
    console.log('Selected Payment Method:', this.selectedPayment);
  }

  getCartList() {
    this.cartService.getCartList().subscribe((data) => {
      // console.log('Fetched Cart Data:', data);
      this.cartList = data;
      // this.cartStateService.setCartData(this.cartList);
      this.numberOfItems = this.cartList.length;
      this.calculateTotalQuantity();
      this.totalCartPrice = this.cartList[0]?.total_cart_price || 0;
      this.cart_id = this.cartList[0]?.cart_id || '';
    });
  }
  calculateTotalQuantity() {
    this.totalQuantity = this.cartList.reduce(
      (sum, item) => sum + item.quantity,
      0
    );
  }

  createOrder() {
    this.loading = true; // Start loading when create API is called
    const orderDetails = new CreateOrderRequest();

    orderDetails.quantity = this.totalQuantity;
    orderDetails.price = this.totalCartPrice;
    orderDetails.orderStatus = 'CREATE_ORDER';
    orderDetails.paymentMode = this.selectedPayment;

    orderDetails.cartIdentifier = this.cartList.map((item) => item.identifier);

    orderDetails.commission = '';
    orderDetails.salesPerson = '';
    orderDetails.sellerPinCode = '';
    orderDetails.cart_id = this.cart_id;
    orderDetails.addressId = this.selectedAddressId;

    this.orderService.createOrder(orderDetails).subscribe(
      (response) => {
        console.log('Order created successfully:', response);
        const orderIdentifier = response[0].identifier; // Assuming the order ID is returned in the response
        console.log(orderIdentifier);
        this.placeOrder(orderIdentifier);
      },
      (error) => {
        console.error('Error creating order:', error);
        this.loading = false; // Stop loading in case of error
      }
    );
  }

  placeOrder(orderIdentifier: string) {
    // console.log('Placing order with identifier:', orderIdentifier);
    this.orderService.placeOrder(orderIdentifier).subscribe(
      (response) => {
        // console.log('Order placed successfully:', response);
        this.loading = false; // Stop loading after place order is successful
        this.cartService.updateCartItemCount(0);
        this.router.navigate(['/order/confirmation'], {
          queryParams: {
            orderIdentifier: orderIdentifier,
          },
        });
      },
      (error) => {
        console.error('Error placing order:', error);
        this.loading = false; // Stop loading in case of error
      }
    );
  }

  // leavePage() {
  //   const modalElement = document.getElementById('backModal');
  //   if (modalElement) {
  //     modalElement.classList.remove('show');
  //     modalElement.style.display = 'none';
  //     document.body.classList.remove('modal-open');
  //     const modalBackdrop = document.querySelector('.modal-backdrop');
  //     if (modalBackdrop) {
  //       modalBackdrop.remove();
  //     }
  //   }

  //   this.router.navigate(['/cart/summary']);
  // }

  user() {
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;
      // this.allAddress();
    });
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
}
