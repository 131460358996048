<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage">
  Our Products
</div>
<div class="text-center font16 fw400 py-5">
  At Power Bihar, we offer an extensive range of electrical products to cater to
  all your needs. Browse our categories to find exactly what you're looking for.
</div>
<div class="d-flex flex-column justify-content-center col-12 gap-4 align-items-center">
  <div *ngFor="let category of categoryList" class="d-flex col-10 justify-content-center align-items-center rounded4">
    <div class="col-12 d-flex justify-content-center align-items-center text-white flex-column rounded4"
      *ngIf="category.subCatList?.length > 0">
      <div class="col-12 text-center bgyellow rounded4 py-5 font32 fw400 align-items-center" style="background-color: rgb(1 2 5 / 76%);
        background-repeat: repeat-x;
        background-blend-mode: darken;
        background-size: contain;
        z-index: 2;
        background-image: url({{category.image.image}});" role="button" (click)="goToCategory(category.categoryName)">
        {{ category.categoryName }}
      </div>
      <div class="flex-wrap d-flex gap-5 text-white my-4 col-12 justify-content-around me-2 me-lg-0">
        <div *ngFor="let subCat of category.subCatList" role="button" (click)="goToSubCategory(subCat.id)">
          <div class="subcatimg align-items-center d-flex justify-content-center rounded-top-2" *ngIf="subCat.image">
            <img src="{{ subCat.image?.image }}" class="subcatimg" alt="{{ subCat.subcategoryDescription }}" />
          </div>
          <div class="subcatimg align-items-center d-flex justify-content-center rounded-top-2 font-primary font24"
            *ngIf="!subCat.image">
            {{ subCat.subcategoryDescription }}
          </div>
          <div class="font20 fw400 bgyellow py-2 col-12 text-center rounded-bottom-2">
            {{ subCat.subcategoryName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>