import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { CommonModule } from '@angular/common';
import { CartUpdateDTO } from '../dto/CartUpdateDTO';
import { Router } from '@angular/router';
import { UsersService } from '../../services/user.service';
import { AuthService } from '../auth-service.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})
export class CartComponent implements OnInit {
  totalQuantity: any;
  userDTO: any;
  isSeller: any;
  cartAvailability: any;
  unAvailableItem: boolean = false;
  quantity: any;
  pinCode: any;
  deliveryFee: number = 0;
  constructor(
    private cartService: CartService,
    private router: Router,
    private userService: UsersService,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}
  cartItems: any[] = [];
  numberOfItems: any;
  ngOnInit(): void {
    this.pinCode = this.localStorage.getItem('pinCode');
    this.getCartList();
    this.getDeliveryFee();
  }
  getLotSizeSteps(lotSize: number): number[] {
    const steps = [];
    for (let i = lotSize; i <= lotSize * 10; i += lotSize) {
      steps.push(i);
    }
    return steps;
  }
  getDeliveryFee() {
    this.cartService.getDeliveryFee(this.pinCode).subscribe((data) => {
      this.deliveryFee = data;
    });
  }
  onQuantityChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    this.quantity = value; // Convert string to number
    console.log('Selected quantity:', this.quantity);
  }
  navigateToProduct(productId: any, listingId: any) {
    this.router.navigate([`/product/${productId}/${listingId}`]);
  }
  getCartList() {
    this.cartService.getCartList().subscribe((data) => {
      this.cartItems = data;
      this.numberOfItems = data.length;
      this.unAvailableItem = false;
      this.cartService.checkAvailability().subscribe((data) => {
        this.cartAvailability = data;
        // console.log(this.cartAvailability);
        for (let i = 0; i < this.cartAvailability.length; i++) {
          if (
            this.cartAvailability[i].cartIdentifier ==
            this.cartItems[i].identifier
          ) {
            this.cartItems[i].isAvailable =
              this.cartAvailability[i].isAvailable;
          }
          if (!this.cartAvailability[i].isAvailable) {
            this.unAvailableItem = true;
          }
        }
      });
    });
  }
  calculateTotalQuantity() {
    this.totalQuantity = this.cartItems.reduce(
      (sum, item) => sum + item.quantity,
      0
    );
  }
  updatedQuantity(productIdentifier: any, quantity: number) {
    const productToUpdate = this.cartItems.find(
      (product: { identifier: string }) =>
        product.identifier === productIdentifier
    );
    const cartUpdateData: CartUpdateDTO = {
      price: productToUpdate.price,
      quantity: quantity,
      tax: productToUpdate.taxAmount * productToUpdate.quantity,
      pinCode: this.pinCode,
    };
    this.cartService
      .updateCartQuantity(productIdentifier, cartUpdateData)
      .subscribe((data) => {
        this.getCartList(); // Update cart list after successful update
        this.updateCartItemCount();
      });
  }
  incrementQuantity(productIdentifier: any, quantity: number, lotSize: number) {
    const productToUpdate = this.cartItems.find(
      (product: { identifier: string }) =>
        product.identifier === productIdentifier
    );
    const cartUpdateData: CartUpdateDTO = {
      price: productToUpdate.price,
      quantity: quantity + lotSize,
      tax: productToUpdate.taxAmount * productToUpdate.quantity,
      pinCode: this.pinCode,
    };
    this.cartService
      .updateCartQuantity(productIdentifier, cartUpdateData)
      .subscribe((data) => {
        this.getCartList(); // Update cart list after successful update
        this.updateCartItemCount();
      });
  }
  decrementQuantity(productIdentifier: any, quantity: number, lotSize: number) {
    const productToUpdate = this.cartItems.find(
      (product: { identifier: string }) =>
        product.identifier === productIdentifier
    );
    const cartUpdateData: CartUpdateDTO = {
      price: productToUpdate.price,
      quantity: quantity - lotSize,
      tax: productToUpdate.taxAmount * productToUpdate.quantity,
      pinCode: this.pinCode,
    };
    this.cartService
      .updateCartQuantity(productIdentifier, cartUpdateData)
      .subscribe((data) => {
        this.getCartList(); // Update cart list after successful update
        this.updateCartItemCount();
      });
  }
  updateQuantity(productIdentifier: string) {
    const productToUpdate = this.cartItems.find(
      (product: { identifier: string }) =>
        product.identifier === productIdentifier
    );

    if (
      productToUpdate &&
      productToUpdate.quantity !== null &&
      productToUpdate.quantity > 0 &&
      confirm('Do you want to update the quantity?')
    ) {
      const cartUpdateData: CartUpdateDTO = {
        price: productToUpdate.price,
        quantity: productToUpdate.quantity,
        tax: productToUpdate.taxAmount * productToUpdate.quantity,
        pinCode: this.pinCode,
      };

      this.cartService
        .updateCartQuantity(productIdentifier, cartUpdateData)
        .subscribe((data) => {
          this.getCartList(); // Update cart list after successful update
          this.updateCartItemCount();
          alert('Product Quantity has been updated succesfully!');
        });
    } else if (
      productToUpdate &&
      (productToUpdate.quantity === null || productToUpdate.quantity === 0)
    ) {
      console.error('Quantity cannot be null or zero.');
      // You can display an error message to the user here if needed
    } else {
      console.error('Product not found in the cart.');
    }
  }

  deleteItem(cartIdentifier: string) {
    if (confirm('Do you want to remove the cart item?')) {
      this.cartService.deleteCartItem(cartIdentifier).subscribe((data) => {
        this.getCartList(); // Update cart list after successful deletion
        this.updateCartItemCount();
      });
    }
  }

  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      const totalCount = cartList.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      this.cartService.updateCartItemCount(totalCount);
    });
  }
  proceedToCheckout() {
    console.log('Cart Data before navigation:', this.cartItems);
    // this.cartStateService.setCartData(this.cartList);
    // this.loading = true;
    if (!this.isLoggedIn()) {
      this.router.navigate(['/login']);
      return;
    }

    // Fetch user details
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;

      // Check if user details are complete
      if (!this.hasCompleteUserDetails()) {
        this.showProfileIncompleteModal();
        return;
      }

      this.cartService.checkAvailability().subscribe((data) => {
        this.cartAvailability = data;
        // console.log(this.cartAvailability);
        for (let i = 0; i < this.cartAvailability.length; i++) {
          if (
            this.cartAvailability[i].cartIdentifier ==
            this.cartItems[i].identifier
          ) {
            this.cartItems[i].isAvailable =
              this.cartAvailability[i].isAvailable;
          }
          if (!this.cartAvailability[i].isAvailable) {
            this.unAvailableItem = true;
          }
        }
        // this.loading = false;
        // console.log(this.cartItems);
        if (!this.unAvailableItem) {
          this.router.navigate(['/cart/address']);
        } else {
          alert(
            'Some Items are not deliverable to you default shipping address. Kindly remove them to proceed.'
          );
        }
      });
    });
  }
  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
  user() {
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;
    });
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.userDTO;
    return phoneNumber;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }
}
