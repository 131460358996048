import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
// import { AuthService } from './auth-service.service';
import { environment } from '../environments/environment';
import { AuthService } from '../app/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private baseUrl = environment.API_URL;
  constructor(private http: HttpClient, private authService: AuthService) {}

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated(); // Check authentication status using AuthService
  }

  generateOTP(phoneNumber: string): Observable<any> {
    const url = `${this.baseUrl}/users/generateOTP/${phoneNumber}`;
    return this.http.post<any>(url, phoneNumber).pipe(first());
  }

  validateOTP(
    userPhoneNumber: string,
    adminPhoneNumber: string,
    otp: string
  ): Observable<any> {
    const url = `${this.baseUrl}/login/validateotp`;
    const requestBody = {
      userPhoneNumber: userPhoneNumber,
      adminPhoneNumber: adminPhoneNumber,
      otp: otp,
    };
    return this.http.post<any>(url, requestBody);
  }

  logout(): Observable<any> {
    const url = `${this.baseUrl}/login/logout`;
    const authToken = this.authService.getToken(); // Get the authentication token from AuthService
    const headers = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });

    return this.http.post<any>(url, null, { headers: headers });
  }

  generateLoginAsOTP(user: string,admin: string) {
    // throw new Error('Method not implemented.');
  const url = `${this.baseUrl}/login/generateOTPAsUser/${user}/${admin}`;
    return this.http.get<any>(url).pipe(first());
  }
  validateAsLoginOTP(
    userPhoneNumber: string,
    adminPhoneNumber: string,
    otp: string
  ): Observable<any> {
    const url = `${this.baseUrl}/login/validateAsLogin`;
    const requestBody = {
      userPhoneNumber: userPhoneNumber,
      adminPhoneNumber: adminPhoneNumber,
      otp: otp,
    };
    return this.http.post<any>(url, requestBody);
  }
}
