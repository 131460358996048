import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/user.service';
import { AuthService } from '../auth-service.service';
import { CommonModule } from '@angular/common';
import { OrderService } from '../../services/order.service';
import { NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserDTO } from '../dto/UserDTO';

@Component({
  selector: 'app-myaccount',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './myaccount.component.html',
  styleUrl: './myaccount.component.scss',
})
export class MyaccountComponent implements OnInit {
  userId: any;
  userDTO: UserDTO = new UserDTO();
  notifications: any[] = [];
  latestNotifications: any[] = [];
  unreadCount: number = 0;
  showNotifications: boolean = false;
  isEditMode = false;
  saveSuccess: boolean = false;
  saveUser: any;

  constructor(
    private userService: UsersService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.userId = params['id'];

      this.user();
      // this.getProduct(this.subCategoryId);
    });
    this.notificationService.getNotification().subscribe((data) => {
      // console.log('API Response:', data);
      this.notifications = data;
      this.latestNotifications = this.notifications.slice(0, 5);
      this.unreadCount = this.notifications.filter(
        (notification) => notification.notificationStatus === 'UNREAD'
      ).length;
    });
  }
  user() {
    this.userService.getUser().subscribe((data) => {
      console.log('API Response:', data);
      this.userDTO = data;
      this.userDTO.phoneNumber = data.phoneNumber;
    });
  }
  navigateToOrderDetails() {
    this.router.navigate(['/buyer/order/listing']);
  }

  toggleNotifications(): void {
    this.showNotifications = !this.showNotifications;
  }
  navigateToAllNotifications(): void {
    this.router.navigate(['/all-notifications']);
  }
  navigateToDeliveryAddress(): void {
    this.router.navigate(['/deliveryAddress']);
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
  saveUsers() {
    if (!this.userDTO || !this.userDTO.phoneNumber) {
      console.error('Phone number is required.'); // Log an error if phone number is empty
      return;
    }
    // this.userDTO.phoneNumber = this.phoneNumber;
    console.log('User DTO:', this.userDTO);
    if (
      typeof this.userDTO.userTypeId === 'object' &&
      'id' in this.userDTO.userTypeId
    ) {
      // Extract the id property and assign it to userTypeId
      this.userDTO.userTypeId = this.userDTO.userTypeId.id;
    }
    this.userService.edit(this.userDTO).subscribe((data) => {
      this.saveSuccess = true;
      this.saveUser = data;
      this.saveSuccess = true;
      // this.isEditMode = false;
    });
  }
  // goBack(): void {
  //   this.location.back();
  // }
  // hideNotifications() {
  //   this.showNotifications = false;
  // }

  cancelEdit() {
    // Reset userDTO to its original state and exit edit mode
    // this.userDTO = JSON.parse(JSON.stringify(this.originalUserDTO));
    this.isEditMode = false;
  }

  signOut() {
    this.authService.logout();
  }
}
