<div class="d-flex align-items-center justify-content-center flex-column">
  <div class="font16 fw600">Ratings & Reviews</div>
  <div class="font48 fw600">
    <span *ngIf="product?.avgRatings?.overallRating"
      >{{ product?.avgRatings.overallRating | number : "1.2" }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="25"
        viewBox="0 0 27 25"
        fill="none"
      >
        <path
          d="M13.5 0L16.5309 9.32827H26.3393L18.4042 15.0935L21.4351 24.4217L13.5 18.6565L5.5649 24.4217L8.59584 15.0935L0.660737 9.32827H10.4691L13.5 0Z"
          fill="#FBB02E"
        />
      </svg>
    </span>
    <span *ngIf="!product?.avgRatings?.overallRating" class="font16"
      >No Ratings Yet.</span
    >
  </div>
  <div class="font16 fw400" *ngIf="product?.overallRating">
    {{ product.avgRatings.totalRating }} Ratings &
    {{ product.avgRatings.totalReview }} Reviews
  </div>
</div>
<div
  class="border-grey d-flex col-12 flex-column p-4 mt-4 justify-content-center align-items-center bg-light-grey-1"
  *ngIf="product.avgRatings?.totalRating > 0"
>
  <div
    class="col-12 d-flex flwx-row align-items-center gap-3 justify-content-center"
  >
    <div class="font14">
      5
      <img src="../../assets/star.svg" alt="" class="img-fluid rating-image" />
    </div>
    <div class="rating-all-bar">
      <div
        class="rating-progress-bar"
        [ngStyle]="{ 'max-width': fiveWidth + '%' }"
      ></div>
    </div>
  </div>
  <div
    class="col-12 d-flex flwx-row align-items-center gap-3 mt-2 justify-content-center"
  >
    <div class="font14">
      4
      <img src="../../assets/star.svg" alt="" class="img-fluid rating-image" />
    </div>
    <div class="rating-all-bar">
      <div
        class="rating-progress-bar"
        [ngStyle]="{ 'max-width': fourWidth + '%' }"
      ></div>
    </div>
  </div>
  <div
    class="col-12 d-flex flwx-row align-items-center gap-3 mt-2 justify-content-center"
  >
    <div class="font14">
      3
      <img src="../../assets/star.svg" alt="" class="img-fluid rating-image" />
    </div>
    <div class="rating-all-bar">
      <div
        class="rating-progress-bar"
        [ngStyle]="{ 'max-width': threeWidth + '%' }"
      ></div>
    </div>
  </div>
  <div
    class="col-12 d-flex flwx-row align-items-center gap-3 mt-2 justify-content-center"
  >
    <div class="font14">
      2
      <img src="../../assets/star.svg" alt="" class="img-fluid rating-image" />
    </div>
    <div class="rating-all-bar">
      <div
        class="rating-progress-bar"
        [ngStyle]="{ 'max-width': twoWidth + '%' }"
      ></div>
    </div>
  </div>
  <div
    class="col-12 d-flex flwx-row align-items-center gap-3 mt-2 justify-content-center"
  >
    <div class="font14">
      1
      <img src="../../assets/star.svg" alt="" class="img-fluid rating-image" />
    </div>
    <div class="rating-all-bar">
      <div
        class="rating-progress-bar"
        [ngStyle]="{ 'max-width': oneWidth + '%' }"
      ></div>
    </div>
  </div>
</div>
