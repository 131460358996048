<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage">
  About Us
</div>
<div class="d-flex col-12 my-2 py-0 my-lg-5 py-lg-5 flex-column flex-lg-row flex-column">
  <div class="col-12 col-lg-6 d-flex justify-content-center flex-column flex-lg-row">
    <img src="../../assets/aboutus.png" class="mx-2 mx-lg-0" alt="AboutUs" />
    <div class="vertical-red-line ms-2 me-2 me-lg-0 ms-lg-5 ps-0 ps-lg-5 mt-2 mt-lg-0"></div>
  </div>
  <div
    class="col-12 col-lg-6 font40 fw400 pe-2 pe-lg-5 mt-3 mt-lg-0 ps-2 ps-lg-0 justify-content-center align-items-center">
    <span> About <span class="font-primary">Power Bihar</span> </span>
    <div class="font16 mt-3 mt-lg-2 lh25">
      <span class="font-primary lh25">Power Bihar</span> was founded with a
      mission to provide high-quality electrical products to the people of South
      Bihar. We understand the importance of reliable and efficient electrical
      solutions, whether for residential, commercial, or industrial purposes.
      Our marketplace is designed to offer a seamless shopping experience,
      combining a vast selection of products with exceptional customer service.
    </div>
    <div class="mt-3 mt-lg-5">
      <div class="d-flex justify-content-between font20 fw400">
        <button class="py-2 px-4 custom-button me-1 me-lg-0" [class.clicked]="showVision" (click)="toggleDiv('vision')">
          Our Vision
          <img src="../../assets/Chevron.png" *ngIf="showVision" class="ps-2" alt="vision" />
          <img src="../../assets/Chevronblack.png" *ngIf="!showVision" class="ps-2" alt="vision" />
        </button>
        <button class="py-2 px-4 custom-button" [class.clicked]="showMission" (click)="toggleDiv('mission')">
          Our Mission
          <img src="../../assets/Chevronblack.png" *ngIf="!showMission" class="ps-2" alt="mission" />
          <img src="../../assets/Chevron.png" *ngIf="showMission" class="ps-2" alt="mission" />
        </button>
      </div>
      <div class="font16 lh25 fw400 mt-4" *ngIf="showVision">
        To become the leading marketplace for electrical items in Bihar, known
        for our quality, affordability, and customer-centric approach.
      </div>
      <div class="font16 lh25 fw400 mt-4" *ngIf="showMission">
        To empower the people of South Bihar with access to top-notch electrical
        products and services, fostering growth and development in the region.
      </div>
    </div>
  </div>
</div>
<div class="aboutusbanner d-flex justify-content-center align-items-center flex-column">
  <div class="text-white font32 fw400 d-flex justify-content-center align-items-center mt-3 mt-lg-5">
    Our Values
  </div>
  <div
    class="orange-bg d-flex mt-4 py-5 col-10 col-lg-8 justify-content-lg-around justify-content-center align-items-center mb-5 pb-5 flex-column flex-lg-row">
    <div class="align-items-center d-flex flex-column">
      <div class="outline-numbers">01</div>
      <p class="text-white font36 fw700 mt-4">Quality</p>
    </div>
    <div class="align-items-center d-flex flex-column justify-content-center my-5 my-lg-0">
      <div class="outline-numbers">02</div>
      <p
        class="text-white font36 fw700 mt-4 d-flex align-items-center justify-content-center mx-5 mx-lg-0 lh40 text-center">
        Customer Satisfaction
      </p>
    </div>
    <div class="align-items-center d-flex flex-column">
      <div class="outline-numbers">03</div>
      <p class="text-white font36 fw700 mt-4">Integrity</p>
    </div>
  </div>
</div>
<div style="min-height: 6vh; background-color: black"></div>
<div class="d-flex flex-column text-white justify-content-center align-items-center col-12">
  <div class="col-12 col-lg-8 bgimage py-5 rounded1 custom-margin d-flex align-items-center justify-content-center">
    <div class="ps-2 pe-2 pe-lg-0 text-center ps-lg-0 font16 fw400 lh25">
      <p>
        Quality: We never compromise on the quality of our products. We sell
        only Branded products on our marketplace.
      </p>
      <p>
        Customer Satisfaction: We prioritize the needs and satisfaction of our
        customers above all.
      </p>
      <p>Integrity: We conduct our business with honesty and transparency.</p>
    </div>
  </div>
</div>
<div
  class="d-flex justify-content-center align-items-center my-0 my-lg-3 py-0 py-lg-5 flex-column gap-2 gap-lg-5 col-12">
  <div class="font32 fw400 lh25 mt-3 mt-lg-0">Meet Our Team</div>
  <div class="font16 fw400 col-12 col-lg-10 px-2 px-lg-0 lh25 mb-3 mb-lg-0">
    Welcome to Power Bihar! We are proud to introduce the dedicated teams that
    make our marketplace for electrical equipment and local electrician services
    the best in South Bihar. Our commitment to excellence and customer
    satisfaction drives everything we do.
  </div>
</div>

<div class="d-flex justify-content-center align-items-center flex-column text-center ">
  <div class="font32 fw400 lh25 mb-5">Leadership Team</div>
  <div class="font16 fw400 col-12 lh25 d-flex flex-column-reverse flex-lg-row justify-content-around ">
    <div class="col-12 col-lg-2 orange-bg rounded5 mx-0 mx-lg-5 flex-column">
      <img src="../../assets/samir.png" class="mt-5 pt-4 ps-5 ms-0 ms-lg-3" />
      <div class="bgimage col-12 d-flex d-lg-none py-4 text-white font20 fw400 justify-content-around"
        style="margin-top: -3px">
        <div class="d-flex col-12 align-items-center justify-content-center ms-0">
          Samir Kumar <br> Chief Technology Officer
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 orange-bg rounded5 mx-0 mx-lg-5 flex-column">
      <img src="../../assets/priya.png" class="mt-4 pt-2 ps-5 ms-0 ms-lg-3" />
      <div class="bgimage col-12 d-flex d-lg-none py-4 text-white font20 fw400 justify-content-around"
        style="margin-top: -3px">
        <div class="d-flex col-12 align-items-center justify-content-center flex-column">
          Priya Ranjan - Founder & CEO<span class="font16 ps-3">priya.ranjan&#64;brain-crowd.com</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 orange-bg rounded5 mx-0 mx-lg-5 flex-column">
      <img src="../../assets/priya.png" class="mt-4 pt-2 ps-5 ms-0 ms-lg-3" />
      <div class="bgimage col-12 d-flex d-lg-none py-4 text-white font20 fw400 justify-content-around"
        style="margin-top: -3px">
        <div class="d-flex col-12 align-items-center justify-content-center">
          Priya Ranjan <br> Chief Operating Officer
        </div>
      </div>
    </div>
  </div>

  <div class="bgimage col-12 d-none d-lg-flex py-4 text-white font20 fw400 justify-content-around"
    style="margin-top: -3px">
    <div class="d-flex col-3 ps-2 ms-5">
      Samir Kumar - Chief Technology Officer
    </div>
    <div class="d-flex col-3 ps-5 ms-5 flex-column">
      Priya Ranjan - Founder & CEO<span class="font16 ps-3">priya.ranjan&#64;brain-crowd.com</span>
    </div>
    <div class="d-flex col-3 ps-5 ms-5">Chief Operating Officer </div>
  </div>
  <!-- <div class="py-5"></div> -->
</div>