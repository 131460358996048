import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

interface Toast {
  message: string;
  type: 'bg-success' | 'bg-danger' | 'bg-warning';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  private toastSubject = new BehaviorSubject<Toast | null>(null);
  toast$ = this.toastSubject.asObservable();

  showToast(message: string, type: 'bg-success' | 'bg-danger' | 'bg-warning') {
    this.toastSubject.next({ message, type });
    setTimeout(() => this.toastSubject.next(null), 3000); // Clear toast after 3 seconds
  }
}
