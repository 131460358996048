<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Seller Listings
</div>

<div class="px-5 py-5">
  <!-- Search Field -->
  <div class="d-flex col-12">
    <div class="col-4 d-flex search-bar mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search by Name or Product ID"
        [(ngModel)]="searchTerm"
        (input)="updateDisplayedProducts()"
      />
    </div>
    <div class="col-8 justify-content-end d-flex mb-3">
      <button class="bgorange" (click)="openModal()">Add New Listing</button>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal-overlay" *ngIf="isModalOpen" (click)="closeModal()">
    <div class="modal-container" (click)="$event.stopPropagation()">
      <div class="modal-header">
        <h5>Add New Listing</h5>
        <button class="close-btn" (click)="closeModal()">×</button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="border-primary_type">Category Name*</label>
            <select
              class="form-control px-3"
              [(ngModel)]="categoryId"
              name="categoryId"
              (ngModelChange)="getAllSubCategory(categoryId)"
              required
              #category="ngModel"
            >
              <option value="" disabled selected>Select a Category</option>
              <option
                *ngFor="let category of allCategoryList"
                [value]="category.id"
              >
                {{ category.categoryName }}
              </option>
            </select>
            <div
              *ngIf="category.invalid && (category.dirty || category.touched)"
              class="text-danger font12 p-2 d-block"
            >
              *Kindly Select Category
            </div>
          </div>

          <div class="form-group">
            <label for="box_type" class="text-cyan">Sub-category Name*</label>
            <select
              class="form-control px-3"
              [(ngModel)]="subcategoryId"
              name="subcategoryId"
              (ngModelChange)="getProductBySubCategory(subcategoryId)"
              required
              #subcategory="ngModel"
            >
              <option
                *ngFor="let subcategory of getSubCategoryList"
                [value]="subcategory.id"
              >
                {{ subcategory.subcategoryName }}
              </option>
            </select>
            <div
              *ngIf="
                subcategory.invalid &&
                (subcategory.dirty || subcategory.touched)
              "
              class="text-danger font12 p-2 d-block"
            >
              *Kindly Select SubCategory
            </div>
          </div>

          <div class="form-group">
            <label for="box_type" class="text-cyan">Product Name*</label>
            <select
              class="form-control px-3"
              [(ngModel)]="productId"
              name="productId"
              required
              #product="ngModel"
            >
              <option
                *ngFor="let product of getProductList"
                [value]="product.productId"
              >
                {{ product.productName }}
              </option>
            </select>
            <div
              *ngIf="product.invalid && (product.dirty || product.touched)"
              class="text-danger font12 p-2 d-block"
            >
              *Kindly Select Product
            </div>
          </div>
          <div class="form-group">
            <label for="mrp">MRP</label>
            <input
              type="number"
              id="mrp"
              [(ngModel)]="mrp"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              required
            />
          </div>
          <div class="form-group">
            <label for="offerPrice">Offer Price</label>
            <input
              type="number"
              id="offerPrice"
              [(ngModel)]="offerPrice"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              required
            />
          </div>
          <div class="form-group">
            <label for="lotSize">Lot Size</label>
            <input
              type="number"
              id="lotSize"
              [(ngModel)]="lotSize"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              required
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
        <button
          class="submit btn btn-primary"
          (click)="submitNewListing()"
          [disabled]="
            mrp === 0 ||
            offerPrice === 0 ||
            lotSize === 0 ||
            !mrp ||
            !offerPrice ||
            !lotSize
          "
        >
          Submit
        </button>
      </div>
    </div>
  </div>

  <div class="listing-header px-lg-5 px-3 col-12 py-3 col-12 d-flex">
    <div class="col-10 d-flex">
      <div class="col-4">Name</div>
      <div class="col-2">MRP</div>
      <div class="col-2">Offer Price</div>
      <div class="col-2">Lot Size</div>
      <div class="col-2">Item Id</div>
    </div>
    <div class="col-2">Action</div>
  </div>

  <div
    *ngFor="let product of displayedProducts; let i = index"
    class="listing px-lg-5 px-3 col-12 py-2 mt-2 col-12 d-flex align-items-center"
    [ngClass]="{ 'bg-light-grey': i % 2 === 0 }"
  >
    <div class="col-10 d-flex align-items-center">
      <div class="col-4 two-line-text">{{ product.productName }}</div>
      <div class="col-2">
        <span *ngIf="!product.isEditing"
          >₹ {{ product.mrp | number : "1.2-2" }}</span
        >
        <input
          *ngIf="product.isEditing"
          type="number"
          [(ngModel)]="product.mrp"
          class="form-control"
        />
      </div>
      <div class="col-2">
        <span *ngIf="!product.isEditing"
          >₹ {{ product.offerPrice | number : "1.2-2" }}</span
        >
        <input
          *ngIf="product.isEditing"
          type="number"
          [(ngModel)]="product.offerPrice"
          class="form-control"
        />
      </div>
      <div class="col-2">
        <span *ngIf="!product.isEditing">{{ product.lotSize }}</span>
        <input
          *ngIf="product.isEditing"
          type="number"
          [(ngModel)]="product.lotSize"
          class="form-control"
        />
      </div>
      <div class="col-2">{{ product.itemId }}</div>
    </div>

    <!-- <div class="col-2 d-flex">
      <div class="col-6">
        <button class="bgorange text-white px-4 py-2">Edit</button>
      </div>
      <div class="col-6">
        <button class="bg-danger text-white px-4 py-2">Delete</button>
      </div>
    </div> -->
    <div class="col-2 d-flex">
      <div class="col-6">
        <!-- Edit Button (visible when not editing) -->
        <button
          *ngIf="!product.isEditing"
          class="bgorange text-white px-4 py-2"
          (click)="startEditing(product)"
        >
          Edit
        </button>

        <!-- Save Button (visible when editing) -->
        <button
          *ngIf="product.isEditing"
          class="bg-success text-white px-4 py-2"
          (click)="saveProduct(product)"
          [disabled]="
            product.mrp === 0 ||
            product.offerPrice === 0 ||
            product.lotSize === 0 ||
            !product.mrp ||
            !product.offerPrice ||
            !product.lotSize
          "
        >
          Save
        </button>
      </div>
      <div class="col-6">
        <!-- Delete Button (always visible when not editing) -->
        <button
          *ngIf="!product.isEditing"
          class="bg-danger text-white px-4 py-2"
          (click)="deleteProduct(product.id)"
        >
          Delete
        </button>

        <!-- Cancel Button (visible when editing) -->
        <button
          *ngIf="product.isEditing"
          class="bg-danger text-white px-4 py-2"
          (click)="cancelEditing(product)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Pagination Controls -->
  <div class="pagination-controls mt-4 text-center">
    <button (click)="previousPage()" [disabled]="currentPage === 1">
      Previous
    </button>
    <span> Page {{ currentPage }} of {{ getTotalPages() }} </span>
    <button (click)="nextPage()" [disabled]="currentPage === getTotalPages()">
      Next
    </button>
  </div>
</div>
