import { Component, OnInit } from '@angular/core';
import { WishlistService } from '../../services/wishlist.service';
import { CommonModule } from '@angular/common';
import { UsersService } from '../../services/user.service';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wishlist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wishlist.component.html',
  styleUrl: './wishlist.component.scss',
})
export class WishlistComponent implements OnInit {
  userDTO: any;
  isSeller: any;
  cart: any;
  constructor(
    private wishlistService: WishlistService,
    private userService: UsersService,
    private cartService: CartService,
    private router: Router
  ) {}

  wishListItems: any[] = [];

  ngOnInit(): void {
    this.getWishListItems();
  }
  getFloor(arg0: any) {
    return Math.floor(arg0);
  }
  goToProductDetails(id: string) {
    // alert(id);
    this.router.navigate([`/product/${id}`]);
  }
  getWishListItems() {
    this.wishlistService.getWishlist().subscribe((data) => {
      this.wishListItems = data;
    });
  }
  removeFromList(product: any): void {
    product.wishlisted = !product.wishlisted;
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.wishlistService
        .addWishlist(product.productDetailDto.productId, this.userDTO.id)
        .subscribe((data) => {
          this.getWishListItems();
        });
    });
  }
  addToCart(product: any, buyNow: boolean) {
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;

      if (!this.hasCompleteUserDetails()) {
        this.showProfileIncompleteModal();
        return;
      }

      const addProductToCartDto = {
        price: product.finalAmtWithTax,
        product_color_id: '1',
        product_id: product.productId,
        quantity: '1',
        sellerId: product.sellerId,
        tax: product.taxAmount,
        mrp: product.mrp,
      };

      if (product.isAvailableAtShippingAddress) {
        this.cartService.addCart(addProductToCartDto).subscribe(
          (data) => {
            this.cart = data;
            if (!buyNow) {
              alert('Product added to cart successfully.');
            }
            this.updateCartItemCount();
            if (buyNow) {
              this.router.navigate(['/cart']);
            }
          },
          (error) => {
            console.error('Error adding product to cart:', error);
          }
        );
      } else {
        alert('Item not available at Default Shipping Address');
      }
    });
  }
  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      // Calculate total items count in the cart
      const totalCount = cartList.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      // Update cart item count in the service
      this.cartService.updateCartItemCount(totalCount);
    });
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.userDTO;
    return firstName && lastName && phoneNumber && emailAddress;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }
  buyNow(product: any) {
    this.addToCart(product, true);
    // this.router.navigate(['/cart']);
  }
}
