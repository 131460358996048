import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent implements OnInit, OnDestroy {
  items = [
    {
      src: 'assets/carousel-1.png',
      alt: 'Buy Electrical Items Online in Bihar',
      link: '/aboutus',
    },
    {
      src: 'assets/carousel-2.png',
      alt: 'Buy Havells Products in Bihar',
      link: '/category/Fans',
    },
    {
      src: 'assets/carousel-3.png',
      alt: 'Book Electrician in Bihar',
      link: '/electricianSearch?latitude=25.5940947&longitude=85.1375645',
    },
    {
      src: 'assets/carousel-4.png',
      alt: 'Bulk Order Electrical Items REO Havells LLoyd in Bihar',
      link: '/category/Wires',
    },
    {
      src: 'assets/carousel-5.png',
      alt: 'Buy Bulbs Lights in Bihar',
      link: '/electricianSearch',
    },
    {
      src: 'assets/carousel-6.png',
      alt: 'Buy Bulk Electricals in Bihar',
      link: '/contactus',
    },
    {
      src: 'assets/carousel-7.png',
      alt: 'Buy Wires Cables in Bihar',
      link: '/category/lighting',
    },
  ];
  public currentIndex = 0;
  public transform = 'translateX(0)';
  private autoplaySubscription!: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.startAutoplay();
    }
  }

  ngOnDestroy() {
    if (this.autoplaySubscription) {
      this.autoplaySubscription.unsubscribe();
    }
  }

  prevSlide() {
    this.currentIndex =
      this.currentIndex === 0 ? this.items.length - 1 : this.currentIndex - 1;
    this.updateTransform();
  }

  nextSlide() {
    this.currentIndex =
      this.currentIndex === this.items.length - 1 ? 0 : this.currentIndex + 1;
    this.updateTransform();
  }

  goToSlide(index: number) {
    this.currentIndex = index;
    this.updateTransform();
  }

  private updateTransform() {
    this.transform = `translateX(-${this.currentIndex * 100}%)`;
  }

  private startAutoplay() {
    this.autoplaySubscription = interval(5000).subscribe(() => {
      this.nextSlide();
    });
  }
}
