import { Component } from '@angular/core';
import { CarouselComponent } from '../carousel/carousel.component';
import { ProductCardComponent } from '../product-card/product-card.component';
import { CommonModule } from '@angular/common';
import { ProductService } from '../../services/product.service';
import { ElectricianHomePageComponent } from '../electrician-home-page/electrician-home-page.component';
import { WhychooseComponent } from '../whychoose/whychoose.component';
import { SaleCardHomeComponent } from '../sale-card-home/sale-card-home.component';
import { FeaturedProductsCardComponent } from '../featured-products-card/featured-products-card.component';
import { NewArrivalsComponent } from '../new-arrivals/new-arrivals.component';
import { OurBrandsCardComponent } from '../our-brands-card/our-brands-card.component';
import { BestsellerComponent } from '../bestseller/bestseller.component';
import { HomepageoffersComponent } from '../homepageoffers/homepageoffers.component';
import { SeasonaloffersComponent } from '../seasonaloffers/seasonaloffers.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CarouselComponent,
    ProductCardComponent,
    CommonModule,
    ElectricianHomePageComponent,
    WhychooseComponent,
    SaleCardHomeComponent,
    FeaturedProductsCardComponent,
    NewArrivalsComponent,
    OurBrandsCardComponent,
    BestsellerComponent,
    HomepageoffersComponent,
    SeasonaloffersComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  catList: any;
  displayedCategories: any[] = [];
  currentIndex: number = 0;
  itemsPerPage: number = 3;

  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    // this.getAllCategory();
  }
}
