import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule, ViewportScroller } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { CategoryCarouselComponent } from './category-carousel/category-carousel.component';
import { WhatsappChatComponent } from './whatsapp-chat/whatsapp-chat.component';
import { ToastComponent } from './shared/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    CategoryCarouselComponent,
    WhatsappChatComponent,
    ToastComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'Power Bihar: South Bihar Electrical Marketplace';
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.urlAfterRedirects === '/' ||
          event.urlAfterRedirects === '/home'
        ) {
          // Scroll 20% on route change
        } else if (event.urlAfterRedirects === '/signin') {
          this.scrollPageByPercentage(25); // Scroll 20% on route change
        } else if (event.urlAfterRedirects === '/contactus') {
          this.scrollPageByPercentage(17); // Scroll 20% on route change
        } else {
          this.scrollPageByPercentage(20);
        }
      }
    });
  }

  scrollPageByPercentage(percentage: number) {
    const viewportHeight = window.innerHeight;
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight
    );

    // Calculate the scroll amount
    const scrollAmount = (scrollHeight - viewportHeight) * (percentage / 100);

    // Scroll the page by the calculated amount
    this.viewportScroller.scrollToPosition([0, percentage * 20]);
  }
}
