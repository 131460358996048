<a
  class="position-float d-flex justify-content-center align-items-center bg-hover-grey mx-5 p-2 gap-4 rounded-2"
  (mouseenter)="showDesc = true"
  (mouseleave)="showDesc = false"
  href="http://wa.me/9035814717"
  target="_blank"
  style="text-decoration: none"
>
  <div class="col-2">
    <img
      src="../../assets/wa.png"
      alt="Chat with us on WhatsApp"
      class="img-fluid object-fit"
    />
  </div>
  <div class="col-8 text-white fw600 font16" *ngIf="showDesc">
    Click to Chat
  </div>
</a>
