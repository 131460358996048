import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-order-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-confirmation.component.html',
  styleUrl: './order-confirmation.component.scss',
})
export class OrderConfirmationComponent implements OnInit {
  showInfo = true;
  orderData: any;
  orderIdentifier: string = '';
  identifier: string = '';
  orderTrackerData: any;
  orderDetails: any[] = [];
  finalPrice: number = 0;
  totalQuantity: number = 0;
  // average!: number;
  // size: 'lg' | 'sm' | undefined;
  roundedAverage: number = 0;
  today: Date = new Date();
  todayDate: any;
  deliveryDate: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private orderService: OrderService // private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // this.today.setDate(new Date());
    this.todayDate = this.today.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    this.route.queryParams.subscribe((params) => {
      if (params['orderIdentifier']) {
        this.orderIdentifier = params['orderIdentifier'];
        this.getOrderConfirmation(this.orderIdentifier);
      }
    });
  }

  dismiss() {
    this.showInfo = false;
  }

  getOrderConfirmation(orderIdentifier: string) {
    this.orderService.getOrderConfirmation(orderIdentifier).subscribe(
      (data) => {
        this.orderData = data;
        this.orderDetails = Array.isArray(data.orderConfrmationDto)
          ? data.orderConfrmationDto
          : [];
        this.finalPrice = data.finalPrice;
        this.totalQuantity = data.totalQuantity;
        this.roundedAverage = this.roundOffAverage();
        this.cartService
          .getDeliveryTime(this.orderDetails[0].address.pinCode)
          .subscribe((data) => {
            // console.log(data);
            let today = new Date();
            const deliveryDate = new Date(today);
            deliveryDate.setDate(deliveryDate.getDate() + data);
            this.deliveryDate = deliveryDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            });
          });
        // this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error fetching order confirmation:', error);
      }
    );
  }
  navigateToProductDetails(product_id: string) {
    this.router.navigate(['/productdetails', product_id]);
  }
  navigateToOrderDetails(orderIdentifier: string, identifier: string) {
    this.router.navigate(['/buyer/order/details', orderIdentifier]);
  }

  navigateToOrderListing() {
    this.router.navigate(['/buyer/order/listing']);
  }

  roundOffAverage() {
    // console.log('roundOffAverage called');
    // console.log('Order Data:', this.orderData);
    if (
      this.orderData &&
      this.orderData.orderConfrmationDto &&
      this.orderData.orderConfrmationDto.length > 0
    ) {
      const productDetail = this.orderData.orderConfrmationDto[0].productDetail;
      // console.log('Product Detail:', productDetail);
      if (
        productDetail &&
        productDetail.ratings &&
        productDetail.ratings.overallRating !== undefined
      ) {
        const roundedValue =
          Math.round(productDetail.ratings.overallRating * 2) / 2;
        // console.log('Rounded Value: ', roundedValue);
        return roundedValue;
      }
    }
    // console.log('Ratings data is unavailable');
    return 0; // Default value if ratings data is unavailable
  }

  cancelOrderItem(orderIdentifier: string) {
    if (confirm('Are you sure to cancel the order?')) {
      this.orderService.cancelOrder(orderIdentifier).subscribe(
        (response) => {
          console.log('Order cancelled successfully:', response);
          // Optionally, update UI or inform user of successful cancellation
          alert('Your order has been cancelled.');
          this.getOrderConfirmation(this.orderIdentifier); // Refresh the order tracker after cancellation
        },
        (error) => {
          console.error('Error cancelling order item:', error);
          alert('There was an error canceling the order. Please try again.');
        }
      );
    }
  }
}
