<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center mt-5 bgimage"
>
  Delivery Address
</div>
<div class="px-5 mx-5 py-5 mt-5">
  <div class="d-flex">
    <div class="col-6 px-5">
      <div>
        <div
          class="address-cards px-5 d-flex col-12"
          *ngFor="let adr of addressDTO"
          [ngClass]="{
            'orange-bg': adr.isDefault,
            'billing-bg': adr.isBilling
          }"
        >
          <div class="col-8">
            <div class="font20 lh30">
              <p class="mb-0">{{ adr.firstName }} {{ adr.lastName }}</p>
              <p class="mb-0">{{ adr.firstLine }}, {{ adr.secondLine }},</p>
              <p class="mb-0">{{ adr.city }}, {{ adr.state }}</p>
              <p class="mb-0">{{ adr.country }} - {{ adr.pinCode }}</p>
              <p class="mb-0">+91 {{ adr.phoneNumber }}</p>
              <p class="mb-3">{{ adr.email }}</p>
            </div>

            <div class="d-flex">
              <!-- Case 1: Default Shipping Address (show only Edit Address and Set as Billing buttons) -->
              <ng-container *ngIf="adr.isDefault && !adr.isBilling">
                <button
                  class="action bgyellow font16 fw-800 px-3 py-2"
                  (click)="editAddress(adr.id)"
                >
                  Edit Address
                </button>
                <button
                  class="action bgyellow font16 fw-800 px-3 py-2 ms-2"
                  (click)="setAsBillingAddress(adr.id)"
                >
                  Set as Billing
                </button>
              </ng-container>
              <!-- Case 2: Default Billing Address (show only Edit Address button) -->
              <ng-container *ngIf="adr.isBilling && !adr.isDefault">
                <button
                  class="action bgyellow font16 fw-800 px-3 py-2"
                  (click)="editAddress(adr.id)"
                >
                  Edit Address
                </button>
              </ng-container>
              <!-- Case 3: Other Address (show Edit Address, Set as Shipping, and Set as Billing buttons) -->
              <ng-container *ngIf="!adr.isDefault && !adr.isBilling">
                <button
                  class="action bgyellow font16 fw-800 px-3 py-2"
                  (click)="editAddress(adr.id)"
                >
                  Edit Address
                </button>
                <button
                  class="action bgyellow font16 fw-800 px-3 py-2 ms-2"
                  (click)="setAsBillingAddress(adr.id)"
                >
                  Set as Billing
                </button>
                <button
                  class="action bgyellow font16 fw-800 px-3 py-2 ms-2"
                  (click)="markAddressDefault(adr.id)"
                >
                  Set As Shipping
                </button>
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="adr.isDefault || adr.isBilling"
            class="text-white my-2 justify-content-end gap-2 col-4 font14 text-end"
          >
            <span
              class="bg-grey p-2 rounded-2"
              *ngIf="adr.isDefault && adr.isBilling"
            >
              Default Shipping & Billing Address
            </span>
            <span
              class="bg-grey p-2 rounded-2"
              *ngIf="adr.isDefault && !adr.isBilling"
              >Default Shipping Address</span
            >
            <span
              class="bg-grey p-2 mb-1 rounded-2"
              *ngIf="!adr.isDefault && adr.isBilling"
              >Default Biling Address</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-5" *ngIf="!isEditing">
      <app-add-address></app-add-address>
    </div>
    <div class="col-6 px-5" *ngIf="isEditing">
      <div class="">
        <span class="font-black font24 fw400">Edit New Address</span>
        <div class="mt-2 col-11 pe-4">
          <button
            (click)="showMap = !showMap"
            *ngIf="!showMap"
            class="location py-3 col-6 font20 fw400"
          >
            <img
              src="../../assets/locationicon.png"
              alt=""
              style="width: 22px; height: 22px"
              class="me-3"
            />
            Locate on Map
          </button>
          <app-map
            (locationSelected)="onLocationSelected($event)"
            [latitude]="parseInteger(addressDTO!.latitude)"
            [longitude]="parseInteger(addressDTO!.longitude)"
            *ngIf="showMap"
            class="col-12"
          ></app-map>
          <button
            class="location py-3 col-6 mt-2 mt-md-2"
            (click)="showMap = !showMap"
            *ngIf="showMap"
          >
            Hide Map
          </button>
        </div>
        <div class="form-group d-flex mt-4 gap-5">
          <div class="col-5">
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              class="form-control custom-input py-3"
              [(ngModel)]="selectedAddress.firstName"
              required
              #firstName="ngModel"
            />
            <div
              *ngIf="
                firstName.invalid && (firstName.dirty || firstName.touched)
              "
              class="text-danger font12"
            >
              *Kindly Provide First Name
            </div>
          </div>
          <div class="col-5">
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              class="form-control custom-input py-3"
              [(ngModel)]="selectedAddress.lastName"
              required
              #lastName="ngModel"
            />
            <div
              *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
              class="text-danger font12"
            >
              *Kindly Provide Last Name
            </div>
          </div>
        </div>
        <div class="form-group d-flex mt-4 gap-5">
          <div class="col-5">
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number"
              class="form-control custom-input py-3"
              [(ngModel)]="selectedAddress.phoneNumber"
              required
              #phoneNumber="ngModel"
            />
            <div
              *ngIf="
                phoneNumber.invalid &&
                (phoneNumber.dirty || phoneNumber.touched)
              "
              class="text-danger font12"
            >
              *Kindly Provide Phone Number
            </div>
          </div>
          <div class="col-5">
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email Address"
              class="form-control custom-input py-3"
              [(ngModel)]="selectedAddress.email"
              required
              #email="ngModel"
            />
            <div
              *ngIf="email.invalid && (email.dirty || email.touched)"
              class="text-danger font12"
            >
              *Kindly Provide Email Address
            </div>
          </div>
        </div>

        <div class="form-group mt-5 d-flex gap-5">
          <div class="col-5">
            <input
              type="text"
              id="firstLine"
              name="firstLine"
              placeholder="First Line"
              class="form-control custom-input py-3"
              [(ngModel)]="selectedAddress.firstLine"
              required
              #firstLine="ngModel"
            />
            <div
              *ngIf="
                firstLine.invalid && (firstLine.dirty || firstLine.touched)
              "
              class="text-danger font12"
            >
              *Kindly Provide First Line
            </div>
          </div>
          <div class="col-5">
            <input
              type="text"
              id="secondLine"
              name="secondLine"
              placeholder="First Line"
              class="form-control custom-input py-3"
              [(ngModel)]="selectedAddress.secondLine"
              required
              #secondLine="ngModel"
            />
            <div
              *ngIf="
                secondLine.invalid && (secondLine.dirty || secondLine.touched)
              "
              class="text-danger font12"
            >
              *Kindly Provide Second Line
            </div>
          </div>
        </div>

        <div class="form-group d-flex mt-5 gap-5">
          <div class="col-5">
            <input
              type="text"
              id="city"
              name="city"
              placeholder="City"
              [(ngModel)]="selectedAddress.city"
              class="form-control custom-input py-3"
              required
              #city="ngModel"
            />
            <div
              *ngIf="city.invalid && (city.dirty || city.touched)"
              class="text-danger font12"
            >
              *Kindly Provide City
            </div>
          </div>
          <div class="col-5">
            <input
              type="text"
              id="country"
              name="country"
              placeholder="Country"
              [(ngModel)]="selectedAddress.country"
              class="form-control custom-input py-3"
              required
              #country="ngModel"
            />
            <div
              *ngIf="country.invalid && (country.dirty || country.touched)"
              class="text-danger font12"
            >
              *Kindly Provide Country
            </div>
          </div>
        </div>

        <div class="form-group d-flex mt-5 gap-5">
          <div class="col-5">
            <input
              type="text"
              id="state"
              name="state"
              placeholder="State"
              [(ngModel)]="selectedAddress.state"
              class="form-control custom-input py-3"
              required
              #state="ngModel"
            />
            <div
              *ngIf="state.invalid && (state.dirty || state.touched)"
              class="text-danger font12"
            >
              *Kindly Provide State
            </div>
          </div>

          <div class="col-5">
            <input
              type="text"
              id="pinCode"
              name="pinCode"
              placeholder="Post Zip Code"
              [(ngModel)]="selectedAddress.pinCode"
              class="form-control custom-input py-3"
              required
              #pinCode="ngModel"
            />
            <div
              *ngIf="pinCode.invalid && (pinCode.dirty || pinCode.touched)"
              class="text-danger font12"
            >
              *Kindly Provide Post Zip Code
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="d-flex justify-content-between col-11 pe-4">
            <div>
              <button
                type="submit"
                class="btn-save rounded4 bgorange px-5 py-2"
                (click)="saveEditedAddress()"
              >
                SAVE
              </button>
              <button
                type="submit"
                class="btn-save rounded4 bgyellow px-5 py-2 ms-2"
                (click)="cancelEdit()"
              >
                Cancel
              </button>
            </div>

            <div *ngIf="selectedAddress">
              <button
                class="btn-save rounded4 bgyellow px-5 py-2"
                (click)="deleteAddress(selectedAddress.id)"
                *ngIf="!selectedAddress.isDefault && !selectedAddress.isBilling"
              >
                Delete Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
