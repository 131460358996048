/// <reference types="@types/googlemaps" />
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss',
})
export class MapComponent implements AfterViewInit {
  @ViewChild('mapContainer', { static: false }) mapContainer:
    | ElementRef
    | undefined;
  @ViewChild('mapSearchField') searchField: ElementRef | undefined;

  @Output() locationSelected = new EventEmitter<{ lat: number; lng: number }>();
  @Input() coordinates: Array<{ lat: number; lng: number }> = [];
  @Input() showSearch: boolean = true;
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() zoom: number = 10;

  map: google.maps.Map | undefined;
  marker: google.maps.Marker | undefined;
  markers: google.maps.Marker[] = [];
  showPopup: boolean = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platformId)) {
      if (changes['latitude'] || changes['longitude']) {
        this.updateMapCenter();
      }

      if (changes['coordinates'] && this.coordinates.length) {
        this.updateMarkers();
      }
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.initMap();
      this.initAutocomplete();
    }
  }

  // Initialize the map with default center and zoom
  private initMap() {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    this.map = new google.maps.Map(
      this.mapContainer!.nativeElement,
      mapOptions
    );
    this.addMarker({ lat: this.latitude, lng: this.longitude });
    this.updateMarkers();
  }

  // Initialize search box autocomplete feature
  private initAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchField!.nativeElement
    );
    this.map!.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField!.nativeElement
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) return;

      const location = place.geometry.location;
      this.map!.fitBounds(
        place.geometry.viewport ||
          new google.maps.LatLngBounds(location, location)
      );
      this.emitLocation(location.lat(), location.lng());
      this.addMarker(location);
    });
  }

  // Update map center and marker when latitude/longitude changes
  private updateMapCenter() {
    const newLocation = new google.maps.LatLng(this.latitude, this.longitude);
    this.map!.setCenter(newLocation);
    this.addMarker(newLocation);
  }

  // Emit selected location coordinates
  private emitLocation(lat: number, lng: number) {
    this.locationSelected.emit({ lat, lng });
  }

  // Add a draggable marker to the map and emit its location on drag end
  private addMarker(
    location: google.maps.LatLng | { lat: number; lng: number }
  ) {
    if (this.marker) this.marker.setMap(null);

    this.marker = new google.maps.Marker({
      position: location,
      map: this.map,
      draggable: true,
    });

    this.marker.addListener('dragend', () => {
      const pos = this.marker!.getPosition()!;
      this.emitLocation(pos.lat(), pos.lng());
    });
  }

  // Add multiple markers based on coordinates array
  private updateMarkers() {
    this.clearMarkers();
    this.coordinates.forEach((coord) => {
      const location = new google.maps.LatLng(coord.lat, coord.lng);
      this.addAdditionalMarker(location);
    });
    this.fitBounds();
  }

  // Add a non-draggable marker for each coordinate
  private addAdditionalMarker(location: google.maps.LatLng) {
    const marker = new google.maps.Marker({
      position: location,
      map: this.map,
      draggable: false,
    });
    this.markers.push(marker);
  }

  // Clear all existing markers from the map
  private clearMarkers() {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
  }

  // Adjust the map bounds to include all markers
  private fitBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => bounds.extend(marker.getPosition()!));
    this.map!.fitBounds(bounds);
  }
}
