<div class="px-lg-5 px-1 mx-lg-5 mx-1">
  <button class="buyNowButton p-3 ms-4 mt-5" (click)="goToOrderList()">
    Back to Order List
  </button>
  <div class="d-flex px-5 flex-column flex-lg-row">
    <div class="col-lg-2 col-12">
      <img
        *ngIf="orderTrackerData.productImage?.length > 0"
        [src]="orderTrackerData.productImage[0].imagePublicUrl"
        alt="{{ orderTrackerData.productImage[0].imageName }}"
        class="product-image img-fluid pe-5 pt-3"
        width=""
      />
      <div class="two-line-text">
        {{ orderTrackerData.product.product.productHeading }}
      </div>
    </div>
    <!-- <div class="d-flex"> -->
    <div class="col-lg-10 col-12 d-flex align-items-center">
      <div class="col-12">
        <div class="d-flex align-items-center font24 fw400">
          Arriving
          {{ orderTrackerData.arrival_date | date : " d MMMM " }} between 8AM -
          5PM
        </div>
        <div class="flex-grow-1">
          <hr class="me-4 custom-hr" />
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
  <div class="d-flex">
    <div class="col-12 px-lg-5 px-3 py-5">
      <div
        *ngIf="
          isCancelled(orderTrackerData.orderItemStatusHistory);
          else showStepper
        "
      >
        <div class="cancelled-header text-center">
          <h2>Order Cancelled</h2>
          <img src="../../assets/cancel.png" alt="Cancelled" />
        </div>
      </div>

      <ng-template #showStepper>
        <div class="order-tracker font13">
          <div
            class="step"
            *ngFor="let step of statusSteps; let last = last"
            [ngClass]="{ completed: step.completed, last: last }"
          >
            <div class="circle">
              <span *ngIf="step.completed">&#10003;</span>
            </div>
            <p>{{ step.name }}</p>
            <div *ngIf="step.completed && !last" class="line"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="px-lg-5 px-1 mb-5 pb-5">
    <div class="bg-light-grey rounded18">
      <div class="d-flex px-lg-5 px-2 py-5 flex-lg-row flex-column">
        <div
          class="col-lg-5 col-12 px-lg-5 px-1 mx-lg-5 mx-1 font16 fw400 font-black mt-4 mt-lg-0"
        >
          Shipping address
          <div class="bgwhite rounded12 px-4 py-4 mt-4 font16 fw400">
            {{ orderTrackerData.shippingAddress.firstName }} &nbsp;
            {{ orderTrackerData.shippingAddress.lastName }} <br />
            {{ orderTrackerData.shippingAddress.firstLine }},
            {{ orderTrackerData.shippingAddress.secondLine }} <br />
            {{ orderTrackerData.shippingAddress.city }},
            {{ orderTrackerData.shippingAddress.state }},
            {{ orderTrackerData.shippingAddress.pinCode }},
            {{ orderTrackerData.shippingAddress.country }}
            <p>Phone Number : {{ orderTrackerData.phone }}</p>

            <span *ngIf="!isCancelled(orderTrackerData.orderItemStatusHistory)"
              >Your Item Will Be
              <p class="font-primary font20 fw400 mb-0">
                Delivered On &nbsp;
                <span class="font-black"
                  >{{ orderTrackerData.arrival_date | date : " d-MM-YYYY " }}
                </span>
              </p>
            </span>
          </div>
        </div>
        <div
          class="col-lg-5 col-12 px-lg-5 px-1 mx-lg-5 mx-1 font16 fw400 font-black"
        >
          Order info
          <div class="bgwhite rounded12 px-4 py-lg-5 py-2 mt-4">
            <div class="font-primary my-3">
              <span
                role="button"
                class="font16 fw400"
                (click)="toggleOrderDetails()"
              >
                View Order Details
                <img src="../../assets/right-triangle.png" alt="" />
              </span>
            </div>
            <div class="font-primary mt-3 my-3">
              <span
                *ngIf="
                  !isOutForDelivery(orderTrackerData.orderItemStatusHistory) &&
                    !isDelivered(orderTrackerData.orderItemStatusHistory) &&
                    !isCancelled(orderTrackerData.orderItemStatusHistory) &&
                    !isClosed(orderTrackerData.orderItemStatusHistory);
                  else returnOrder
                "
                role="button"
                class="font16 fw400"
                (click)="cancelOrderItem(identifier)"
              >
                Cancel Order
                <img src="../../assets/right-triangle.png" alt="" />
              </span>
              <ng-template #returnOrder>
                <span
                  *ngIf="
                    !isCancelled(orderTrackerData.orderItemStatusHistory) &&
                    !isOutForDelivery(
                      orderTrackerData.orderItemStatusHistory
                    ) &&
                    !isClosed(orderTrackerData.orderItemStatusHistory)
                  "
                  role="button"
                  class="font16 fw400"
                  (click)="returnOrderItem(orderTrackerData.orderIdentifier)"
                >
                  Return Order
                  <img src="../../assets/right-triangle.png" alt="" />
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showOrderDetails">
    <div class="px-lg-5 px-1 mb-5">
      <div
        class="bg-light-grey rounded18 px-lg-5 px-1 py-5 mt-4 font18 fw400 d-flex flex-lg-row flex-column justify-content-start gap-5 align-items-center"
      >
        <div class="d-flex flex-wrap align-items-center">
          <img
            [src]="orderTrackerData.productImage[0].imagePublicUrl"
            [alt]="orderTrackerData.productImage[0].imageName"
            class="product-image"
            width="150"
          />
        </div>
        <div>
          <div class="mb-0">
            <span class="font-primary two-line-text">{{
              orderTrackerData.product.product.productHeading
            }}</span>
            <p class="font-strike font12">
              MRP: ₹
              {{
                orderTrackerData.product.productPricing.originalPrice
                  | number : "1.2-2"
              }}
            </p>
          </div>
          <div class="font14">
            Unit Price: ₹
            {{
              (orderTrackerData.orderItem.price -
                orderTrackerData.orderItem.totalGst) /
                orderTrackerData.orderItem.quantity | number : "1.2-2"
            }}
          </div>
          <!-- <p>{{ orderTrackerData.product.product.productDescription }}</p> -->
          <div class="font14">
            Quantity: {{ orderTrackerData.orderItem.quantity }}
          </div>

          <div class="font14">
            Total Price (Without Tax): ₹
            {{
              orderTrackerData.orderItem.price -
                orderTrackerData.orderItem.totalGst | number : "1.2-2"
            }}
          </div>
          <div class="font14">
            GST : ₹
            {{ orderTrackerData.orderItem.totalGst | number : "1.2-2" }}
          </div>
          <div class="font14">Delivery Fee: ₹ 0</div>
          <div class="font14">
            Final Amount: ₹
            {{ orderTrackerData.orderItem.price | number : "1.2-2" }}
          </div>
        </div>
        <!-- <p>HSN CODE: {{ orderTrackerData.product.hsnCode }}</p> -->
      </div>
    </div>
  </div>
</div>
