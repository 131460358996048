<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage">
  FAQ
</div>

<div class="d-flex col-12 justify-content-center align-items-center mt-5 mt-lg-5 mb-0 mb-lg-4 py-3 py-lg-5 flex-column">
  <div class="font32 fw400">FAQ</div>
  <div class="font16 fw400">Frequently Asked Questions</div>
</div>
<div class="d-flex col-11 col-lg-10 justify-content-around flex-column gap-4 ms-2 ms-lg-5 py-5 ps-2 ps-lg-5">
  <div class="font-primary font20 fw400 lh25">
    <img src="../../assets/Question.png" alt="Question" class="pe-3" />What
    payment methods do you accept?
  </div>
  <div class="font20 fw400 lh25 mb-4">
    <img src="../../assets/More.png" alt="Question" class="pe-3" />We accept all
    major credit and debit cards, net banking, UPI, and popular mobile wallets.
  </div>
  <div class="font-primary font20 fw400 lh25">
    <img src="../../assets/Question.png" alt="Question" class="pe-3" />Do you
    offer cash on delivery?
  </div>
  <div class="font20 fw400 lh25 mb-4">
    <img src="../../assets/More.png" alt="Question" class="pe-3" />Yes, we offer
    cash on delivery for select locations in South Bihar.
  </div>
  <div class="font-primary font20 fw400 lh25">
    <img src="../../assets/Question.png" alt="Question" class="pe-3" />How long
    does delivery take?
  </div>
  <div class="font20 fw400 lh25 mb-4">
    <img src="../../assets/More.png" alt="Question" class="pe-3" />Delivery
    typically takes 3-5 business days, depending on your location.
  </div>
  <div class="font-primary font20 fw400 lh25">
    <img src="../../assets/Question.png" alt="Question" class="pe-3" />What is
    your return policy?
  </div>
  <div class="font20 fw400 lh25 mb-4">
    <img src="../../assets/More.png" alt="Question" class="pe-3" />We offer a
    30-day return policy on most products. Please refer to our return policy
    page for more details.
  </div>
  <div class="font-primary font20 fw400 lh25">
    <img src="../../assets/Question.png" alt="Question" class="pe-3" />How can I
    track my order?
  </div>
  <div class="font20 fw400 lh25 mb-4">
    <img src="../../assets/More.png" alt="Question" class="pe-3" />You can track
    your order using the tracking number provided in your order confirmation
    email.
  </div>
</div>
<div class="d-flex col-12 justify-content-center align-items-center mb-5 py-2 flex-column font-primary">
  <div class="font20 fw400 mb-4">Need More Help?</div>
  <div class="font20 fw400 px-3 px-lg-0">Contact our customer support team at support&#64;powerbihar.com or call</div>
</div>