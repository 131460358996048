export const environment = {
  production: false,
  API_URL: 'https://api-dev.powerbihar.com',
  // API_URL: 'http://localhost:8080',
  // API_URL: 'https://api.powerbihar.com',
  calendlyUrl:
    'https://calendly.com/contact-brain-crowd/bookelectrician?preview_source=et_card',
  googleAnalyticsId: '', // Google Analytics ID for Dev
  googleTagManagerId: '', // GTM ID for Dev
};
