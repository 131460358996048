import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  constructor(private productService: ProductService, private router: Router) {}

  featuredProducts: any[] = [];

  ngOnInit() {
    this.productService.getFaeturedCategory().subscribe((data) => {
      this.featuredProducts = data;
      //Show Only 5 Featured Category in Footer
      const maxNum = Math.min(5, data.length);
      this.featuredProducts = this.featuredProducts.slice(0, maxNum);
    });
  }

  goToCategory(name: any) {
    this.router.navigate(['/category', name]);
  }
}
