import { Component } from '@angular/core';
// import { ProductCardComponent } from "../product-card/product-card.component";
import { CountdownComponent } from "../countdown/countdown.component";
import { ProductCardSaleComponent } from "../product-card-sale/product-card-sale.component";

@Component({
  selector: 'app-sale-card-home',
  standalone: true,
  imports: [CountdownComponent, ProductCardSaleComponent],
  templateUrl: './sale-card-home.component.html',
  styleUrl: './sale-card-home.component.scss'
})
export class SaleCardHomeComponent {

}
