import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth-service.service';
// import { AuthService } from '../../services/auth-service.service';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss',
})
export class SigninComponent {
  otpRequested: boolean = false;
  userPhoneNumber: any;
  otp: any;
  adminPhoneNumber: any;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private authService: AuthService
  ) {}
  onInputChange() {
    const mobileInput = document.getElementById(
      'mobilenumber'
    ) as HTMLInputElement;
    const submitBtn = document.getElementById('submitBtn') as HTMLButtonElement;

    // Enable button if 10-digit mobile number is entered
    if (/^\d{10}$/.test(mobileInput.value)) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }
  onOtpInputChange(event: any) {
    const input = event.target as HTMLInputElement;
    const submitBtn = document.getElementById('submitBtn') as HTMLButtonElement;

    // Remove any non-digit characters
    input.value = input.value.replace(/\D/g, '');

    // Limit the input length to 6 digits
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
    }

    // Enable button if 6-digit OTP is entered
    if (/^\d{6}$/.test(input.value)) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }
  getOTP() {
    // console.log('OTP Requested');
    this.otpRequested = true;
    this.loginService.generateOTP(this.userPhoneNumber).subscribe((data) => {});
  }
  validateOtp() {
    if (!this.otp) {
      console.error('Please enter the OTP.');
      return;
    }

    if (this.otp.length !== 6) {
      console.error('OTP must be exactly 6 digits.');
      return;
    }

    // Log the values to ensure they are populated correctly
    // console.log('User Phone Number:', this.userPhoneNumber);
    // console.log('OTP:', this.otp);

    this.loginService
      .validateOTP(this.userPhoneNumber, this.adminPhoneNumber, this.otp)
      .subscribe(
        (response) => {
          // console.log('OTP validation response:', response);
          if (response && response.success) {
            // console.log('OTP validation successful');
            this.authService.setToken(response.token);
            // localStorage.setItem('token', response.token); // Assuming the token is returned in the response
            // Handle successful validation here
            this.router.navigate(['/']);
          } else {
            console.error('OTP validation failed:', response.message);
            // Handle failed validation here
            alert(response.message);
          }
        },
        (error) => {
          console.error('Error validating OTP:', error);
          // Handle errors here
        }
      );
  }
}
