// import { UserAddressDTO } from './UserAddressDTO';

import { UserAddressDTO } from './UserAddressDTO';
import { UserTypeDTO } from './UserTypeDTO';

export class UserDTO {
  //   roleId: string = '';
  // userId: string = '';
  id: string = '';
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  gstNumber: string = '';
  // userTypeId: string = '';
  userTypeId: UserTypeDTO | string = '';
  emailAddress: string = '';
  whatsappNo: string = '';
  // address: any;
  // addressId: string = '';
  address: UserAddressDTO = new UserAddressDTO();
}
