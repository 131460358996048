import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-buyer-order-listing',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './buyer-order-listing.component.html',
  styleUrl: './buyer-order-listing.component.scss',
})
export class BuyerOrderListingComponent implements OnInit {
  allBuyerOrder: any;
  orderItemDetails: any;
  identifier: string = '';
  loading: boolean = true;
  count: number = 0;
  roundedAverage: number = 0;
  currentStatus: 'live' | 'cancelled' | 'closed' = 'live';
  //Pagination Variables
  paginatedProducts: any[] = []; // Products displayed on current page
  itemsPerPage = 10;
  currentPage = 1;
  totalPages = 1;
  pageArray: any[] = [1];
  showFullPagination = false;
  searchTerm: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.getAllBuyerOrderItem();
  }

  onStatusChange(status: 'live' | 'cancelled' | 'closed') {
    this.currentStatus = status; // Update the current status
    this.getAllBuyerOrderItem(); // Fetch orders based on the selected status
  }

  getAllBuyerOrderItem() {
    this.orderService.getAllBuyerOrderItem(this.currentStatus).subscribe(
      (data) => {
        this.allBuyerOrder = data;
        this.roundedAverage = this.roundOffAverage();
        this.loading = false;
        this.updatePaginatedProducts();
        this.calculateTotalPages();
      },
      (error) => {
        console.error('Error fetching all buyer order:', error);
        this.loading = false;
      }
    );
    this.orderService.getCountOfBuyerOrder().subscribe((data) => {
      this.count = data.count;
    });
  }
  goToProductDetails(id: any, listingId: any) {
    this.router.navigate([`/product/${id}/${listingId}`]);
  }

  navigateToOrderTracking(orderIdentifier: string) {
    this.router.navigate(['/buyer/order/tracking', orderIdentifier]);
  }
  roundOffAverage() {
    // console.log('roundOffAverage called');
    // console.log('Order Data:', this.orderData);
    if (
      this.allBuyerOrder &&
      this.allBuyerOrder.order &&
      this.allBuyerOrder.order.ratings.length > 0
    ) {
      const productDetail = this.allBuyerOrder.ratings;
      // console.log('Product Detail:', productDetail);
      if (
        productDetail &&
        productDetail.ratings &&
        productDetail.ratings.overallRating !== undefined
      ) {
        const roundedValue =
          Math.round(productDetail.ratings.overallRating * 2) / 2;
        // console.log('Rounded Value: ', roundedValue);
        return roundedValue;
      }
    }
    // console.log('Ratings data is unavailable');
    return 0; // Default value if ratings data is unavailable
  }
  updatePaginatedProducts() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedProducts = this.allBuyerOrder.slice(startIndex, endIndex);
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.allBuyerOrder.length / this.itemsPerPage);
    for (let i = 2; i <= this.totalPages; i++) {
      this.pageArray.push(i);
    }
  }

  // Pagination logic for displaying the page numbers
  updatePageArray() {
    if (this.totalPages <= 6 || this.showFullPagination) {
      // Show all pages if total is less than 6 or if full pagination is expanded
      this.pageArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      // Show limited page range with "..."
      const startPages = [1, 2, 3, 4];
      const endPages = [this.totalPages];
      this.pageArray = [...startPages, -1, ...endPages]; // -1 represents "..."
    }
  }
  // Pagination Controls
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedProducts();
    }
  }
  goToPage(page: any) {
    if (page !== -1) {
      // Ignore clicks on "..."
      this.currentPage = page;
      this.updatePaginatedProducts();
      this.updatePageArray();
    } else {
      this.expandPagination();
    }
  }
  expandPagination() {
    this.showFullPagination = true;
    this.updatePageArray();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedProducts();
    }
  }

  filterOrders() {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    console.log(lowerSearchTerm);

    this.paginatedProducts = this.allBuyerOrder.filter(
      (order: {
        product: {
          product: any;
          productDescription: string;
          productHeading: string;
        };
        orderIdentifier: any;
      }) =>
        (order.product &&
          ((order.product.product.productDescription &&
            order.product.product.productDescription
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
            (order.product.product.productHeading &&
              order.product.product.productHeading
                .toLowerCase()
                .includes(lowerSearchTerm)))) ||
        order.orderIdentifier.includes(lowerSearchTerm)
    );
  }
}
