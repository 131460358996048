import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aboutus.component.html',
  styleUrl: './aboutus.component.scss',
})
export class AboutusComponent implements OnInit {
  showVision = true;
  showMission = false;
  constructor(private title: Title, private meta: Meta) {}
  ngOnInit(): void {
    this.title.setTitle(
      'About Us | Power Bihar - Your Trusted Partner for Electrical Products & Services in Bihar'
    );
    // this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({
      property: 'og:title',
      content:
        'About Us | Power Bihar - Your Trusted Partner for Electrical Products & Services in Bihar',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Discover Power Bihar, the leading marketplace for electrical products and services in Bihar. We specialize in providing high-quality electrical equipment and connecting you with expert electricians in your local area. Whether you're a homeowner, contractor, or business, Power Bihar is your one-stop destination for reliable and affordable electrical solutions.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'About Power Bihar, electrical products in Bihar, buy electrical equipment in Bihar, electricians in Bihar, local electrician services, Bihar electrical marketplace, trusted electrical solutions in Bihar',
    });
  }
  toggleDiv(name: any) {
    if (name == 'vision') {
      this.showVision = true;
      this.showMission = false;
    } else {
      this.showMission = true;
      this.showVision = false;
    }
  }
}
