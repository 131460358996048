export class CreateOrderRequest {
  paymentMode!: string;
  cartIdentifier: string[] = [];
  userId!: number;
  quantity!: number;
  price!: number;
  orderStatus!: string;
  finalAmount!: '';
  totalValue!: '';
  commission!: '';
  salesPerson!: '';
  sellerPinCode!: string;
  cart_id!: string;
  addressId!: string;
}
