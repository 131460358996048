import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-seller-listing',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './seller-listing.component.html',
  styleUrl: './seller-listing.component.scss',
})
export class SellerListingComponent implements OnInit {
  allSellerProduct: any[] = [];
  displayedProducts: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10; // Number of items to display per page
  searchTerm: string = ''; // To store the search query

  isModalOpen: boolean = false;

  allCategoryList: any[] = [];
  getSubCategoryList: any[] = [];
  getProductList: any;

  categoryId: string = ''; // Bind to the dropdown
  subcategoryId: string = '';
  productId: string = '';

  mrp: number = 0;
  offerPrice: number = 0;
  lotSize: number = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService
  ) {}
  ngOnInit(): void {
    this.sellerProduct();
    this.getAllCategory();
  }

  sellerProduct() {
    this.productService.getAllSellerProduct().subscribe((data) => {
      // console.log(data);
      this.allSellerProduct = data;
      this.updateDisplayedProducts(); // Set initial items for the first page
    });
  }

  getAllCategory() {
    this.productService.getAllCategory().subscribe(
      (data) => {
        this.allCategoryList = data;
        // console.log('Categories:', this.allCategoryList);
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  getAllSubCategory(categoryId: string) {
    if (categoryId) {
      this.productService.getAllSubCategory(categoryId).subscribe(
        (data) => {
          this.getSubCategoryList = data;
        },
        (error) => {
          console.error('Error fetching subcategories:', error);
        }
      );
    }
  }

  getProductBySubCategory(subCategoryId: string) {
    if (subCategoryId) {
      this.productService.getAllProductBySubCategory(subCategoryId).subscribe(
        (data) => {
          this.getProductList = data;
        },
        (error) => {
          console.error('Error fetching subcategories:', error);
        }
      );
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  submitNewListing() {
    // Check if mrp or offerPrice is 0
    // if (this.mrp === 0 || this.offerPrice === 0) {
    //   alert('MRP and Offer Price must be greater than 0.');
    //   return; // Prevent further execution
    // }

    const newProduct = {
      productId: this.productId,
      originalPrice: this.mrp,
      offerPrice: this.offerPrice,
      lotSize: this.lotSize,
      productColorId: 1, // Default or dynamic if applicable
      id: null,
    };
    console.log(newProduct);
    this.productService.addPriceDetails(newProduct).subscribe(
      (response) => {
        console.log('New product added successfully:', response);
        this.sellerProduct(); // Refresh product list
        this.closeModal();
      },
      (error) => {
        console.error('Error adding new product:', error);
      }
    );
  }

  updateDisplayedProducts() {
    // Trim the searchTerm and convert it to lowercase for case-insensitive matching
    const searchTermStr = this.searchTerm.trim().toLowerCase();

    // Filter products based on the searchTerm
    const filteredProducts = this.allSellerProduct.filter((product) => {
      // Convert both productId and searchTerm to strings and lowercase
      const productIdStr = product.itemId
        ? product.itemId.toString().toLowerCase()
        : '';

      console.log('Comparing:', productIdStr, searchTermStr); // Log values for debugging

      // Check if search term matches either product name or productId
      const isProductNameMatch = product.productName
        .toLowerCase()
        .includes(searchTermStr);
      const isProductIdMatch = productIdStr.includes(searchTermStr);

      return isProductNameMatch || isProductIdMatch;
    });

    // Update the displayed products based on filtered results and pagination
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedProducts = filteredProducts.slice(startIndex, endIndex);

    console.log('Filtered Products:', filteredProducts); // Debug filtered results
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.updateDisplayedProducts();
  }

  nextPage() {
    if (this.currentPage < this.getTotalPages()) {
      this.currentPage++;
      this.updateDisplayedProducts();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateDisplayedProducts();
    }
  }

  getTotalPages(): number {
    return Math.ceil(this.allSellerProduct.length / this.itemsPerPage);
  }

  startEditing(product: any) {
    // Enable edit mode for the selected product
    product.isEditing = true;

    // Backup the original values in case the user cancels
    product.originalMrp = product.mrp;
    product.originalOfferPrice = product.offerPrice;
    product.originalLotSize = product.lotSize;
  }

  saveProduct(product: any) {
    // Check if mrp, offerPrice, or lotSize are 0
    // if (
    //   product.mrp === 0 ||
    //   product.offerPrice === 0 ||
    //   product.lotSize === 0
    // ) {
    //   alert('MRP, Offer Price, and Lot Size must be greater than 0.');
    //   return; // Prevent saving the product if validation fails
    // }

    // Save the changes and exit edit mode
    product.isEditing = false;

    const priceDetails = {
      productId: product.productId,
      originalPrice: product.mrp, // Assuming `mrp` is the original price
      offerPrice: product.offerPrice,
      productColorId: 1, // Assuming `productColorId` is fixed as 1
      lotSize: product.lotSize || 1, // Default lot size to 1 if not provided
      id: product.id,
    };

    // Optionally, send updated data to the backend
    this.productService.addPriceDetails(priceDetails).subscribe(
      (response) => {
        console.log('Product details added successfully', response);
      },
      (error) => {
        console.error('Error adding price details', error);
      }
    );
  }

  cancelEditing(product: any) {
    // Restore the original values
    product.mrp = product.originalMrp;
    product.offerPrice = product.originalOfferPrice;
    product.lotSize = product.originalLotSize;

    // Exit edit mode
    product.isEditing = false;
  }

  deleteProduct(id: number) {
    // Call the service to delete the product and update the UI
    this.productService.deleteSellerListingById(id).subscribe(
      (response) => {
        console.log('Product deleted successfully', response);
        this.allSellerProduct = this.allSellerProduct.filter(
          (p) => p.id !== id
        );
        this.updateDisplayedProducts();
      },
      (error) => {
        console.error('Error deleting product', error);
      }
    );
  }
}
