import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { GoogleMapSearchComponent } from '../google-map-search/google-map-search.component';
import { EletricianService } from '../../services/eletrician.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-electrician-search-page',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './electrician-search-page.component.html',
  styleUrl: './electrician-search-page.component.scss',
})
export class ElectricianSearchPageComponent implements AfterViewInit {
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  @ViewChild('searchBox', { static: false }) searchBox!: ElementRef;
  defaultCalendlyUrl = environment.calendlyUrl;
  markers: google.maps.Marker[] = [];
  locaMarker: google.maps.Marker[] = [];
  map: google.maps.Map | undefined;
  circle: google.maps.Circle | undefined;

  isMapVisible: boolean = true;
  latitude: number = 0;
  longitude: number = 0;
  list: any[] = [];
  zoom: number = 8;
  electricianCordinates: Array<{ lat: number; lng: number }> = [];
  calendlyLoaded: boolean = false;
  loading: boolean = true; // Add a loading flag
  selectedPlace: any;
  distance: number = 40;
  debounceTimeout: any; // Timeout reference for debounce
  initialTechSpecs: number = 2;
  currentIndex: any;
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalPages: number = 1;
  pageArray: any[] = [1];
  showFullPagination: boolean = false;
  paginatedList: any[] = [];
  hoveredBoxIndex: number | null = null;
  QAList = [
    {
      que: 'How do I know the electricians are trustworthy?',
      ans: 'All our electricians are thoroughly vetted, licensed, and reviewed by previous users.',
    },
    {
      que: 'What happens if the electrician cancels my booking?',
      ans: 'We will notify you immediately and assist in finding another available electrician.',
    },
    // {
    //   que: 'How do I know the electricians are trustworthy?',
    //   ans: 'All our electricians are thoroughly vetted, licensed, and reviewed by previous users.',
    // },
    // {
    //   que: 'How do I know the electricians are trustworthy?',
    //   ans: 'All our electricians are thoroughly vetted, licensed, and reviewed by previous users.',
    // },
  ];

  constructor(
    private eletricianService: EletricianService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadCalendlyScript();
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe((params) => {
      this.latitude = parseFloat(params['latitude']);
      this.longitude = parseFloat(params['longitude']);
      this.initializePage();
    });
  }

  ngAfterViewInit() {
    this.initAutocomplete();
    this.initMap();
  }
  onMouseEnter(index: number) {
    this.hoveredBoxIndex = index;
  }

  onMouseLeave() {
    this.hoveredBoxIndex = null;
  }

  isHovered(index: number): boolean {
    return this.hoveredBoxIndex === index;
  }

  loadCalendlyScript() {
    const script = document.createElement('script');
    script.src = `https://assets.calendly.com/assets/external/widget.js?v=${new Date().getTime()}`;
    script.async = true;
    script.onload = () => (this.calendlyLoaded = true);
    document.body.appendChild(script);
  }

  initializePage() {
    this.electricianList();
    this.popElectricianList();
  }

  initAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchBox.nativeElement,
      {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'in' },
      }
    );

    autocomplete.addListener('place_changed', () =>
      this.handlePlaceChanged(autocomplete)
    );
  }

  handlePlaceChanged(autocomplete: google.maps.places.Autocomplete) {
    const place = autocomplete.getPlace();
    if (place.geometry?.location) {
      this.selectedPlace = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address || 'Selected location',
      };
      this.latitude = this.selectedPlace.lat;
      this.longitude = this.selectedPlace.lng;
      this.addSearchLocationMarker(
        new google.maps.LatLng(this.latitude, this.longitude)
      );
      this.map!.setCenter(
        new google.maps.LatLng(this.latitude, this.longitude)
      );
      this.electricianList();
    }
  }

  initMap() {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);
    this.addSearchLocationMarker(
      new google.maps.LatLng(this.latitude, this.longitude)
    );
    this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
    // this.addMarker(new google.maps.LatLng(this.latitude, this.longitude));
    this.fitBounds();
  }
  addSearchLocationMarker(location: google.maps.LatLng) {
    // Remove existing marker if present
    this.clearLocationMarkers();
    // Create new draggable marker
    const marker = new google.maps.Marker({
      position: location,
      map: this.map,
      draggable: false, // Set draggable property to true
      // title: this.title,
    });
    this.locaMarker.push(marker);
    // Draw the circle around locaMarker
    this.drawCircle(location);

    // Update the circle's position when the marker is dragged
    marker.addListener('dragend', () => {
      this.drawCircle(marker.getPosition()!);
    });

    this.fitBounds();
    // this.fitBounds();
  }
  drawCircle(location: google.maps.LatLng) {
    // Remove existing circle if any
    if (this.circle) {
      this.circle.setMap(null);
    }

    // Draw new circle with a 40 km radius
    this.circle = new google.maps.Circle({
      map: this.map,
      center: location,
      radius: this.distance * 1000, // Convert km to meters
      fillColor: '#FF0000',
      fillOpacity: 0.2,
      strokeColor: '#FF0000',
      strokeOpacity: 0.6,
      strokeWeight: 2,
    });
    // this.updateRadiusCircle();

    this.fitBounds();
  }
  updateRadiusCircle() {
    if (this.circle) {
      // this.circle.setCenter(this.locaMarker?.getPosition());
      this.circle.setRadius(this.distance * 1000); // Update radius based on distance
    }
    this.electricianList();
    // this.popElectricianList();
  }
  // Method called on slider change
  handleDistanceChange() {
    // Clear any previous debounce timeout
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    // Set a new debounce timeout to call updateCircleRadius after 2 seconds
    this.debounceTimeout = setTimeout(() => {
      this.updateRadiusCircle();
    }, 2000);
  }
  addMarker(location: google.maps.LatLng, image: any, name: any) {
    const customIcon = {
      url: 'assets/result.gif', // URL of your custom icon
      scaledSize: new google.maps.Size(80, 80), // Scale the icon size
    };
    if (!image) {
      image = '/assets/electrician-avatar.png';
    }
    // console.log(firstName);
    // Create an InfoWindow
    const infoWindow = new google.maps.InfoWindow({
      content: `<div style="font-size: 14px;" class="px-3 py-2 text-white"><img src="${image}" alt="Profile" class="img-fluid pt-2" style="max-width:80px;max-height:80px;border-radius:50%;"> <div class="pt-3 text-center fw500">${name}</div></div>`, // Customize content as needed
      disableAutoPan: false,
    });

    const marker = new google.maps.Marker({
      position: location,
      map: this.map,
      icon: customIcon, // Assign the custom icon
      animation: google.maps.Animation.DROP, // Optional: animation effect
    });
    // Add mouseover event to show info window
    marker.addListener('mouseover', () => {
      infoWindow.open(this.map, marker);
    });

    // Add mouseout event to close info window
    marker.addListener('mouseout', () => {
      infoWindow.close();
    });
    // Push marker to the markers array
    this.markers.push(marker);
    this.fitBounds();
  }

  fitBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => bounds.extend(marker.getPosition()!));
    this.locaMarker.forEach((marker) => bounds.extend(marker.getPosition()!));
    if (this.circle) {
      bounds.union(this.circle.getBounds()!);
    }
    this.map?.fitBounds(bounds);
  }

  toggleMap() {
    this.isMapVisible = !this.isMapVisible;
  }

  openCalendly(calenderUrl: string) {
    if (!calenderUrl) {
      calenderUrl = this.defaultCalendlyUrl;
    }
    if (this.calendlyLoaded) {
      (window as any).Calendly.showPopupWidget(calenderUrl);
    } else {
      console.warn('Calendly widget script is not loaded yet.');
    }
    return false;
  }

  clearMarkers() {
    this.popElectricianList();
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
  }
  clearLocationMarkers() {
    // this.popElectricianList();
    this.locaMarker.forEach((marker) => marker.setMap(null));
    this.locaMarker = [];
  }

  electricianList() {
    this.loading = true; // Set loading flag to true while fetching data
    this.eletricianService
      .searchElectrician(this.latitude, this.longitude, this.distance)
      .subscribe((data) => {
        this.clearMarkers();
        this.electricianCordinates = data.map((electrician: any) => ({
          lat: electrician.latitude,
          lng: electrician.longitude,
        }));
        // Add markers for each electrician with their specific profile image
        data.forEach((electrician: any) => {
          this.addMarker(
            new google.maps.LatLng(electrician.latitude, electrician.longitude),
            electrician.profileImage, // Pass the profile image URL for each electrician,
            electrician.firstName
          );
        });

        this.loading = false; // Set loading flag to false after data is loaded
        this.cdr.detectChanges(); // Trigger change detection to update the view
      });
  }

  popElectricianList() {
    this.eletricianService
      .searchElectrician(this.latitude, this.longitude, this.distance)
      .subscribe((data) => {
        this.list = data.map((electrician: any) => {
          if (electrician.electrician_specialization) {
            electrician.electrician_specialization = JSON.parse(
              electrician.electrician_specialization
            );
          }
          return electrician;
        });
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].showAll = false;
          this.list[i].initialTechSpecs = this.initialTechSpecs;
        }
        this.calculateTotalPages();
        this.updatePaginatedProducts();
        this.updatePageArray();
        this.loading = false; // Set loading flag to false after data is loaded
        this.cdr.detectChanges(); // Trigger change detection
      });
  }
  goToDetails(identifier: any) {
    this.router.navigate(['/electricianDetail', identifier]);
  }
  updatePaginatedProducts() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedList = this.list.slice(startIndex, endIndex);
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.list.length / this.itemsPerPage);
    console.log(this.totalPages);
    for (let i = 2; i <= this.totalPages; i++) {
      this.pageArray.push(i);
    }
  }
  // Pagination logic for displaying the page numbers
  updatePageArray() {
    if (this.totalPages <= 6 || this.showFullPagination) {
      // Show all pages if total is less than 6 or if full pagination is expanded
      this.pageArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      // Show limited page range with "..."
      const startPages = [1, 2, 3, 4];
      const endPages = [this.totalPages];
      this.pageArray = [...startPages, -1, ...endPages]; // -1 represents "..."
    }
  }
  // Pagination Controls
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedProducts();
    }
  }
  goToPage(page: any) {
    if (page !== -1) {
      // Ignore clicks on "..."
      this.currentPage = page;
      this.updatePaginatedProducts();
      this.updatePageArray();
    } else {
      this.expandPagination();
    }
  }
  expandPagination() {
    this.showFullPagination = true;
    this.updatePageArray();
  }
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedProducts();
    }
  }
}
