import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../app/auth-service.service';
import { environment } from '../environments/environment';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EletricianService {
  private baseUrl = environment.API_URL + '/electrician';
  // private baseAPI = environment.API_URL;

  constructor(private http: HttpClient, private authService: AuthService) {}

  allElectricianList() {
    const url = `${this.baseUrl}`;
    return this.http
      .get<any>(url, { headers: this.authService.getAuthHeaders() })
      .pipe(first());
  }
  searchElectrician(lat: any, long: any, distance: any) {
    const url = `${this.baseUrl}/searchelectrician/${lat}/${long}/${distance}`;
    return this.http
      .get<any>(url, { headers: this.authService.getAuthHeaders() })
      .pipe(first());
  }
}
