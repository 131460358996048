import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-seller-order-listing',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './seller-order-listing.component.html',
  styleUrl: './seller-order-listing.component.scss',
})
export class SellerOrderListingComponent implements OnInit {
  // allBuyerOrder: any;
  allSellerOrder: any = [];
  filteredOrders: any = [];
  orderItemDetails: any;
  identifier: string = '';
  loading: boolean = true;
  count: number = 0;
  roundedAverage: number = 0;
  currentStatus: 'live' | 'cancelled' | 'closed' = 'live';
  //Pagination Variables
  paginatedProducts: any[] = []; // Products displayed on current page
  itemsPerPage = 10;
  currentPage = 1;
  totalPages = 1;
  pageArray: any[] = [1];
  showFullPagination = false;
  searchTerm: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.getAllSellerOrder();
  }

  toggleOrderDetails(order: any): void {
    order.showDetails = !order.showDetails;
  }

  onStatusChange(status: 'live' | 'cancelled' | 'closed') {
    this.currentStatus = status; // Update the current status
    this.filterOrders(); // Fetch orders based on the selected status
  }

  getAllSellerOrder() {
    this.orderService.getAllSellerOrder().subscribe(
      (data) => {
        this.allSellerOrder = data;
        this.filterOrders(); // Filter orders initially
        console.log(this.allSellerOrder, '------------------');
        this.roundedAverage = this.roundOffAverage();
        this.loading = false;
        this.updatePaginatedProducts();
        this.calculateTotalPages();
      },
      (error) => {
        console.error('Error fetching all seller order:', error);
        this.loading = false;
      }
    );
    this.orderService.getCountOfBuyerOrder().subscribe((data) => {
      this.count = data.count;
    });
  }
  goToProductDetails(id: any, listingId: any) {
    this.router.navigate([`/product/${id}/${listingId}`]);
  }

  navigateToOrderTracking(orderIdentifier: string) {
    this.router.navigate(['/sellerorder/track', orderIdentifier]);
  }
  roundOffAverage() {
    // console.log('roundOffAverage called');
    // console.log('Order Data:', this.orderData);
    if (
      this.allSellerOrder &&
      this.allSellerOrder.order &&
      this.allSellerOrder.order.ratings.length > 0
    ) {
      const productDetail = this.allSellerOrder.ratings;
      // console.log('Product Detail:', productDetail);
      if (
        productDetail &&
        productDetail.ratings &&
        productDetail.ratings.overallRating !== undefined
      ) {
        const roundedValue =
          Math.round(productDetail.ratings.overallRating * 2) / 2;
        // console.log('Rounded Value: ', roundedValue);
        return roundedValue;
      }
    }
    // console.log('Ratings data is unavailable');
    return 0; // Default value if ratings data is unavailable
  }
  updatePaginatedProducts() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedProducts = this.filteredOrders.slice(startIndex, endIndex);
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.filteredOrders.length / this.itemsPerPage);
    // for (let i = 2; i <= this.totalPages; i++) {
    //   this.pageArray.push(i);
    // }
    this.pageArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  // Pagination logic for displaying the page numbers
  updatePageArray() {
    if (this.totalPages <= 6 || this.showFullPagination) {
      // Show all pages if total is less than 6 or if full pagination is expanded
      this.pageArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      // Show limited page range with "..."
      const startPages = [1, 2, 3, 4];
      const endPages = [this.totalPages];
      this.pageArray = [...startPages, -1, ...endPages]; // -1 represents "..."
    }
  }
  // Pagination Controls
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedProducts();
    }
  }
  goToPage(page: any) {
    if (page !== -1) {
      // Ignore clicks on "..."
      this.currentPage = page;
      this.updatePaginatedProducts();
      this.updatePageArray();
    } else {
      this.expandPagination();
    }
  }
  expandPagination() {
    this.showFullPagination = true;
    this.updatePageArray();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedProducts();
    }
  }

  filterOrders() {
    // Step 1: Filter by current status.
    let orders = this.allSellerOrder.filter((order: any) => {
      return this.mapStatusToCategory(order.orderStatus) === this.currentStatus;
    });

    // Step 2: If a search term is provided, further filter by orderIdentifier, productDescription, or productHeading.
    if (this.searchTerm && this.searchTerm.trim() !== '') {
      const lowerSearchTerm = this.searchTerm.trim().toLowerCase();

      orders = orders.filter((order: any) => {
        // Check the order identifier.
        const matchesOrderIdentifier =
          order.orderIdentifier &&
          order.orderIdentifier.toLowerCase().includes(lowerSearchTerm);

        // Try to get the product details from either order.product.product or order.productDetail.
        // Check if any item in the relatedOrderItem array matches.
        const matchesRelatedOrderItem =
          order.relatedOrderItem &&
          order.relatedOrderItem.some((item: any) => {
            // Extract product details from the item.
            const product = item.productDetail?.product;
            if (!product) {
              return false;
            }
            const matchesDescription =
              product.productDescription &&
              product.productDescription
                .toLowerCase()
                .includes(lowerSearchTerm);
            const matchesHeading =
              product.productHeading &&
              product.productHeading.toLowerCase().includes(lowerSearchTerm);
            return matchesDescription || matchesHeading;
          });

        return matchesOrderIdentifier || matchesRelatedOrderItem;
      });
    }
    // Update the filtered orders.
    this.filteredOrders = orders;

    // Reset current page, recalc total pages, and update pagination.
    this.currentPage = 1;
    this.calculateTotalPages();
    this.updatePaginatedProducts();
  }

  mapStatusToCategory(status: string): 'live' | 'cancelled' | 'closed' {
    const liveStatuses = [
      'ORDER_PLACED',
      'IN_PROGRESS',
      'PREPARE_TO_DISPATCH',
      'DISPATCHED',
      'OUT_FOR_DELIVERY',
      'RETURN_REQUESTED',
      'RETURN_APPROVED',
      'RETURN_RECEIVED',
    ];
    const cancelledStatuses = ['CANCELLED', 'PARTIALLY_CANCELLED'];
    const closedStatuses = [
      'DELIVERED',
      'RETURN_COMPLETED',
      'PARTIALLY_COMPLETED',
    ];

    if (liveStatuses.includes(status)) return 'live';
    if (cancelledStatuses.includes(status)) return 'cancelled';
    if (closedStatuses.includes(status)) return 'closed';

    return 'live'; // Default case
  }

  getSafeArrayLength(value: any): number[] {
    console.log('getSafeArrayLength input:', value);
    let num = Math.max(0, Math.floor(Number(value) || 0)); // Ensure non-negative integer
    console.log('getSafeArrayLength output:', num);
    return new Array(num).fill(0);
  }
}
