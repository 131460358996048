import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { UsersService } from '../../services/user.service';
import { AuthService } from '../auth-service.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-login-as-user',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './login-as-user.component.html',
  styleUrl: './login-as-user.component.scss',
})
export class LoginAsUserComponent implements OnInit {
  otpRequested: boolean = false;
  userPhoneNumber: any;
  otp: any;
  adminPhoneNumber: any;
  user: any;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private authService: AuthService,
    private userService: UsersService
  ) {}
  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      // this.router.navigate(['/']);
    }
  }
  onInputChange() {
    const mobileInput = document.getElementById(
      'mobilenumber'
    ) as HTMLInputElement;
    const submitBtn = document.getElementById('submitBtn') as HTMLButtonElement;

    // Enable button if 10-digit mobile number is entered
    if (/^\d{10}$/.test(mobileInput.value)) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }
  onOtpInputChange(event: any) {
    const input = event.target as HTMLInputElement;
    const submitBtn = document.getElementById('submitBtn') as HTMLButtonElement;

    // Remove any non-digit characters
    input.value = input.value.replace(/\D/g, '');

    // Limit the input length to 6 digits
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
    }

    // Enable button if 6-digit OTP is entered
    if (/^\d{6}$/.test(input.value)) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }
  getOTP() {
    // console.log('OTP Requested');
    this.otpRequested = true;
    this.loginService
      .generateLoginAsOTP(this.userPhoneNumber, this.adminPhoneNumber)
      .subscribe((data) => {});
  }
  validateOtp() {
    if (!this.otp) {
      console.error('Please enter the OTP.');
      return;
    }

    if (this.otp.length !== 6) {
      console.error('OTP must be exactly 6 digits.');
      return;
    }

    // Log the values to ensure they are populated correctly
    // console.log('User Phone Number:', this.userPhoneNumber);
    // console.log('OTP:', this.otp);

    this.loginService
      .validateOTP(this.userPhoneNumber, this.adminPhoneNumber, this.otp)
      .subscribe(
        (response) => {
          // console.log('OTP validation response:', response);
          if (response && response.success) {
            // console.log('OTP validation successful');
            this.authService.setToken(response.token);
            // localStorage.setItem('token', response.token); // Assuming the token is returned in the response
            // Handle successful validation here
            this.userService.getUser().subscribe((data) => {
              this.user = data;
            });
            if (this.hasCompleteUserDetails()) {
              this.router.navigate(['/']);
            } else {
              this.router.navigate(['/myaccount']);
            }
          } else {
            console.error('OTP validation failed:', response.message);
            // Handle failed validation here
            alert(response.message);
          }
        },
        (error) => {
          console.error('Error validating OTP:', error);
          // Handle errors here
        }
      );
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.user;
    return phoneNumber && firstName && lastName && emailAddress;
  }
}
