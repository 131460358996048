<div class="">
  <span class="font-black font24 fw400">Add New Address</span>
  <div class="mt-2 col-lg-11 col-12 pe-lg-4 pe-3">
    <button
      (click)="showMap = !showMap"
      *ngIf="!showMap"
      class="location py-3 col-lg-6 col-12 font20 fw400"
    >
      <img
        src="../../assets/locationicon.png"
        alt=""
        style="width: 22px; height: 22px"
        class="me-3"
      />
      Locate on Map
    </button>
    <!-- {{ addressDTO | json }} -->
    <app-map
      (locationSelected)="onLocationSelected($event)"
      [latitude]="parseInteger(addressDTO!.latitude)"
      [longitude]="parseInteger(addressDTO!.longitude)"
      *ngIf="showMap"
      class="col-12"
    ></app-map>
    <button
      class="location py-3 col-6 mt-2 mt-md-2"
      (click)="showMap = !showMap"
      *ngIf="showMap"
    >
      Hide Map
    </button>
  </div>
  <form #addressForm="ngForm" (ngSubmit)="saveAddress(addressForm)">
    <div class="form-group d-flex mt-5 gap-5">
      <div class="col-5">
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="First Name"
          class="form-control custom-input py-3"
          [(ngModel)]="addressDTO!.firstName"
          required
          #firstName="ngModel"
        />
        <div
          *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
          class="text-danger font12"
        >
          *Kindly Provide First Name
        </div>
      </div>
      <div class="col-5">
        <input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Last Name"
          class="form-control custom-input py-3"
          [(ngModel)]="addressDTO!.lastName"
          required
          #lastName="ngModel"
        />
        <div
          *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
          class="text-danger font12"
        >
          *Kindly Provide Last Name
        </div>
      </div>
    </div>

    <div class="form-group d-flex mt-lg-5 mt-3 gap-5">
      <div class="col-5">
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Phone"
          class="form-control custom-input py-3"
          [(ngModel)]="addressDTO!.phoneNumber"
          required
          #phoneNumber="ngModel"
        />
        <div
          *ngIf="
            phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)
          "
          class="text-danger font12"
        >
          *Kindly Provide Phone Number
        </div>
      </div>
      <div class="col-5">
        <input
          type="text"
          id="email"
          name="email"
          placeholder="Email Address"
          class="form-control custom-input py-3"
          [(ngModel)]="addressDTO!.email"
          required
          #email="ngModel"
        />
        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="text-danger font12"
        >
          *Kindly Provide Email Address
        </div>
      </div>
    </div>

    <div class="form-group mt-lg-5 mt-3 col-lg-11 pe-lg-4 pe-3 col-12">
      <input
        type="text"
        id="firstLine"
        name="firstLine"
        placeholder="Street Address"
        class="form-control custom-input py-3"
        [(ngModel)]="addressDTO!.firstLine"
        required
        #firstLine="ngModel"
      />
      <div
        *ngIf="firstLine.invalid && (firstLine.dirty || firstLine.touched)"
        class="text-danger font12"
      >
        *Kindly Provide Street Address
      </div>
    </div>

    <div class="form-group d-flex mt-lg-5 mt-3 gap-5">
      <div class="col-5">
        <input
          type="text"
          id="city"
          name="city"
          placeholder="City"
          [(ngModel)]="addressDTO!.city"
          class="form-control custom-input py-3"
          required
          #city="ngModel"
        />
        <div
          *ngIf="city.invalid && (city.dirty || city.touched)"
          class="text-danger font12"
        >
          *Kindly Provide City
        </div>
      </div>
      <div class="col-5">
        <input
          type="text"
          id="country"
          name="country"
          placeholder="Country"
          [(ngModel)]="addressDTO!.country"
          class="form-control custom-input py-3"
          required
          #country="ngModel"
        />
        <div
          *ngIf="country.invalid && (country.dirty || country.touched)"
          class="text-danger font12"
        >
          *Kindly Provide Country
        </div>
      </div>
    </div>
    <div class="form-group d-flex mt-lg-5 mt-3 gap-5">
      <div class="col-5">
        <input
          type="text"
          id="state"
          name="state"
          placeholder="State"
          [(ngModel)]="addressDTO!.state"
          class="form-control custom-input py-3"
          required
          #state="ngModel"
        />
        <div
          *ngIf="state.invalid && (state.dirty || state.touched)"
          class="text-danger font12"
        >
          *Kindly Provide State
        </div>
      </div>

      <div class="col-5">
        <input
          type="text"
          id="pinCode"
          name="pinCode"
          placeholder="Post Zip Code"
          [(ngModel)]="addressDTO!.pinCode"
          class="form-control custom-input py-3"
          required
          #pinCode="ngModel"
        />
        <div
          *ngIf="pinCode.invalid && (pinCode.dirty || pinCode.touched)"
          class="text-danger font12"
        >
          *Kindly Provide Post Zip Code
        </div>
      </div>
    </div>
    <!-- <div class="address-type">
          <label
            ><input type="radio" name="addressType" value="Shipping" /> Shipping</label
          >
          <label
            ><input type="radio" name="addressType" value="Billing" /> Billing</label
          >
        </div> -->

    <div class="mt-lg-5 my-3">
      <button
        type="submit"
        class="btn-save rounded4 bgorange px-5 py-2"
        [disabled]="addressForm.invalid"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
