<div class="d-flex col-12 justify-content-center font36 fw400 my-5">
  New Arrivals
</div>
<div class="ms-3 ms-lg-5 ps-2 ps-lg-5 my-5">
  <div class="bg-light-grey col-11 d-flex px-lg-5 py-4 py-lg-5" *ngIf="productList.length > 0">
    <div
      class="align-items-center  col-12 d-flex flex-column flex-lg-row flex-lg-wrap justify-content-center products-container py-0 py-lg-5 gap-5">
      <div *ngFor="let product of productList" class="product-card" role="button"
        (click)="navigateToProductDetails(product.productId)">
        <!-- {{product}} -->
        <div class="product-card-header">
          <div class="banner bgyellow text-white d-flex col-8 justify-content-center" *ngIf="product.isBestSeller">
            BestSeller
          </div>
          <div class="image-placeholder" [ngStyle]="product.isBestSeller ? {} : {'margin-top':'1.5rem'}">
            <img [src]="
              product.productImageList[0]?.imagePublicUrl ||
              '/assets/default-image.png'
            " [alt]="product.productHeading" class="product-image" />
            <div class="bgyellow discount-badge" style="position: absolute;" *ngIf="product.discountAmount>0"> {{
              product.discountAmount /
              product.mrp | percent : "1.0-0"
              }} </div>
          </div>

        </div>
        <div class="product-details">
          <div class="product-name">{{ product.productHeading }}</div>
          <div class="product-price">
            <div class="discounted-price font15 fw400 lh-1">
              ₹{{ product.finalAmtWithTax | number:'1.2-2'}}
            </div>
            <div class="original-price font12 fw400 lh-1 mt-1">
              MRP: ₹{{ product.mrp | number:'1.2-2'}}
            </div>
          </div>
          <!-- <div class="product-rating">{{ product.rating || "★★★★☆" }}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>