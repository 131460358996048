import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-brands-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './our-brands-card.component.html',
  styleUrl: './our-brands-card.component.scss',
})
export class OurBrandsCardComponent implements OnInit {
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    this.getBrandList();
  }
  constructor(private productService: ProductService, private router: Router) {}

  brandList: any;
  getBrandList() {
    this.productService.getAllBrand().subscribe((data) => {
      this.brandList = data;
    });
  }
  goToBrand(brandId: any) {
    // this.showBrandPopup = false;
    this.router.navigate(['/brand', brandId]);
  }
}
