<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Wishlist
</div>

<div
  class="font-primary font24 fw400 lh40 justify-content-center justify-content-lg-start col-10 d-flex p-5 ms-5"
>
  My Wishlist
</div>

<div
  class="d-flex ms-lg-1 ms-3 mb-5 justify-content-center"
  *ngFor="let product of wishListItems"
>
  <div
    class="d-flex flex-column flex-lg-row align-self-lg-start align-self-center justify-content-center py-5 px-5 col-10 col-lg-11 ms-lg-5 ms-0 product-container"
  >
    <div
      class="d-flex col-lg-2 col-12 align-self-lg-start align-self-center justify-content-center justify-content-lg-start mb-lg-0 mb-3"
    >
      <ng-container
        *ngIf="
          product.productDetailDto.productImageList &&
          product.productDetailDto.productImageList.length > 0
        "
      >
        <div class="thumbnail-box">
          <img
            class=""
            width="100"
            [src]="product.productDetailDto.productImageList[0].imagePublicUrl"
            alt="Product Image"
          />
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !product.productDetailDto.productImageList ||
          product.productDetailDto.productImageList.length === 0
        "
      >
        <div class="thumbnail-box">
          <img
            width="100"
            src="../../../assets/no-images.jpg"
            alt="Product Image"
          />
        </div>
      </ng-container>
    </div>
    <div class="d-flex col-lg-6 col-12 flex-column">
      <div class="d-flex flex-column flex-lg-row justify-content-center">
        <div
          class="col align-items-lg-start mb-lg-3 mb-4 mb-md-0 text-center text-lg-start align-items-center"
        >
          <h3
            class="mb-0 lh3 font-primary font24 fw400"
            role="button"
            (click)="
              goToProductDetails(
                product.productDetailDto.productId,
                product.listingId
              )
            "
          >
            {{ product.productDetailDto.productHeading }}
          </h3>
          <p class="mb-0 font20 fw400 lh30">
            {{ product.productDetailDto.productDescription }}
          </p>
          <p
            class="font12 font-primary"
            *ngIf="product.productDetailDto.lotSize > 1"
          >
            *Sold in Pack of {{ product.productDetailDto.lotSize }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-12 d-flex gap-2 flex-column pe-lg-4 pe-0">
      <div
        class="justify-content-between align-items-center justify-content-lg-between d-flex"
        *ngIf="product.productDetailDto.avgRatings"
      >
        <div class="rating-stars">
          <div
            class="star"
            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
            [ngClass]="{
              filled:
                i < getFloor(product.productDetailDto.avgRatings.overallRating),
              'half-filled':
                i >=
                  getFloor(product.productDetailDto.avgRatings.overallRating) &&
                i < product.productDetailDto.avgRatings.overallRating
            }"
          >
            ★
          </div>
        </div>
        <div>{{ product.productDetailDto.avgRatings.totalRating }} Ratings</div>
      </div>

      <div
        class="font24 fw400 d-flex justify-content-center gap-2 justify-content-lg-start"
      >
        <span
          class="font-primary"
          *ngIf="product.productDetailDto.discountAmount > 0"
          >-{{
            product.productDetailDto.discountAmount /
              product.productDetailDto.mrp | percent : "1.0-0"
          }}</span
        >
        ₹ {{ product.productDetailDto.finalAmtWithTax | number : "1.2-2" }}
      </div>
      <div class="d-flex justify-content-between">
        <div class="font14 fw400 pt-1">
          M.R.P.: ₹ {{ product.productDetailDto.mrp | number : "1.2-2" }}
        </div>
        <div role="button" (click)="removeFromList(product)">
          <img src="../../assets/Trash.png" class="deleteImage" />
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-self-end mt-4 gap-5 font20 fw400 rounded4 col-12"
      >
        <button
          class="buy-now-btn px-4"
          (click)="buyNow(product.productDetailDto)"
        >
          Buy Now
        </button>
        <button
          class="add-to-cart-btn px-4"
          (click)="addToCart(product.productDetailDto, false)"
        >
          Add To Cart
        </button>
      </div>
    </div>
  </div>
</div>
