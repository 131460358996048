import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { environment } from '../environments/environment';
import { UserDTO } from '../app/dto/UserDTO';
import { UserAddressDTO } from '../app/dto/UserAddressDTO';
import { AuthService } from '../app/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private baseUrl = environment.API_URL + '/users';

  constructor(private http: HttpClient, private authService: AuthService) {}

  getUser(): Observable<UserDTO> {
    const url = `${this.baseUrl}/getUserById`;
    return this.http
      .get<UserDTO>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getDefaultAddress(id: string) {
    const url = `${this.baseUrl}/getAddressById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAddressById(addressId: string) {
    const url = `${this.baseUrl}/getAddressById/${addressId}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  editAddress(addressDTO: UserAddressDTO) {
    const url = `${this.baseUrl}/editAddress`;
    return this.http
      .post<UserAddressDTO>(url, addressDTO, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getAllAddress() {
    const url = `${this.baseUrl}/getAllAddress`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  markAddressAsDefault(userId: string, addressId: string) {
    const url = `${this.baseUrl}/markAddressDefault/${userId}/${addressId}`;
    return this.http
      .post<any>(url, userId, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  deleteAddress(addressId: any) {
    const url = `${this.baseUrl}/deleteAddressById/${addressId}`;
    return this.http
      .post<any>(url, addressId, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  edit(userDTO: UserDTO) {
    const url = `${this.baseUrl}/edit`;
    return this.http
      .post<UserDTO>(url, userDTO, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  save(userDTO: UserDTO) {
    const url = `${this.baseUrl}/save`;
    return this.http
      .post<UserDTO>(url, userDTO, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  roles() {
    const url = `${this.baseUrl}/getAllTypes`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  addAddress(addressDTO: UserAddressDTO) {
    const url = `${this.baseUrl}/addAddress`;
    return this.http
      .post<UserAddressDTO>(url, addressDTO, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  setAsBillingAddress(id: string) {
    const url = `${this.baseUrl}/markAsBillingAddress/${id}`;
    return this.http
      .post<any>(url, id, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getAllRegion() {
    const url = `${this.baseUrl}/getAllRegion`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
}
