import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ProductService } from '../../../services/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-rating-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-rating-details.component.html',
  styleUrl: './product-rating-details.component.scss',
})
export class ProductRatingDetailsComponent implements OnInit {
  @Input() ratings: any;
  isLiked: boolean = false;
  disLiked: boolean = false;
  initialReview: number = 0;
  imageUrls: any;
  selectedImage: any;
  @ViewChild('allImagesModal') modal: any;
  currentStartIndex: number = 0;
  constructor(
    private productService: ProductService,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    this.initialReview = 4;
    this.currentStartIndex = 0;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ratings'] && changes['ratings'].currentValue) {
      this.stackImages();
    }
  }
  likeOrDislike(id: any, like: number) {
    this.productService.likeRating(id, like).subscribe((data) => {
      if (like == 1) {
        this.isLiked = true;
      } else {
        this.disLiked = true;
      }
    });
  }
  viewImages(imageUrl: any) {
    this.selectedImage = imageUrl;
    this.modalService.open(this.modal);
  }
  stackImages() {
    this.imageUrls = this.ratings
      .map(
        (rating: { image_id: { [s: string]: unknown } | ArrayLike<unknown> }) =>
          Object.entries(rating.image_id)
            .filter(([key, value]) => key.startsWith('image') && value !== null)
            .map(([key, value]) => value) // Extract the image URLs after filtering
      )
      .flat();
    this.selectedImage = this.imageUrls[0];
  }
  selectImage(imageUrl: any) {
    this.selectedImage = imageUrl;
  }
  nextImage() {
    if (this.currentStartIndex + 4 < this.imageUrls.length) {
      this.currentStartIndex += 1;
    }
  }
  prevImage() {
    if (this.currentStartIndex > 0) {
      this.currentStartIndex -= 1;
    }
  }
}
