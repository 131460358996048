<div class="border-bottom-grey mt-3"></div>
<div class="d-flex col-12 py-4 justify-content-evenly border-bottom-grey" *ngFor="let item of ratings; let i = index"
  [ngClass]="{'bg-light-grey-1':i%2==0}">
  <div class="d-flex flex-column col-8 gap-2" *ngIf="i <= initialReview && item.review">
    <div>
      <span class="darkorangecircle text-center text-white p-2">{{ item.rating }}
        <img src="../../../assets/startWhite.png" alt="" class="pb-1" /></span>
      <span class="ps-3 font14 fw500">{{ item.review_header }}</span>
    </div>
    <div class="font14 fw400">{{ item.review }}</div>
    <div class="font12 text-light-black fw400">{{ item.userName }}</div>
    <div class="d-flex flex-row gap-2" *ngIf="item.image_id" role="button">
      <img src="{{ item.image_id.image1 }}" alt="" class="img-fluid img-container" *ngIf="item.image_id.image1"
        (click)="viewImages(item.image_id.image1)" />
      <img src="{{ item.image_id.image2 }}" alt="" class="img-fluid img-container" *ngIf="item.image_id.image2"
        (click)="viewImages(item.image_id.image2)" />
      <img src="{{ item.image_id.image3 }}" alt="" class="img-fluid img-container" *ngIf="item.image_id.image3"
        (click)="viewImages(item.image_id.image3)" />
      <img src="{{ item.image_id.image4 }}" alt="" class="img-fluid img-container" *ngIf="item.image_id.image4"
        (click)="viewImages(item.image_id.image4)" />
      <img src="{{ item.image_id.image5 }}" alt="" class="img-fluid img-container" *ngIf="item.image_id.image5"
        (click)="viewImages(item.image_id.image5)" />
    </div>
  </div>
  <div class="d-flex flex-row col-2 gap-lg-5 gap-2" *ngIf="i <= initialReview && item.review">
    <div class="font14 fw400" role="button" (click)="likeOrDislike(item.identifier, 1)">
      <p class="gap-2 d-flex align-items-center">
        <img src="../../../assets/like.png" alt="" />
        <span *ngIf="!isLiked">{{ item.like }}</span>
        <span *ngIf="isLiked">{{ item.like + 1 }}</span>
      </p>
    </div>
    <div class="font14 fw400" role="button" (click)="likeOrDislike(item.identifier, 0)">
      <p class="gap-2 d-flex align-items-center">
        <img src="../../../assets/dislike.png" alt="" />
        <span *ngIf="!disLiked">{{ item.dislike }}</span>
        <span *ngIf="disLiked">{{ item.dislike + 1 }}</span>
      </p>
    </div>
  </div>
</div>
<div class="col-12 d-flex justify-content-center py-3 gap-2 font14 font-primary">
  <span *ngIf="ratings.length > initialReview" role="button" (click)="initialReview = initialReview + 4">See More</span>
  <span *ngIf="ratings.length > initialReview && initialReview > 4" role="button"
    (click)="initialReview = initialReview - 4">See Less</span>
</div>
<ng-template class="modal fade mt-5 center" #allImagesModal tabindex="-1" aria-label="Review" aria-hidden="true">
  <div class="modal-header text-center d-flex justify-content-center align-items-center">
    All User Images
  </div>
  <div class="modal-body d-flex flex-column gap-2">
    <div class="d-flex justify-content-center align-items-center">
      <img src="{{ selectedImage }}" alt="" width="450px" height="400px" />
    </div>
    <div class="d-flex flex-row gap-2 justify-content-center align-items-center">
      <button (click)="prevImage()" [disabled]="currentStartIndex === 0" class="transparent-button">
        &lt;
        <!-- Left arrow icon -->
      </button>
      <div *ngFor="
          let image of imageUrls.slice(
            currentStartIndex,
            currentStartIndex + 4
          );
          let i = index
        " class="border-black" (click)="selectImage(image)" role="button">
        <img src="{{ image }}" alt="" width="80px" height="80px" />
      </div>
      <button (click)="nextImage()" [disabled]="currentStartIndex + 4 >= imageUrls.length" class="transparent-button"
        *ngIf="imageUrls">
        &gt;
        <!-- Right arrow icon -->
      </button>
    </div>
  </div>
</ng-template>