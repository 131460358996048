export class UserAddressDTO {
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  email: string = '';
  firstLine: string = '';
  secondLine: string = '';
  landmark: string = '';
  city: string = '';
  district: string = '';
  state: string = '';
  country: string = '';
  pinCode: string = '';
  latitude: string = '';
  longitude: string = '';
  id: string = '';
}
