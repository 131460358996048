<div class="flex-column-reverse flex-lg-row d-flex p-lg-5 p-0 m-2 m-lg-5 justify-content-between gap-5">
  <div class="col-lg-3 col-12">
    <div class="col-12 cart-price-details d-flex justify-content-between flex-column border-grey rounded-2">
      <div class="font20 fw400 text-white bg-grey px-4 py-3 rounded-top-2 mb-4 zindex2">
        Price Details
      </div>
      <div class="font20 fw400 px-4 py-1 rounded4 mb-1 d-flex justify-content-between">
        <div>Price Details {{ numberOfItems }} items</div>
        <div>₹ {{ cartList[0].total_cart_price | number : "1.2-2" }}</div>
      </div>
      <div class="font20 fw400 px-4 py-1 rounded4 mb-1 d-flex justify-content-between">
        <div>Delivery Charges</div>
        <div class="font18 font-primary">Free</div>
      </div>
      <div class="px-4 py-0 mb-2">
        <hr />
      </div>
      <div class="font20 fw400 px-4 py-1 rounded4 mb-4 font-primary d-flex justify-content-between">
        <div>Total Amount</div>
        <div>₹ {{ cartList[0].total_cart_price | number : "1.2-2" }}</div>
      </div>

      <button [disabled]="!isCodSelected || loading" (click)="createOrder()"
        class="bordernone font20 fw400 px-4 py-2 rounded4 mb-5 bgorange text-white text-center mx-4">
        PLACE ORDER
      </button>
    </div>
  </div>
  <div class="col-lg-9 col-12">
    <div class="container">
      <div class="mt-lg-0 mt-2 text-blue-1 font16 shipping-address" *ngIf="userDTO.address">
        <div class="d-flex">
          <div class="col-lg-6">
            <h2 class="font24 fw-700 mb-1">Delivery Address</h2>
          </div>
          <a class="font-primary col-lg-6 text-end" role="button" href="/cart/address">Change</a>
        </div>
        <div class="font16 fw-400">
          <span>{{ selectedAddress.userName }},</span>
          <span class="">{{ selectedAddress.firstName }}
            {{ selectedAddress.lastName }}</span>
          <span class="">
            {{ selectedAddress.firstLine }},
            {{ selectedAddress.secondLine }}
          </span>
          <span class="">
            {{ selectedAddress.city }}, {{ selectedAddress.district }},
            {{ selectedAddress.state }}, {{ selectedAddress.country }}
          </span>
          <span class="">{{ selectedAddress.pinCode }}</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="bg-grey text-white px-5 mb-3 mt-4 py-3">
        <span class="font20 fw-400">Select A Payment Method</span>
      </div>
      <div class="payment-list">
        <!-- Credit or Debit Card -->
        <div class="payment-option px-5 mt-5 mb-5" (click)="selectPaymentMethod('card')" *ngIf="showCard">
          <div class="card py-3" [ngClass]="{ 'selected-payment': selectedPayment === 'card' }">
            <div class="card-body d-flex">
              <div class="align-self-start">
                <input type="radio" name="payment" [(ngModel)]="selectedPayment" value="card"
                  class="radio-input mt-1" />
              </div>
              <div class="payment-details col-5">
                <span class="font20 fw400">Credit Or Debit Card</span>
                <p class="mb-0">+ Enter Card Details</p>
                <p class="mb-0">PowerBihar accepts all major credit & cards</p>
              </div>
              <div class="payment-icons col-7 d-flex justify-content-around">
                <img src="../../assets/visa.png" alt="Visa" />
                <img src="../../assets/mastercard.png" alt="MasterCard" />
                <img src="../../assets/debitcard.png" alt="DebitCard" />
                <img src="../../assets/3dcreditcard.png" alt="RuPay" />
                <img src="../../assets/creditcard.png" alt="CreditCrad" />
              </div>
            </div>
          </div>
        </div>

        <!-- Net Banking -->
        <div class="payment-option px-5 mt-5 mb-5" (click)="selectPaymentMethod('netbanking')" *ngIf="showBank">
          <div class="card py-3" [ngClass]="{ 'selected-payment': selectedPayment === 'netbanking' }">
            <div class="card-body d-flex align-items-center">
              <div class="align-self-start">
                <input type="radio" name="payment" [(ngModel)]="selectedPayment" value="netbanking"
                  class="radio-input mt-1" />
              </div>
              <div class="payment-net">
                <span class="font20 fw400">Net Banking</span>
                <select class="form-select">
                  <option>HDFC Bank</option>
                  <option>SBI</option>
                  <option>ICICI Bank</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Other UPI Apps -->
        <div class="payment-option px-5 mt-5 mb-5" (click)="selectPaymentMethod('upi')" *ngIf="showUPI">
          <div class="card py-3" [ngClass]="{ 'selected-payment': selectedPayment === 'upi' }">
            <div class="card-body d-flex align-items-center">
              <input type="radio" name="payment" [(ngModel)]="selectedPayment" value="upi" class="radio-input mt-1" />
              <div class="payment-details">
                <span class="font20 fw400">Other UPI Apps</span>
                <p class="mb-0 py-2">Please enter your UPI ID</p>
                <div class="upi-input">
                  <input type="text" class="form-control" placeholder="Enter UPI ID" />
                  <button class="btn btn-warning">Verify</button>
                </div>
                <p class="font13 fw400 mb-0 mt-3">
                  The UPI ID Is In The Format Of Name/Phone Number&#64;BankName
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Cash On Delivery/Pay On Delivery -->
        <div class="payment-option px-5 mt-5 mb-5" (click)="selectPaymentMethod('COD')">
          <div class="card py-3" [ngClass]="{ 'selected-payment': selectedPayment === 'COD' }">
            <div class="card-body d-flex align-items-center">
              <input type="radio" name="payment" [(ngModel)]="selectedPayment" value="COD" class="radio-input mt-1" />
              <div class="payment-details">
                <span class="font20 fw400">Cash On Delivery/Pay On Delivery</span>
                <p class="font13 fw400 mb-0 mt-3">
                  Cash, UPI and Cards Accepted
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>