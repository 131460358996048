<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage">
  <!-- {{ header }} -->
  Your Orders
</div>

<div class="px-5 mx-lg-5 mx-0 mt-5">
  <!-- <div class="d-flex col-12 mt-5">
    <div class="col-7 d-flex align-items-center">
      <div class="font32 fw400 font-black">Your Orders</div>
    </div>
    <div class="col-5 d-flex">
      <div class="col-7">
        <div class="col-lg-12 col-8 rounded12 d-flex search-container">
          <img src="../../assets/leftsearch.png" class="search-icon" />
          <input type="text" placeholder="Search All Orders" name="search"
            class="ms-2 col-11 rounded12 search-bg search-input" />
        </div>
      </div>
      <div class="col-5 d-flex align-items-center ps-lg-4 ps-2 justify-content-end">
        <button class="bgorange text-white search-button">Search Orders</button>
      </div>
    </div>
  </div> -->
  <div class="">
    <div class="d-flex justify-content-lg-between justify-content-center align-items-center flex-lg-row flex-column">
      <div class="order-tabs col-lg-7 col-12">
        <button class="tab-button" (click)="onStatusChange('live')" [ngClass]="{ active: currentStatus === 'live' }">
          Live
        </button>
        <button class="tab-button" (click)="onStatusChange('cancelled')"
          [ngClass]="{ active: currentStatus === 'cancelled' }">
          Cancelled
        </button>
        <button class="tab-button" (click)="onStatusChange('closed')"
          [ngClass]="{ active: currentStatus === 'closed' }">
          Closed
        </button>
      </div>
      <div class="col-lg-5 col-12">
        <div class="d-flex flex-lg-row flex-column">
          <div class="col-lg-7 col-12">
            <div class="col-lg-12 col-12 my-lg-0 my-3 rounded12 d-flex search-container">
              <img src="../../assets/leftsearch.png" class="search-icon" />
              <input type="text" placeholder="Search All Orders" name="search"
                class="ms-2 col-11 rounded12 search-bg search-input" [(ngModel)]="searchTerm"
                (ngModelChange)="filterOrders()" />
            </div>
          </div>
          <div class="col-lg-5 col-12 d-lg-flex d-none align-items-center ps-lg-4 ps-2 justify-content-end">
            <button class="bgorange text-white search-button" (click)="filterOrders()">
              Search Orders
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="order-list-container">
      <div *ngFor="let order of paginatedProducts" class="order-item">
        <div
          class="order-header d-flex align-items-lg-center align-items-start justify-content-lg-between justify-content-start px-lg-5 px-3 col-12 py-3 flex-lg-row flex-column">
          <span class="font16 fw400 font-primary">
            {{ order.orderStatus }}
            <p class="mb-0 font-black">
              Order Date: {{ order.orderReceivedAt | date : "d MMMM yyyy" }}
            </p>
          </span>
          <p class="mb-0 font-black font16 fw400">
            Total:
            <span class="font24">₹{{ order.totalPrice | number : "1.2-2" }}</span>
          </p>
          <span class="font-black font16 fw400 d-none d-lg-flex">Ship To
            <p class="mb-0">
              {{ order.address.shippingAddressDto.firstName }},
              {{ order.address.shippingAddressDto.lastName }}
            </p>
          </span>
          <span class="font-black font16 fw400 d-flex d-lg-none">Ship To
            {{ order.address.shippingAddressDto.firstName }},
            {{ order.address.shippingAddressDto.lastName }}
          </span>
          <span class="font-black font16 fw400">Order # {{ order.orderIdentifier }}</span>
        </div>

        <div class="d-flex justify-content-between px-lg-5 px-3 py-3 align-items-center">
          <span>Arriving on:
            <p class="mb-0 font-primary">
              {{ order.arrivalDate | date : "MMM-dd-yyyy" }}
            </p>
          </span>
          <!-- <p>Status: {{ order.orderStatus }}</p> -->
          <span class="font-black font16 fw400 hovertorange" role="button"
            (click)="goToProductDetails(order.product.product.id)">Write A Product Review</span>
        </div>

        <div class="d-flex px-lg-5 px-1 mb-5 justify-content-center flex-lg-row flex-column align-items-center">
          <div
            class="d-flex col-lg-9 col-12 gap-1 justify-content-lg-between justify-content-center align-items-center flex-lg-row flex-column">
            <div class="col-lg-2 col-12 d-flex justify-content-center justify-content-lg-start">
              <!-- <div *ngFor="let img of order.image"> -->
              <img *ngIf="order.image?.length > 0" [src]="order.image[0].imagePublicUrl"
                alt="{{ order.image[0].imageName }}" class="order-image img-fluid" width="100" />
              <!-- </div> -->
            </div>
            <div
              class="col-lg-5 col-12 two-line-text justify-content-center justify-content-lg-start d-flex align-items-center">
              {{ order.product.product.productHeading }}
            </div>

            <div class="col-lg-4 col-12 d-flex justify-content-center flex-lg-column">
              <div class="">
                <div class="stars-bordered">
                  <span [ngClass]="{
                      rating0: order.ratings.overallRating === 0,
                      'rating0-5': order.ratings.overallRating === 0.5,
                      rating1: order.ratings.overallRating === 1,
                      'rating1-5': order.ratings.overallRating === 1.5,
                      rating2: order.ratings.overallRating === 2,
                      'rating2-5': order.ratings.overallRating === 2.5,
                      rating3: order.ratings.overallRating === 3,
                      'rating3-5': order.ratings.overallRating === 3.5,
                      rating4: order.ratings.overallRating === 4,
                      'rating4-5': order.ratings.overallRating === 4.5,
                      rating5: order.ratings.overallRating === 5
                    }"></span>
                </div>
                <span class="font16 fw400 ps-3">{{
                  order.ratings.overallRating | number : "1.1-1"
                  }}</span>
              </div>
              <div class="mt-lg-0 mt-0 ps-3 ps-lg-0 mb-4 mb-lg-0">{{ order.ratings.totalRating }} Ratings</div>
            </div>
          </div>
          <div class="col-lg-3 col-12 flex-column d-flex justify-content-center">
            <div class="d-flex align-items-lg-end justify-content-lg-end justify-content-center">
              <button *ngIf="allBuyerOrder" class="col-6 bgorange track-package text-white py-3"
                (click)="navigateToOrderTracking(order.orderIdentifier)">
                Track Package
              </button>
            </div>
            <div class="d-flex align-items-lg-end justify-content-lg-end justify-content-center mt-2">
              <button class="col-6 track-package bgyellow text-white py-3" *ngIf="order.invoiceUrl">
                <a href="{{ order.invoiceUrl }}" target="_blank" style="
                    text-decoration: none !important;
                    color: white !important;
                  ">Download Invoice</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 justify-content-center d-flex mb-5" *ngIf="paginatedProducts.length > 0">
    <div class="d-flex gap-2 justify-content-center align-items-center">
      <button (click)="previousPage()" [disabled]="currentPage === 1" class="bordernone bg-transparent">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
          <path
            d="M0.560453 8.45071C-0.108387 8.06959 -0.113845 7.10736 0.550627 6.71868L11.7423 0.172246C12.4068 -0.216431 13.2428 0.259959 13.2472 1.02975L13.3207 13.9952C13.3251 14.765 12.4945 15.2509 11.8257 14.8697L0.560453 8.45071Z"
            fill="#414040" />
        </svg>
      </button>
      <span *ngFor="let i of pageArray">
        <span role="button" (click)="goToPage(i)" class="index-page"
          [ngClass]="{ 'active-index': i === currentPage }">{{ i === -1 ? "..." : i }}
        </span></span>
      <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="bordernone bg-transparent">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
          <path
            d="M13.5004 6.62451C14.1683 7.00726 14.1714 7.9695 13.506 8.35655L2.29834 14.8755C1.63292 15.2626 0.79804 14.7841 0.79556 14.0143L0.753788 1.04873C0.751308 0.278929 1.58309 -0.204878 2.25099 0.177872L13.5004 6.62451Z"
            fill="#414040" />
        </svg>
      </button>
    </div>
  </div>
</div>