import { Component, OnInit } from '@angular/core';
// import { ProductService } from '../services/product.service';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-category-carousel',
  templateUrl: './category-carousel.component.html',
  styleUrls: ['./category-carousel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CategoryCarouselComponent implements OnInit {
  goToCategory(category: any) {
    // console.log(category);
    this.router.navigate(['/category', category]);
  }
  catList: any[] = [];
  filteredMenu: any;
  constructor(private productService: ProductService, private router: Router) {}
  ngOnInit(): void {
    this.getAllCategory();
    // this.autoScroll();
    // this.filterAvailableMenu();
  }


 
  getAllCategory() {
    this.productService.getAllCategory().subscribe((data) => {
      this.catList = data;
      // console.log(data);
      // console.log(this.filterMenu);
    });
  }
}
