<div class="position-relative col-12 d-flex align-items-center">
  <!-- Left Arrow Button -->
  <button class="scroll-btn left-arrow" (click)="scrollLeft()">
    &#10094;
  </button>

  <!-- Scrollable Product List -->
  <div class="gap-3 d-flex col-12 pb-lg-5 scrollDiv justify-content-around overflow-hidden">
    <div *ngFor="let product of newProduct"
      class="product-card col-lg-3 col-12 justify-content-center mb-4 mb-lg-0 d-flex flex-column" role="button"
      (click)="navigateToProductDetails(product.productId)">
      <div class="product-card-header">
        <div class="image-placeholder">
          <img [src]="product.productImageList[0]?.imagePublicUrl || '/assets/default-image.png'"
            [alt]="product.productHeading" class="product-image" />
          <div class="bgyellow discount-badge" style="position: absolute;" *ngIf="product.discountAmount > 0">
            {{ product.discountAmount / product.mrp | percent: '1.0-0' }}
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-center align-items-center flex-column gap-2">
        <div class="my-3 justify-content-center align-items-center justify-content-lg-between d-flex"
          *ngIf="product.avgRatings">
          <div class="rating-stars">
            <div class="star" *ngFor="let star of starsArray; let i = index" [ngClass]="{
                filled: i < getFloor(product.avgRatings.overallRating),
                'half-filled': i >= getFloor(product.avgRatings.overallRating) && i < product.avgRatings.overallRating
              }">
              ★
            </div>
          </div>
        </div>
        <div class="font15 fw400 d-flex justify-content-center align-items-center" style="min-height: 50px;">
          {{ product.productHeading.slice(0, 35) }}
        </div>
        <span class="discounted-price font24 fw400">₹{{ product.finalAmtWithTax | number: '1.2-2' }}</span>
        <span class="original-price font12 fw400">MRP: ₹{{ product.mrp | number: '1.2-2' }}</span>
      </div>
    </div>
  </div>

  <!-- Right Arrow Button -->
  <button class="scroll-btn right-arrow" (click)="scrollRight()">
    &#10095;
  </button>
</div>