import { Component } from '@angular/core';

@Component({
  selector: 'app-createaccount',
  standalone: true,
  imports: [],
  templateUrl: './createaccount.component.html',
  styleUrl: './createaccount.component.scss'
})
export class CreateaccountComponent {

}
