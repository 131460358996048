import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-countdown',
  standalone: true,
  imports: [],
  templateUrl: './countdown.component.html',
  styleUrl: './countdown.component.scss',
})
export class CountdownComponent implements OnInit {
  public countdown = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  };
  subscription: Subscription | undefined;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.startCountdown();
    }
  }

  private startCountdown() {
    this.subscription = interval(1000).subscribe(() => {
      const now = new Date();
      const nextSunday = this.getNextSunday(now);

      const timeDifference = nextSunday.getTime() - now.getTime();

      this.countdown = this.getTimeRemaining(timeDifference);
    });
  }

  private getNextSunday(date: Date): Date {
    const dayOfWeek = date.getDay();
    const nextSunday = new Date(date);

    nextSunday.setDate(date.getDate() + ((7 - dayOfWeek) % 7));

    nextSunday.setHours(0, 0, 0, 0);
    return nextSunday;
  }

  private getTimeRemaining(timeDifference: number) {
    const seconds = Math.floor((timeDifference / 1000) % 60)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60)
      .toString()
      .padStart(2, '0');
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24)
      .toString()
      .padStart(2, '0');
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(2, '0');

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
