<div
  class="flex-column-reverse flex-lg-row d-flex p-lg-5 p-0 m-2 m-lg-5 justify-content-between gap-5"
>
  <div class="col-lg-3 col-12">
    <div
      class="col-12 cart-price-details d-flex justify-content-between flex-column"
    >
      <div
        class="font20 fw400 text-white bg-grey px-4 py-3 rounded-top-2 mb-4 zindex2"
      >
        Price Details
      </div>
      <div
        class="font20 fw400 px-4 py-1 rounded4 mb-1 d-flex justify-content-between"
      >
        <div>Price Details {{ numberOfItems }} items</div>
        <div>₹ {{ cartItems[0].total_cart_price | number : "1.2-2" }}</div>
      </div>
      <div
        class="font20 fw400 px-4 py-1 rounded4 mb-1 d-flex justify-content-between"
      >
        <div>Delivery Charges:</div>
        <div class="font18 font-primary">
          <span
            *ngIf="cartItems[0].deliveryFee == 0"
            class="font-strike text-light-blue font14"
            >₹ {{ deliveryFee }}</span
          >
          ₹ {{ cartItems[0].deliveryFee | number : "1.2-2" }}
        </div>
      </div>
      <div class="px-4 py-0 mb-2">
        <hr />
      </div>
      <div
        class="font20 fw400 px-4 py-1 rounded4 mb-4 font-primary d-flex justify-content-between"
      >
        <div>Total Amount</div>
        <div>
          ₹
          {{
            cartItems[0].total_cart_price + cartItems[0].deliveryFee
              | number : "1.2-2"
          }}
        </div>
      </div>
      <button
        class="font20 fw400 px-4 py-2 rounded4 mb-5 bgorange text-white text-center mx-4 bordernone"
        [disabled]="unAvailableItem"
        (click)="proceedToCheckout()"
        role="button"
      >
        DELIVER TO THIS ADDRESS
      </button>
    </div>
  </div>
  <div class="col-lg-9 col-12">
    <div class="container">
      <h4>Select A Delivery Address</h4>
      <div class="address-list">
        <div
          *ngFor="let address of addressDTO; let i = index"
          class="address-wrapper mt-3"
        >
          <div
            class="card py-3"
            [ngClass]="{ 'selected-address': selectedAddress === i }"
            (click)="selectAddress(i)"
          >
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <input
                type="radio"
                [name]="'address-' + i"
                [checked]="selectedAddress === i"
                [disabled]="address.deliveryTime < 0 || address.nonDeliverable"
              />
              <div class="address-details">
                <p class="mb-0">
                  {{ address.firstName }} {{ address.lastName }},
                  {{ address.firstLine }},{{ address.city }},
                  {{ address.state }}, {{ address.pinCode }},
                  {{ address.country }}
                  {{ address.unAvailableItem }}
                </p>
                <p
                  *ngIf="address.deliveryTime >= 0 && !address.nonDeliverable"
                  class="mb-0 font12 font-primary"
                >
                  Delivery by {{ formatDate(address.deliveryTime) }}
                </p>
                <p
                  *ngIf="address.deliveryTime < 0 && !address.unAvailableItem"
                  class="mb-0 font12 text-danger"
                >
                  Address not Serviceable currently; Kindly Contact Customer
                  Care for further details.
                </p>
                <p
                  *ngIf="address.deliveryTime >= 0 && address.nonDeliverable"
                  class="mb-0 font12 text-danger"
                >
                  Some or all items are not deliverable to this address. Kindly
                  review
                  <a href="/cart" class="font16 font-primary"> Cart Items</a> .
                </p>
              </div>
            </div>
          </div>
          <span class="address-number">{{ i + 1 }}</span>
        </div>

        <div
          role="button"
          (click)="openAddress()"
          class="add-new-address text-danger"
        >
          + Add A New Delivery Address
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template class="modal fade center p-3" #addAddress>
  <app-add-address class="p-4 text-center"></app-add-address>
</ng-template>
