import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-card-sale',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-card-sale.component.html',
  styleUrl: './product-card-sale.component.scss',
})
export class ProductCardSaleComponent implements OnInit, AfterViewInit {
  wishlistIcon = '/assets/fav.png'; // Default icon
  newProduct: any[] = [];
  starsArray = [1, 2, 3, 4, 5];

  constructor(
    private productService: ProductService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.getNewArrivals();
    // this.user();
  }
  navigateToProductDetails(id: any) {
    this.router.navigate([`/product/${id}`]);
  }
  getFloor(arg0: any) {
    return Math.floor(arg0);
  }

  getNewArrivals() {
    this.productService.getNewArrivals().subscribe(
      (data) => {
        // console.log('Data received:', data);

        if (data.productHeading) {
          data.productHeading = data.productHeading.slice(0, 10); // Slice the first 20 characters (index 0-19)
        }
        this.newProduct = data;
        // this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching new arrivals:', error);
        // this.isLoading = false; // Set loading to false in case of error
      }
    );
  }

  toggleWishlist(product: any): void {
    product.wishlistIcon =
      product.wishlistIcon === '/assets/fav.png'
        ? '/assets/red-fav.png'
        : '/assets/fav.png';
  }

  scrollContainer: HTMLElement | null = null;

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollContainer = document.querySelector('.scrollDiv');
    }
  }

  scrollLeft() {
    if (this.scrollContainer) {
      this.scrollContainer.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }

  scrollRight() {
    if (this.scrollContainer) {
      this.scrollContainer.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }
}
