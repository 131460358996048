import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { UsersService } from '../../services/user.service';
import { AuthService } from '../auth-service.service';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from '../../services/local-storage.service';
import { AddAddressComponent } from '../add-address/add-address.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-address',
  standalone: true,
  imports: [CommonModule, AddAddressComponent],
  templateUrl: './cart-address.component.html',
  styleUrl: './cart-address.component.scss',
})
export class CartAddressComponent implements OnInit {
  @ViewChild('addAddress') modal: any;
  totalQuantity: any;
  userDTO: any;
  isSeller: any;
  cartAvailability: any;
  unAvailableItem: boolean = false;
  quantity: any;
  cartItems: any[] = [];
  numberOfItems: any;

  addressDTO: any;
  selectedAddress: number = 0;
  pinCode: any;
  cartAvailabilityForPin: any;
  deliveryFee: any;
  constructor(
    private cartService: CartService,
    private router: Router,
    private userService: UsersService,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.pinCode = this.localStorage.getItem('pinCode');
    this.allAddress();
    this.getCartList();
    this.getDeliveryFee();
  }
  getDeliveryFee() {
    this.cartService.getDeliveryFee(this.pinCode).subscribe((data) => {
      this.deliveryFee = data;
    });
  }

  getCartList() {
    this.cartService.getCartList().subscribe((data) => {
      this.cartItems = data;
      this.numberOfItems = data.length;
      this.unAvailableItem = false;
      this.localStorage.setItem(
        'pinCode',
        this.addressDTO[this.selectedAddress].pinCode
      );
      // console.log(this.addressDTO[this.selectedAddress].pinCode);
      this.cartService.checkAvailability().subscribe((data) => {
        this.cartAvailability = data;
        console.log(this.cartAvailability);
        for (let i = 0; i < this.cartAvailability.length; i++) {
          if (
            this.cartAvailability[i].cartIdentifier ==
            this.cartItems[i].identifier
          ) {
            this.cartItems[i].isAvailable =
              this.cartAvailability[i].isAvailable;
          }
          if (
            !this.cartAvailability[i].isAvailable ||
            this.selectedAddress < 0
          ) {
            this.unAvailableItem = true;
          }
        }
      });
    });
  }

  proceedToCheckout() {
    console.log('Cart Data before navigation:', this.cartItems);
    // this.cartStateService.setCartData(this.cartList);
    // this.loading = true;
    if (!this.isLoggedIn()) {
      this.router.navigate(['/login']);
      return;
    }

    // Fetch user details
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;

      // Check if user details are complete
      if (!this.hasCompleteUserDetails()) {
        this.showProfileIncompleteModal();
        return;
      }

      this.cartService.checkAvailability().subscribe((data) => {
        this.cartAvailability = data;
        // console.log(this.cartAvailability);
        for (let i = 0; i < this.cartAvailability.length; i++) {
          if (
            this.cartAvailability[i].cartIdentifier ==
            this.cartItems[i].identifier
          ) {
            this.cartItems[i].isAvailable =
              this.cartAvailability[i].isAvailable;
          }
          if (!this.cartAvailability[i].isAvailable) {
            this.unAvailableItem = true;
          }
        }
        // this.loading = false;
        // console.log(this.cartItems);
        if (!this.unAvailableItem) {
          const selectedAddressId = this.addressDTO[this.selectedAddress].id;
          this.router.navigate(['/cart/payment'], {
            queryParams: { addressId: selectedAddressId },
          });
        } else {
          alert(
            'Some Items are not deliverable to you default shipping address. Kindly remove them to proceed.'
          );
        }
      });
    });
  }
  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
  user() {
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;
    });
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.userDTO;
    return phoneNumber;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }

  // navigateToProfile() {
  //   this.router.navigate(['/profile']);
  // }

  allAddress() {
    this.userService.getAllAddress().subscribe((data) => {
      this.addressDTO = data;
      let matchedPinCode = false;
      for (let i = 0; i < this.addressDTO.length; i++) {
        // console.log(this.addressDTO[i]);

        this.cartService
          .getDeliveryTime(this.addressDTO[i].pinCode)
          .subscribe((resp) => {
            this.addressDTO[i].deliveryTime = resp;
          });
        this.cartService
          .checkAvailabilityByPinCode(this.addressDTO[i].pinCode)
          .subscribe((response) => {
            this.cartAvailabilityForPin = response;
            for (let j = 0; j < this.cartAvailabilityForPin.length; j++) {
              if (!this.cartAvailabilityForPin[j].isAvailable) {
                this.addressDTO[i].nonDeliverable = true;
              } else {
                this.addressDTO[i].deliverable = true;
              }
            }
            console.log(this.addressDTO[i]);
          });
        if (this.pinCode === this.addressDTO[i].pinCode) {
          this.selectedAddress = i;
          matchedPinCode = true;
        }
      }
      if (!matchedPinCode) {
        this.selectedAddress = -1;
        this.unAvailableItem = true;
      }
    });
    console.log(this.addressDTO);
  }

  selectAddress(index: number): void {
    this.selectedAddress = index;
    this.getCartList();
  }
  openAddress() {
    this.modalService.open(this.modal);
  }
  formatDate(numberOfDays: any) {
    const today = new Date();

    // Add 7 days
    const deliveryDate = new Date(today);
    deliveryDate.setDate(deliveryDate.getDate() + numberOfDays);
    return deliveryDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
  addBusinessDays(date: Date, daysToAdd: number): Date {
    let businessDaysAdded = 0;
    let newDate = new Date(date); // Create a copy of the input date

    while (businessDaysAdded < daysToAdd) {
      newDate.setDate(newDate.getDate() + 1); // Move to the next day

      // Check if it's not Saturday (6) or Sunday (0)
      if (newDate.getDay() !== 6 && newDate.getDay() !== 0) {
        businessDaysAdded++;
      }
    }

    return newDate;
  }
}
