<div class="countdown">
    <div class="countdown-item">
      <span>{{ countdown.days }}</span> Days
    </div>
    <div class="countdown-item">
      <span>{{ countdown.hours }}</span> Hours
    </div>
    <div class="countdown-item">
      <span>{{ countdown.minutes }}</span> Minutes
    </div>
    <div class="countdown-item">
      <span>{{ countdown.seconds }}</span> Seconds
    </div>
  </div>
  