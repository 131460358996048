import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProductService } from '../../services/product.service';
import { CacheService } from './cache.service';
import { WishlistService } from '../../services/wishlist.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class NewProductResolver implements Resolve<any> {
  private readonly CACHE_EXPIRATION = 30 * 60 * 1000; // 30 minutes
  private readonly NEW_ARRIVALS_CACHE_KEY = 'newArrivals';
  private readonly BEST_SELLERS_CACHE_KEY = 'bestSellers';
  private readonly ALL_PRODUCTS_CACHE_KEY = 'allProducts';
  private readonly CATEGORY_PRODUCTS_CACHE_KEY = 'category';
  private readonly SUBCATEGORY_PRODUCTS_CACHE_KEY = 'subcategory';
  private readonly BRANDS_CACHE_KEY = 'brands';

  constructor(
    private productService: ProductService,
    private cacheService: CacheService,
    private wishlistService: WishlistService,
    private loadingService: LoadingService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.loadingService.show();

    const path = route.routeConfig?.path;
    let productObservable: Observable<any>;

    if (path === 'newproducts') {
      productObservable = this.getCachedDataOrFetchAsync(
        this.NEW_ARRIVALS_CACHE_KEY,
        this.productService.getNewArrivals()
      );
    } else if (path === 'bestseller') {
      productObservable = this.getCachedDataOrFetchAsync(
        this.BEST_SELLERS_CACHE_KEY,
        this.productService.getBestSeller()
      );
    } else if (path === 'allproducts') {
      productObservable = this.getCachedDataOrFetchAsync(
        this.ALL_PRODUCTS_CACHE_KEY,
        this.productService.getAllProducts()
      );
    } else if (path?.startsWith('category/')) {
      const category = route.params['category'];
      productObservable = this.getCachedDataOrFetchAsync(
        `${this.CATEGORY_PRODUCTS_CACHE_KEY}_${category}`,
        this.productService.getProductsByCategory(category)
      );
    } else if (path?.startsWith('subcategory/')) {
      const subcategory = route.params['subcategory'];
      productObservable = this.getCachedDataOrFetchAsync(
        `${this.SUBCATEGORY_PRODUCTS_CACHE_KEY}_${subcategory}`,
        this.productService.getProductsBySubCategory(subcategory)
      );
    } else if (path?.startsWith('brand/')) {
      const brand = route.params['brandId'];
      productObservable = this.getCachedDataOrFetchAsync(
        `${this.BRANDS_CACHE_KEY}_${brand}`,
        this.productService.getProductByBrand(brand)
      );
    } else if (path === 'product/:productid/:listingId') {
      const productId = route.params['productid'];
      const listingId = route.params['listingId'];
      productObservable = this.productService.getProductById(
        productId,
        listingId
      );
    } else {
      this.loadingService.hide();
      return of(null);
    }

    return forkJoin({
      products: productObservable,
      wishlistedProducts: this.wishlistService.getWishlist().pipe(
        catchError(() => of([])) // Handle cases where wishlist is unavailable
      ),
    }).pipe(
      map(({ products, wishlistedProducts }) => {
        const productsArray = Array.isArray(products) ? products : [products];

        const wishlistIds = wishlistedProducts
          ? wishlistedProducts.map(
              (wl: { productDetailDto: { productId: any } }) =>
                wl.productDetailDto.productId
            )
          : [];
        this.loadingService.hide(); // Hide only once after forkJoin completes
        // return products.map((product: { productId: any }) => ({
        //   ...product,
        return productsArray.map((product: { productId: any }) => ({
          ...product,
          wishlisted: wishlistIds.includes(product.productId),
        }));
      }),
      catchError((error) => {
        console.error('Error fetching data:', error);
        this.loadingService.hide(); // Ensure hiding loading on error
        return of([]); // Return an empty array instead of retrying
      })
    );
  }

  private getCachedDataOrFetchAsync(
    cacheKey: string,
    apiCall: Observable<any>
  ): Observable<any> {
    const cachedData = this.cacheService.get<any>(cacheKey);
    if (cachedData && this.isCacheValid(cachedData.timestamp)) {
      return of(cachedData.data);
    }

    return apiCall.pipe(
      map((data) => {
        // Adjust here if data contains the array in a property (e.g., data.products)
        const productsArray = Array.isArray(data) ? data : data.products || [];
        this.cacheService.set(cacheKey, { data, timestamp: Date.now() });
        return productsArray;
      }),
      catchError((error) => {
        console.error(`Error fetching ${cacheKey}:`, error);
        return of([]); // Return empty array to prevent breaking the resolver
      })
    );
  }

  private isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < this.CACHE_EXPIRATION;
  }
}

// import { Injectable } from '@angular/core';
// import {
//   Resolve,
//   ActivatedRouteSnapshot,
//   RouterStateSnapshot,
//   ActivatedRoute,
// } from '@angular/router';
// import { Observable, forkJoin, of } from 'rxjs';
// import { switchMap, map, catchError } from 'rxjs/operators';
// import { ProductService } from '../../services/product.service';
// import { CacheService } from './cache.service';
// import { WishlistService } from '../../services/wishlist.service';
// import { LoadingService } from '../loading/loading.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class NewProductResolver implements Resolve<any> {
//   private readonly CACHE_EXPIRATION = 30 * 60 * 1000; // 30 minutes in milliseconds
//   private readonly NEW_ARRIVALS_CACHE_KEY = 'newArrivals';
//   private readonly BEST_SELLERS_CACHE_KEY = 'bestSellers';
//   private readonly ALL_PRODUCTS_CACHE_KEY = 'allProducts';
//   private readonly CATEGORY_PRODUCTS_CACHE_KEY = 'category';
//   private readonly SUBCATEGORY_PRODUCTS_CACHE_KEY = 'subcategory';
//   private readonly BRANDS_CACHE_KEY = 'brands';
//   category: any;

//   constructor(
//     private productService: ProductService,
//     private cacheService: CacheService,
//     private router: ActivatedRoute,
//     private wishlistService: WishlistService,
//     private loadingService: LoadingService
//   ) {}

//   resolve(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<any> {
//     this.loadingService.show();
//     const path = route.routeConfig?.path;
//     let productObservable: Observable<any>;

//     if (path === 'newproducts') {
//       productObservable = this.getCachedDataOrFetchAsync(
//         this.NEW_ARRIVALS_CACHE_KEY,
//         this.productService.getNewArrivals()
//       );
//       // this.loadingService.hide();
//     } else if (path === 'bestseller') {
//       productObservable = this.getCachedDataOrFetchAsync(
//         this.BEST_SELLERS_CACHE_KEY,
//         this.productService.getBestSeller()
//       );
//       // this.loadingService.hide();
//     } else if (path === 'allproducts') {
//       productObservable = this.getCachedDataOrFetchAsync(
//         this.ALL_PRODUCTS_CACHE_KEY,
//         this.productService.getAllProducts()
//       );
//       // this.loadingService.hide();
//     } else if (path === 'category/:category') {
//       const category = route.params['category'];
//       const catKey = `${this.CATEGORY_PRODUCTS_CACHE_KEY}_${category}`;
//       productObservable = this.getCachedDataOrFetchAsync(
//         catKey,
//         this.productService.getProductsByCategory(category)
//       );
//       // this.loadingService.hide();
//     } else if (path === 'subcategory/:subcategory') {
//       const subcategory = route.params['subcategory'];
//       const catKey = `${this.SUBCATEGORY_PRODUCTS_CACHE_KEY}_${subcategory}`;
//       productObservable = this.getCachedDataOrFetchAsync(
//         catKey,
//         this.productService.getProductsBySubCategory(subcategory)
//       );
//       // this.loadingService.hide();
//     } else if (path === 'brand/:brandId') {
//       const brand = route.params['brandId'];
//       console.log(brand);
//       const catKey = `${this.BRANDS_CACHE_KEY}_${brand}`;
//       productObservable = this.getCachedDataOrFetchAsync(
//         catKey,
//         this.productService.getProductByBrand(brand)
//       );
//       // this.loadingService.hide();
//     } else if (path === 'product/:productid/:listingId') {
//       const brand = route.params['productid'];
//       const listing = route.params['listingId'];
//       console.log(brand);
//       const catKey = `${this.BRANDS_CACHE_KEY}_${brand}_${listing}`;
//       productObservable = this.getCachedDataOrFetchAsync(
//         catKey,
//         this.productService.getProductById(brand, listing)
//       );
//       // this.loadingService.hide();
//     } else {
//       // this.loadingService.hide();
//       return of(null); // Handle unknown routes
//     }

//     // Fetch the wishlisted products and combine them with the product API response
//     return forkJoin({
//       products: productObservable,
//       wishlistedProducts: this.wishlistService.getWishlist(),
//     }).pipe(
//       map(({ products, wishlistedProducts }) => {
//         // Extract product IDs from wishlist
//         const wishlistIds = wishlistedProducts.map(
//           (wl: { productDetailDto: { productId: any } }) =>
//             wl.productDetailDto.productId
//         );
//         // console.log(wishlistIds,products);
//         // Map wishlisted status to each product
//         // this.loadingService.hide(); // End loading
//         return products.map((product: { productId: any }) => ({
//           ...product,
//           wishlisted: wishlistIds.includes(product.productId), // Adjusted to match productId field
//         }));
//       }),
//       catchError((error) => {
//         // console.error('Error fetching wishlist:', error);
//         return productObservable; // Return an empty array or handle the error as needed
//       })
//     );
//   }

//   private getCachedDataOrFetchAsync(
//     cacheKey: string,
//     apiCall: Observable<any>
//   ): Observable<any> {
//     const cachedData = this.cacheService.get<any>(cacheKey);
//     if (cachedData && this.isCacheValid(cachedData.timestamp)) {
//       // console.log('Using cached data');
//       // this.loadingService.hide();
//       return of(cachedData.data);
//     } else {
//       return of(null).pipe(
//         switchMap(() => {
//           // console.log('Fetching new data asynchronously');
//           return apiCall.pipe(
//             map((data) => {
//               this.cacheService.set(cacheKey, { data, timestamp: Date.now() });
//               // this.loadingService.hide();
//               return data;
//             })
//           );
//         })
//       );
//     }
//   }

//   private isCacheValid(timestamp: number): boolean {
//     return Date.now() - timestamp < this.CACHE_EXPIRATION;
//   }
// }
