import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../app/auth-service.service';
import { environment } from '../environments/environment';
import { first, Observable } from 'rxjs';
import { OrderChatDTO } from '../app/dto/OrderChatDTO';
import { CreateOrderRequest } from '../app/dto/CreateOrderRequest';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private baseUrl = environment.API_URL + '/order/user';

  constructor(private http: HttpClient, private authService: AuthService) {}

  createOrder(orderModel: CreateOrderRequest): Observable<any> {
    const url = `${this.baseUrl}/createorder`;
    return this.http
      .post<any>(url, orderModel, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  placeOrder(orderIdentifier: string) {
    // console.log('Service received orderIdentifier:', orderIdentifier);
    const url = `${this.baseUrl}/place/${orderIdentifier}`;
    return this.http
      .post<any>(
        url,
        { orderIdentifier },
        {
          headers: this.authService.getAuthHeaders(),
        }
      )
      .pipe(first());
  }

  getOrderConfirmation(orderIdentifier: string) {
    const url = `${this.baseUrl}/confirmation/${orderIdentifier}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getOrderTracker(orderIdentifier: string) {
    const url = `${this.baseUrl}/trackorder/${orderIdentifier}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getOrderDetails(
    orderItemIdentifier: string,
    userType: string
  ): Observable<any> {
    const url = `${this.baseUrl}/getorderItem/${orderItemIdentifier}/${userType}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllBuyerOrder(
    pageNumber: number,
    pageSize: number = 1,
    sort: string = 'orderReceivedAt,desc,quantity,desc,totalPrice,desc'
  ) {
    const url = `${this.baseUrl}/getallorder/BUYER?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sort}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllBuyerOrderItem(status: 'live' | 'cancelled' | 'closed' = 'live') {
    const allowedStatuses: Array<'live' | 'cancelled' | 'closed'> = [
      'live',
      'cancelled',
      'closed',
    ];
    if (!allowedStatuses.includes(status)) {
      throw new Error(
        `Invalid status: ${status}. Allowed values are: ${allowedStatuses.join(
          ', '
        )}`
      );
    }

    const url = `${this.baseUrl}/getallorderitem/BUYER?status=${status}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllSellerOrderItem(status: 'live' | 'cancelled' | 'closed' = 'live') {
    const allowedStatuses: Array<'live' | 'cancelled' | 'closed'> = [
      'live',
      'cancelled',
      'closed',
    ];
    if (!allowedStatuses.includes(status)) {
      throw new Error(
        `Invalid status: ${status}. Allowed values are: ${allowedStatuses.join(
          ', '
        )}`
      );
    }

    const url = `${this.baseUrl}/getallorderitem/SELLER?status=${status}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllSellerOrder(
    pageNumber: number,
    pageSize: number = 1,
    sort: string = 'orderReceivedAt,desc,quantity,desc,totalPrice,desc'
  ) {
    const url = `${this.baseUrl}/getallorder/SELLER?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sort}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getCountOfBuyerOrder() {
    const url = `${this.baseUrl}/getallorder/BUYER/count`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getCountOfSellerOrder() {
    const url = `${this.baseUrl}/getallorder/SELLER/count`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  cancelOrder(orderIdentifier: string) {
    const url = `${this.baseUrl}/cancel/${orderIdentifier}`;
    return this.http
      .post<any>(
        url,
        { orderIdentifier },
        {
          headers: this.authService.getAuthHeaders(),
        }
      )
      .pipe(first());
  }
  returnOrder(orderIdentifier: string) {
    const url = `${this.baseUrl}/return/${orderIdentifier}`;
    return this.http
      .post<any>(
        url,
        { orderIdentifier },
        {
          headers: this.authService.getAuthHeaders(),
        }
      )
      .pipe(first());
  }
  getAllOrderMessage(id: string, userType: string) {
    const url = `${this.baseUrl}/getAllMessageByOrderItemAndUserType/${id}/${userType}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  deleteOrderMessage(id: string) {
    const url = `${this.baseUrl}/deleteMessageById/${id}`;
    return this.http
      .post<any>(url, id, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  saveOrderMessage(orderChatDTO: OrderChatDTO) {
    const url = `${this.baseUrl}/sendMessage`;
    return this.http
      .post<any>(url, orderChatDTO, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getOrderKPI() {
    const url = `${this.baseUrl}/getOrderKPI`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getSellerOrderKPI() {
    const url = `${this.baseUrl}/getSellerOrderKPI`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getSellerTopSellingKPI() {
    const url = `${this.baseUrl}/getSellerTopSellingKPI`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getBuyerTopBuyingKPI() {
    const url = `${this.baseUrl}/getBuyerTopBuyingKPI`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
}
