<div class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center  bgimage">
  {{ showNotifications ? "Notifications" : "My Account" }}
</div>
<div>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="darkorangecircle mt-5">
      <img src="../../assets/userdefault.png" class="img-center" />
    </div>
    <div class="font20 fw400 mt-2 d-flex flex-column justify-content-center align-items-center">
      <span> {{ userDTO.firstName + " " + userDTO.lastName }} </span>
      <span class="font15"> {{ userDTO.emailAddress }} </span>
      <span class="font15"> +91 - {{ userDTO.phoneNumber }} </span>
    </div>
  </div>
</div>
<div class="d-flex col-12 justify-content-center">
  <div class="body-border d-flex col-10 my-5 align-items-center justify-content-center flex-lg-row flex-column">
    <div class="col-lg-5 col-12 body-border-inside d-flex justify-content-center flex-column">
      <div class="navigateMenu" role="button" (click)="navigateToOrderDetails()">
        <img src="../../assets/orders.png" class="me-2" />My Orders
      </div>
      <a class="navigateMenu" href="/wishlist"><img src="../../assets/wishlist.png" class="me-2" />WishList</a>
      <div class="navigateMenu" role="button" (click)="navigateToDeliveryAddress()">
        <img src="../../assets/address.png" class="me-2" />Delivery Address
      </div>
      <a class="navigateMenu" href="/contactus"><img src="../../assets/help.png" class="me-2" />Need Help</a>
      <div class="navigateMenu" role="button" (click)="toggleNotifications()">
        <img src="../../assets/Notification.png" class="me-2" />Notifications
      </div>
      <div class="navigateMenu" role="button" (click)="signOut()">
        <img src="../../assets/Exit.png" class="me-2" />SignOut
      </div>
      <div class="navigateMenu">
        <img src="../../assets/accountbrandlogo.png" />
      </div>
    </div>
    <div class="col-lg-7 col-12 d-flex justify-content-center align-self-start flex-column">
      <div class="d-flex align-items-center flex-column font15 fw400">
        <ng-container *ngIf="!showNotifications">
          <span class="pt-5">Account Information</span>
          <div class="body-border col-8 mt-4">
            <ng-container *ngIf="!isEditMode">
              <div class="accountDetails flex-column">
                <span class="font13">Full Name</span>
                <span> {{ userDTO.firstName + " " + userDTO.lastName }} </span>
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">Email Id</span>
                <span> {{ userDTO.emailAddress }} </span>
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">Mobile Number</span>
                <span> +91 {{ userDTO.phoneNumber }} </span>
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">GST Number</span>
                <span> {{ userDTO.gstNumber }} </span>
              </div>
              <div class="accountDetails flex-column" *ngIf="userDTO.whatsappNo">
                <span class="font13">WhatsApp Number</span>
                <span> +91 {{ userDTO.whatsappNo }} </span>
              </div>

              <div class="accountDetails flex-column">
                <span class="font13">Address</span>
                <span style="text-transform: capitalize">{{
                  userDTO.address.firstLine +
                  ", " +
                  userDTO.address.secondLine +
                  ", " +
                  userDTO.address.city +
                  ", " +
                  userDTO.address.state +
                  ", " +
                  userDTO.address.country +
                  ", " +
                  userDTO.address.pinCode
                  }}
                </span>
              </div>

              <div class="accountDetails flex-column col-12 justify-content-center align-items-center">
                <button class="bgorange col-12 py-1 rounded17 font20 text-white" style="border: none"
                  (click)="toggleEditMode()">
                  Edit
                </button>
              </div>
            </ng-container>

            <!-- Editable form fields -->
            <ng-container *ngIf="isEditMode">
              <div class="accountDetails flex-column">
                <span class="font13">Full Name</span>
                <input type="text" [(ngModel)]="userDTO.firstName" placeholder="First Name" class="edit-input" />
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">Last Name</span>
                <input type="text" [(ngModel)]="userDTO.lastName" placeholder="Last Name" class="edit-input" />
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">Email Id</span>
                <input type="email" [(ngModel)]="userDTO.emailAddress" placeholder="Email Id" class="edit-input" />
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">Mobile Number</span>
                <input type="text" [(ngModel)]="userDTO.phoneNumber" placeholder="Mobile No" class="edit-input" />
              </div>
              <div class="accountDetails flex-column">
                <span class="font13">GST Number</span>
                <input type="text" [(ngModel)]="userDTO.gstNumber" placeholder="GST Number" class="edit-input" />
              </div>
              <div class="accountDetails flex-column" *ngIf="userDTO.whatsappNo">
                <span class="font13">WhatsApp Number</span>
                <input type="text" [(ngModel)]="userDTO.whatsappNo" placeholder="WhatsApp Number" class="edit-input" />
              </div>

              <!-- Save and Back Buttons -->
              <div class="accountDetails flex-column col-12 justify-content-center align-items-center">
                <button class="bgorange col-12 py-1 rounded17 font20 text-white" (click)="saveUsers()"
                  style="border: none">
                  Save
                </button>
                <button class="bgorange col-12 py-1 rounded17 font20 text-white mt-2" style="border: none"
                  (click)="cancelEdit()">
                  Cancel
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="showNotifications">
          <div class="notifications-list">
            <ng-container *ngIf="latestNotifications.length > 0; else noNotifications">
              <div class="d-flex flex-column mt-5">
                <div class="d-flex justify-content-center font-black font20 fw400 mb-3">
                  Notifications
                </div>
                <a class="notification-card my-3 d-flex align-items-center bgwhite rounded8"
                  *ngFor="let notification of latestNotifications"
                  [href]="'buyer/order/details/' + notification.orderIdentifier">
                  <div class="notification-icon">
                    <img src="../../assets/Notification.png" alt="Notification Icon" />
                  </div>
                  <div class="">
                    <div class="font16 fw400 font-black">
                      {{ notification.notificationText }}
                    </div>
                    <div class="text-grey font11 fw400">
                      {{ notification.createdAt | date : "d MMMM, YYYY" }}
                    </div>
                  </div>
                </a>
                <div class="d-flex flex-column rounded8">
                  <button class="rounded8 see-all bgorange font20 fw400 text-white py-2" style="border: none"
                    (click)="navigateToAllNotifications()">
                    See All
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-template #noNotifications>
              <div class="text-center py-lg-4">
                <div><img src="assets/big-bell.png" alt="" /></div>
                <div class="font24 mt-lg-3">
                  There are no notifications yet.
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>