import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, first } from 'rxjs';
import { RouteData } from '../app/resolvers/route-data.interface';
import { AuthService } from '../app/auth-service.service';
import { LocalStorageService } from './local-storage.service';
import { UsersService } from './user.service';
// import { AuthService } from './auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private baseUrl = environment.API_URL + '/user/product';
  private baseAPI = environment.API_URL;

  private pinCode = 800001;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private userService: UsersService
  ) {}

  getNewArrivals(): Observable<any> {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/getNewArrivals/${this.pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getAllCategory(): Observable<any> {
    const url = `${this.baseUrl}/getAllCategory`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getFaeturedCategory(): Observable<any> {
    const url = `${this.baseUrl}/getFeaturedCategory`;
    return this.http.get<any>(url).pipe(first());
  }
  getBestSeller(): Observable<any> {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/getBestSellers/${this.pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getAllProducts(): Observable<any> {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/getAllProducts/${this.pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllSellerProduct(): Observable<any> {
    const url = `${this.baseUrl}/getAllSellerProduct`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  deleteSellerListingById(id: number): Observable<any> {
    const url = `${this.baseUrl}/deleteSellerListingById/${id}`;
    return this.http
      .post<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  addPriceDetails(priceDetails: {
    productId: string;
    originalPrice: number;
    offerPrice: number;
    productColorId: number;
    lotSize: number;
    id: any;
  }): Observable<any> {
    const url = `${this.baseUrl}/addPriceDetails`;
    return this.http
      .post<any>(
        url,
        priceDetails, // Sending the request body
        {
          headers: this.authService.getAuthHeaders(),
        }
      )
      .pipe(first());
  }

  searchProducts(desc: string): Observable<any[]> {
    const url = `${this.baseUrl}/search/${desc}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getProductsByCategory(category: any): Observable<any> {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/searchByCategory/${category}/${this.pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  // console.log(this.pinCode);

  getProductsBySubCategory(subcategory: any): Observable<any> {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/searchBySubCategory/${subcategory}/${this.pinCode}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllProductBySubCategory(subCategoryId: any): Observable<any> {
    const url = `${this.baseUrl}/getProductBySubCategory/${subCategoryId}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllSubCategory(id: any): Observable<any> {
    const url = `${this.baseUrl}/getAllSubcategory/${id}`;
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }

  getAllBrand() {
    const url = environment.API_URL + '/user/system/getAllBrands';
    return this.http.get<any>(url).pipe(first());
  }

  getProductByBrand(id: string) {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/getByBrand/${id}/${this.pinCode}`;
    // console.log(url);
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getProductById(id: string, listing: string) {
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    }
    const url = `${this.baseUrl}/getProductById/${id}/${this.pinCode}/${listing}`;
    // console.log(url);
    return this.http
      .get<any>(url, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  rateProduct(
    id: any,
    rating: any,
    feedback: any,
    imageId: any,
    reviewHeader: any
  ) {
    const url = `${this.baseAPI}/rating/submit/${id}`;
    const req = {
      rating: rating,
      feedback: feedback,
      imageId: imageId,
      review_header: reviewHeader,
    };
    // console.log(url);
    return this.http
      .post<any>(url, req, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  uploadReviewImages(id: any, files: File[]) {
    const url = `${this.baseAPI}/rating/uploadimage/${id}`;
    const formData = new FormData();
    let filesArray: File[] = [];
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      filesArray.push(files[i]);
    }
    // console.log(url);
    return this.http
      .post<any>(url, formData, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  likeRating(id: any, like: number) {
    const url = `${this.baseAPI}/rating/likeordislike/${id}/${like}`;
    // console.log(url);
    return this.http
      .post<any>(url, null, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
  getQA(id: any) {
    console.log('API Request ID:', id); // Debugging
    // if (!id) {
    //   console.error('Error: ID is undefined');
    //   return;
    // }
    const url = `${this.baseAPI}/faq/getfaq/${id}`;
    return this.http.get(url).pipe(first());
  }
  submitQA(id: any, question: any, answer: any) {
    const url = `${this.baseAPI}/faq/add/${id}`;
    const req = {
      question: question,
      answer: answer,
    };
    return this.http
      .post<any>(url, req, {
        headers: this.authService.getAuthHeaders(),
      })
      .pipe(first());
  }
}
