<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Notifications
</div>

<div class="px-lg-5 py-lg-3">
  <div class="d-flex align-items-center mb-lg-3 mb-1 header-container ps-lg-2">
    <button
      class="bgorange text-white rounded-2 px-4 py-2 d-flex justify-content-center bordernone"
      onclick="history.back()"
    >
      Back
    </button>
    <div class="col-5 justify-content-end d-flex header-title">
      <!-- <h3 class="mb-0">Notifications</h3> -->
    </div>
  </div>
  <div class="contain mt-lg-3 mt-1 px-lg-0 mx-2 px-2 pt-2 mb-2">
    <ng-container *ngIf="notifications.length > 0; else noNotifications">
      <div
        class="d-flex flex-column"
        *ngFor="let notification of notifications; let last = last"
      >
        <div
          class="notification-item d-flex justify-content-between px-lg-4 py-lg-2 flex-column flex-md-row mb-2"
        >
          <a
            class="col-lg-10"
            [href]="'buyer/order/tracking/' + notification.referenceId"
          >
            {{ notification.notificationText }}
          </a>
          <div class="col-lg-2">
            {{ notification.createdAt | date : "short" }}
          </div>
        </div>
        <div *ngIf="!last" class="notification-line"></div>
      </div>
    </ng-container>
    <ng-template #noNotifications>
      <div class="text-center py-4">
        <div><img src="assets/big-bell.png" alt="" /></div>
        <div>There are no notifications yet.</div>
      </div>
    </ng-template>
  </div>
</div>
