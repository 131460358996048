<div class="carousel my-1">
  <div class="carousel-images" [style.transform]="transform">
    <div *ngFor="let item of items" class="carousel-image">
      <a href="{{ item.link }}"
        ><img [src]="item.src" [alt]="item.alt" style="object-fit: contain"
      /></a>
    </div>
  </div>
  <button class="carousel-arrow left" (click)="prevSlide()">
    <img src="../../assets/left-double-arrow.png" alt="" />
  </button>
  <button class="carousel-arrow right" (click)="nextSlide()">
    <img src="../../assets/right-double-arrow.png" alt="" />
  </button>
  <div
    class="carousel-dots text-center d-flex justify-content-center col-12 align-items-center"
  >
    <div
      class="bg-light-grey rounded-5 px-lg-3 px-2 py-lg-2 py-0 align-items-center"
      style="max-width: fit-content !important"
    >
      <span
        *ngFor="let dot of items; let i = index"
        [class.active]="currentIndex === i"
        (click)="goToSlide(i)"
      ></span>
    </div>
  </div>
</div>
