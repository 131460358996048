<div *ngIf="showSearch">
  <input
    type="text"
    placeholder="Search for a location"
    #mapSearchField
    id="pac-input"
  />
</div>

<div
  #mapContainer
  style="height: 600px"
  class="rounded2 justify-content-center align-items-center d-flex"
></div>
