import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-chat',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './whatsapp-chat.component.html',
  styleUrl: './whatsapp-chat.component.scss',
})
export class WhatsappChatComponent {
  showDesc: boolean = false;
}
