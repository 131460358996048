import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-product-category',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-category.component.html',
  styleUrl: './product-category.component.scss',
})
export class ProductCategoryComponent implements OnInit {
  categoryList: any[] = [];

  constructor(
    private productService: ProductService,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {}
  ngOnInit(): void {
    this.title.setTitle(
      'Product Categories | Power Bihar - Explore Electrical Products for Every Need'
    );
    // this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({
      property: 'og:title',
      content:
        'Product Categories | Power Bihar - Explore Electrical Products for Every Need',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Browse Power Bihar's wide range of electrical product categories. From wires and switches to lighting and appliances, we offer premium quality electrical products tailored to meet your needs. Discover top brands and reliable solutions for homes, businesses, and contractors in Bihar.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Electrical product categories, Power Bihar products, buy electrical products Bihar, electrical equipment Bihar, lighting solutions Bihar, electrical appliances Bihar, premium electrical brands Bihar.',
    });
    this.getAllCategory();
  }

  getAllCategory() {
    this.productService.getAllCategory().subscribe((data) => {
      this.categoryList = data;
      for (let i = 0; i < data.length; i++) {
        this.productService
          .getAllSubCategory(this.categoryList[i].id)
          .subscribe((data) => {
            this.categoryList[i].subCatList = data;
          });
      }
      // console.log(this.categoryList);
    });
  }
  goToCategory(category: any) {
    // console.log(category);
    this.router.navigate(['/category', category]);
  }
  goToSubCategory(subcategory: any) {
    this.router.navigate(['/subcategory', subcategory]);
  }
}
