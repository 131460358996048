import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { FormsModule } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { AuthService } from '../auth-service.service';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, map } from 'rxjs';
import { UsersService } from '../../services/user.service';
import { GoogleMapSearchComponent } from '../google-map-search/google-map-search.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, FormsModule, GoogleMapSearchComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  cartItemCount: number = 0;
  showBrandPopup: boolean = false;
  allRegion: any;
  navigateToProductDetails(id: any, listingId: any) {
    this.searchQuery = '';
    this.searchResults = [];
    this.router.navigate([`/product/${id}/${listingId}`]);
  }
  isHamburger = false;
  searchQuery: string = '';
  searchResults: any[] = [];
  allCategories: any;
  showCatPopUp: boolean = false;
  @ViewChild('categoryModal') modal: any;
  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private authService: LoginService,
    private elementRef: ElementRef,
    private router: Router,
    private modalService: NgbModal,
    private userService: UsersService
  ) {}
  ngOnInit(): void {
    this.cartService.cartItemCount$.subscribe((count) => {
      this.cartItemCount = count;
    });
    this.updateCartItemCount();
    this.getAllRegion();
  }
  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Click occurred outside the search results, close the results here
      this.searchResults = [];
    }
  }
  getAllRegion() {
    this.userService.getAllRegion().subscribe((data) => {
      this.allRegion = data;
    });
  }
  toggleHamburger() {
    this.isHamburger = !this.isHamburger;
  }
  searchProducts() {
    if (this.searchQuery.trim() !== '' && this.searchQuery.length > 3) {
      this.productService.searchProducts(this.searchQuery).subscribe(
        (data: any[]) => {
          this.searchResults = data;
        },
        (error) => {
          console.error('Error searching products:', error);
          this.searchResults = []; // Clear search results on error
        }
      );
    } else {
      // Clear search results if search query is empty
      this.searchResults = [];
    }
  }
  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      // Calculate total items count in the cart
      const totalCount = (cartList || []).reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      // Update cart item count in the service
      this.cartService.updateCartItemCount(totalCount);
    });
  }
  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }
  openCategoryModal() {
    this.productService.getAllCategory().subscribe((data) => {
      this.allCategories = data;
      // Create an array of observables for the subcategory API calls
      const subCategoryRequests = this.allCategories.map(
        (category: { id: any }) => {
          return this.productService.getAllSubCategory(category.id).pipe(
            map((subCategories) => ({
              ...category,
              subCategoryNames: subCategories.map(
                (subCategory: { subcategoryName: any; id: any }) => ({
                  id: subCategory.id, // Assuming the subcategory has an 'id' property
                  name: subCategory.subcategoryName, // Assuming the subcategory has a 'subcategoryName' property
                })
              ),
            }))
          );
        }
      );

      // Execute all the subcategory requests in parallel
      forkJoin(subCategoryRequests).subscribe((updatedCategories) => {
        this.allCategories = updatedCategories; // This will have categories with subCategoryNames
        // console.log(updatedCategories);
        this.showCatPopUp = true;
      });
      // console.log(this.allCategories);
    });
  }
  goToCategory(category: any) {
    // console.log(category);
    this.showCatPopUp = false;
    this.router.navigate(['/category', category]);
  }
  goToSubCategory(subcategory: any) {
    this.showCatPopUp = false;
    this.router.navigate(['/subcategory', subcategory]);
  }
  brandList: any;
  getBrandList() {
    this.productService.getAllBrand().subscribe((data) => {
      this.brandList = data;
      this.showBrandPopup = true;
    });
  }
  goToBrand(brandId: any) {
    this.showBrandPopup = false;
    this.router.navigate(['/brand', brandId]);
  }
  navigateToElectricianSearchPage() {
    this.router.navigate(['/electricianSearch']);
  }
}
