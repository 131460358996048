<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Your Orders
</div>

<div class="px-5 mx-lg-5 mx-0 mt-5">
  <div>
    <!-- Tabs & Search -->
    <div
      class="d-flex justify-content-lg-between justify-content-center align-items-center flex-lg-row flex-column"
    >
      <div class="order-tabs col-lg-7 col-12">
        <button
          class="tab-button"
          (click)="onStatusChange('live')"
          [ngClass]="{ active: currentStatus === 'live' }"
        >
          Live
        </button>
        <button
          class="tab-button"
          (click)="onStatusChange('cancelled')"
          [ngClass]="{ active: currentStatus === 'cancelled' }"
        >
          Cancelled
        </button>
        <button
          class="tab-button"
          (click)="onStatusChange('closed')"
          [ngClass]="{ active: currentStatus === 'closed' }"
        >
          Closed
        </button>
      </div>
      <div class="col-lg-5 col-12">
        <div class="d-flex flex-lg-row flex-column">
          <div class="col-lg-7 col-12">
            <div
              class="col-lg-12 col-12 my-lg-0 my-3 rounded12 d-flex search-container"
            >
              <img src="../../assets/leftsearch.png" class="search-icon" />
              <input
                type="text"
                placeholder="Search All Orders"
                name="search"
                class="ms-2 col-11 rounded12 search-bg search-input"
                [(ngModel)]="searchTerm"
                (ngModelChange)="filterOrders()"
              />
            </div>
          </div>
          <div
            class="col-lg-5 col-12 d-lg-flex d-none align-items-center ps-lg-4 ps-2 justify-content-end"
          >
            <button
              class="bgorange text-white search-button"
              (click)="filterOrders()"
            >
              Search Orders
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Orders List -->
    <div
      class="order-container"
      *ngIf="filteredOrders && filteredOrders.length > 0; else noOrders"
    >
      <!-- Use paginatedProducts if you want only current page orders -->
      <div
        class="rounded4 bg-light-grey-1 order-header mb-2"
        (click)="toggleOrderDetails(order)"
        *ngFor="let order of paginatedProducts"
      >
        <div class="d-flex align-items-center px-lg-5 px-3 py-3" role="button">
          <div class="col-2">
            <span class="font16 fw400 font-primary initcap">
              <span class="font-black">Status</span>
              <p class="mb-0">{{ order?.orderStatus }}</p>
            </span>
          </div>
          <div class="col-3">
            <span class="mb-0 font16 fw400 font-black">
              Order Date: {{ order?.orderReceivedAt | date : "d MMMM yyyy" }}
            </span>
          </div>
          <div class="col-2">
            <span class="mb-0 font16 fw400 font-black">
              Total Quantity: {{ order?.quantity }}
            </span>
          </div>
          <div class="col-2">
            <span class="mb-0 font16 fw400 font-black">
              Total Price:
              <span class="font20"
                >₹{{ order?.totalPrice | number : "1.2-2" }}</span
              >
            </span>
          </div>
          <div class="col-3 text-end">
            <span class="font-black font16 fw400"
              >Order# {{ order.orderIdentifier }}</span
            >
          </div>
        </div>

        <!-- Expanded Order Details -->
        <div class="order-item bg-white" *ngIf="order?.showDetails">
          <div *ngFor="let item of order?.relatedOrderItem; let i = index">
            <div class="item-card">
              <div
                class="d-flex justify-content-between px-lg-5 px-3 pt-2 align-items-center"
              >
                <div>
                  <span *ngIf="item?.arrivalDate">
                    Arriving on:
                    <p class="mb-0 font-primary">
                      {{ item.arrivalDate | date : "MMM-dd-yyyy" }}
                    </p>
                  </span>
                </div>
                <div>
                  Order Status:
                  <span class="font-primary">{{ item.orderItemStatus }}</span>
                </div>
                <div>
                  <span
                    class="font-black font16 fw400 hovertorange"
                    role="button"
                    (click)="
                      goToProductDetails(item?.productId, item?.identifier)
                    "
                  >
                    Write A Product Review
                  </span>
                </div>
              </div>
              <div class="d-flex px-lg-5 pb-2 align-items-center">
                <div class="col-2">
                  <ng-container
                    *ngIf="
                      item?.image && item.image[0]?.imagePublicUrl;
                      else defaultImage
                    "
                  >
                    <img
                      [src]="item.image[0].imagePublicUrl"
                      alt="Product Image"
                      class="img-fluid"
                      width="100"
                    />
                  </ng-container>
                  <ng-template #defaultImage>
                    <img
                      src="../../assets/default-product-image.jpg"
                      alt="Default Product Image"
                      class="img-fluid"
                      width="100"
                    />
                  </ng-template>
                </div>
                <div class="col-3">
                  <span class="font-black font16">{{
                    item?.productHeading
                  }}</span>
                </div>
                <div class="col-2">Quantity: {{ item?.quantity }}</div>
                <div class="col-2">
                  Unit Price: ₹{{ item?.unitPrice | number : "1.2-2" }}
                  <p>Price: ₹{{ item?.price | number : "1.2-2" }}</p>
                </div>
                <div class="col-3 d-flex justify-content-end">
                  <div class="ratings">
                    <div>
                      <i
                        class="fa fa-star"
                        *ngFor="
                          let star of getSafeArrayLength(
                            item?.ratings?.overallRating
                          )
                        "
                      >
                      </i>
                      <i
                        class="fa fa-star-o"
                        *ngFor="
                          let empty of getSafeArrayLength(
                            5 - (item?.ratings?.overallRating || 0)
                          )
                        "
                      >
                      </i>

                      <span>{{
                        item?.ratings.overallRating | number : "1.1-1"
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-end py-2">
                      {{ item?.ratings.totalRating }} Ratings
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        *ngIf="allBuyerOrder"
                        class="bgorange track-package text-white py-2"
                        (click)="navigateToOrderTracking(item.identifier)"
                      >
                        Track Package
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  order?.relatedOrderItem.length > 1 &&
                  i < order.relatedOrderItem.length - 1
                "
              >
                <hr class="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noOrders>
      <div class="text-center py-4">
        <p>There are no {{ currentStatus }} orders.</p>
      </div>
    </ng-template>

    <!-- Pagination Controls -->
    <div class="justify-content-center d-lg-flex d-none mb-2">
      <div class="d-flex gap-2 justify-content-center align-items-center">
        <button
          (click)="previousPage()"
          [disabled]="currentPage === 1"
          class="bordernone bg-transparent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M0.560453 8.45071C-0.108387 8.06959 -0.113845 7.10736 0.550627 6.71868L11.7423 0.172246C12.4068 -0.216431 13.2428 0.259959 13.2472 1.02975L13.3207 13.9952C13.3251 14.765 12.4945 15.2509 11.8257 14.8697L0.560453 8.45071Z"
              fill="#414040"
            />
          </svg>
        </button>
        <span *ngFor="let i of pageArray">
          <span
            role="button"
            (click)="goToPage(i)"
            class="index-page"
            [ngClass]="{ 'active-index': i === currentPage }"
          >
            {{ i === -1 ? "..." : i }}
          </span>
        </span>
        <button
          (click)="nextPage()"
          [disabled]="currentPage === totalPages"
          class="bordernone bg-transparent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
          >
            <path
              d="M13.5004 6.62451C14.1683 7.00726 14.1714 7.9695 13.506 8.35655L2.29834 14.8755C1.63292 15.2626 0.79804 14.7841 0.79556 14.0143L0.753788 1.04873C0.751308 0.278929 1.58309 -0.204878 2.25099 0.177872L13.5004 6.62451Z"
              fill="#414040"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
