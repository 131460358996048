<div class="d-flex col-12 justify-content-center font36 fw400 my-3">
  Our Brands
</div>
<div
  class="d-flex col-12 justify-content-center bg-light-grey-1 align-items-center"
>
  <div
    class="col-lg-11 col-12 font12 text-black overflow-hidden d-flex align-items-center justify-content-around py-2"
  >
    <!-- <span>Available in</span> -->
    <div class="marquee">
      <div class="marquee-content">
        <div *ngFor="let items of brandList" class="me-3">
          <div
            class="d-flex align-items-center justify-content-center"
            role="button"
            (click)="goToBrand(items.id)"
          >
            <div
              class="align-items-center d-flex flex-column img-bg justify-content-center px-4"
              style="min-width: 180px; min-height: 100px"
            >
              <img
                class="img-fluid"
                src="{{ items.brandLogoUrl }}"
                alt=""
                role="button"
                (click)="goToBrand(items.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Duplicate the content to create a continuous scroll effect -->
      <div class="marquee-content">
        <div *ngFor="let items of brandList" class="me-3">
          <div
            class="d-flex align-items-center justify-content-center"
            role="button"
            (click)="goToBrand(items.id)"
          >
            <div
              class="align-items-center d-flex flex-column img-bg justify-content-center px-4"
              style="min-width: 180px; min-height: 100px"
            >
              <img
                class="img-fluid"
                src="{{ items.brandLogoUrl }}"
                alt=""
                role="button"
                (click)="goToBrand(items.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
