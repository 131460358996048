<div style="background-color: #ea5c24" class="py-5"></div>
<div
  class="d-flex col-12 justify-content-center pt-0 pt-lg-5 grey-bg text-white px-3 px-lg-5 flex-column"
>
  <div class="flex-lg-row flex-column d-flex mt-0 mt-lg-4 text-center-1">
    <div class="col-12 col-lg-3 pt-2 ps-2 d-flex flex-column">
      <div>
        <img src="../../assets/footerlogo.png" class="mt-3" alt="logo" />
      </div>
      <div class="mt-4 flex-row d-flex text-center-1">
        <input
          type="text"
          placeholder="Email"
          style="
            background-color: transparent;
            border: none;
            border-bottom: 1px #ffffff40 solid !important;
            color: #fff;
          "
          class="col-12 col-lg-9"
        />
        <div style="margin-left: -14px">
          <img src="../../assets/Arrow 1.png" alt="subscribe" />
        </div>
      </div>
      <div
        class="d-flex flex-row mt-3 gap-2 align-items-center justify-content-around justify-content-lg-start"
      >
        <a class="ring"
          ><img
            src="../../assets/Instagram.png"
            class="mid-image"
            alt="Instagram"
        /></a>
        <a class="ring"
          ><img src="../../assets/Twitter.png" class="mid-image" alt="Twitter"
        /></a>
        <a class="ring"
          ><img
            src="../../assets/Facebook.png"
            class="mid-image"
            alt="Facebook"
        /></a>
        <a class="ring"
          ><img src="../../assets/Mail.png" class="mid-image" alt="Email"
        /></a>
        <a class="ring"
          ><img src="../../assets/Youtube.png" class="mid-image" alt="Youtube"
        /></a>
      </div>
    </div>
    <div class="col-12 col-lg-2 font20 mt-4 mt-lg-0 text-center-1">
      NAVIGATE
      <div class="d-flex flex-column gap-3 mt-1 pt-1 mt-lg-4 pt-lg-3 font16">
        <a href="/">Home</a>
        <a href="/aboutus">About Us</a>
        <a href="/newproducts">New Products</a>
        <a href="/bestseller">Best Seller</a>
        <a>Blog</a>
        <a href="/faq">FAQ</a>
        <a href="/contactus">Contact Us</a>
      </div>
    </div>
    <div class="col-12 col-lg-2 font20 mt-5 mt-lg-0">
      QUICK LINK
      <div class="d-flex flex-column gap-3 mt-1 pt-1 mt-lg-4 pt-lg-3 font16">
        <!-- <a>My Profile</a>
        <a>My Orders</a> -->
        <!-- <a>Cart</a> -->
        <!-- <a>Return Policy</a> -->
        <div *ngFor="let item of featuredProducts">
          <a href="/category/{{ item.categoryName }}">{{
            item.categoryName
          }}</a>
        </div>
        <a href="/privacypolicy">Privacy Policy</a>
      </div>
    </div>
    <div
      class="col-12 col-lg-5 font20 ms-0 ms-lg-5 ps-0 ps-lg-5 mt-5 mt-lg-0 d-flex flex-column"
    >
      CONTACT US
      <div class="d-flex flex-column mt-1 pt-1 mt-lg-4 pt-lg-3 font16">
        <p>Brain Crowd Software Pvt. Ltd.</p>
        <p>315 ,Blue Pearl, 2nd Floor, 7th Main, 14th B Cross Rd,</p>
        <p>HSR Layout, Bengaluru, Karnataka 560102</p>
      </div>
      <div class="d-flex flex-column font16">&nbsp;</div>
      <div class="d-flex flex-column mt-3 font16 align-itmes-baseline">
        <p class="">
          Business Hours<span class="ms-2"
            ><img
              src="../../assets/timeleft.png"
              class="mb-2"
              alt="businessTime"
          /></span>
        </p>
        <p>Monday to Saturday: 9:00 AM - 6:00 PM</p>
        <p>Sunday: Closed</p>
      </div>
    </div>
  </div>
  <div class="d-flex col-12 flex-column my-3">
    <hr style="opacity: 0.2; background: #fff" />
  </div>
</div>
