<div
  class="d-flex justify-content-center py-2 col-12 flex-column flex-lg-row"
  style="background-color: #ea5c24"
>
  <div style="min-width: 10px"></div>
  <div
    class="col-lg-4 col-12 font12 overflow-hidden d-flex align-items-center ps-2"
  >
    <!-- <span>Available in</span> -->
    <div class="marquee">
      <div class="marquee-content">
        <div *ngFor="let item of allRegion" class="me-3 shining-text">
          {{ item.name }}
        </div>
      </div>
      <!-- Duplicate the content to create a continuous scroll effect -->
      <div class="marquee-content">
        <div *ngFor="let item of allRegion" class="me-3 shining-text">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="fw400 font14 text-white col-lg-4 pt-2 pt-lg-0 col-12 px-2 px-lg-0 d-flex justify-content-lg-end justify-content-center mt-1 align-items-center"
    style="text-align: center"
  >
    Welcome to Power Bihar – Your Trusted Marketplace for Electrical Items in
    South Bihar!
  </div>

  <div
    class="justify-content-lg-end justify-content-between ps-3 ps-lg-0 pt-2 pt-lg-0 col-12 d-flex col-lg-4 me-2 gap-4 pe-3 pe-lg-5 align-items-center mb-1"
  >
    <span class="font13 text-white"
      ><img
        src="../../assets/Phone Call.png"
        class="pe-2"
        alt=""
        style="filter: invert(1)"
      />
      +91-90358 14717</span
    >
    <div class="d-flex flex-row gap-3">
      <a class=""
        ><img
          src="../../assets/Instagram.png"
          class="mid-image"
          alt="Instagram"
      /></a>
      <a class=""
        ><img src="../../assets/Twitter.png" class="mid-image" alt="Twitter"
      /></a>
      <a class=""
        ><img src="../../assets/Facebook.png" class="mid-image" alt="Facebook"
      /></a>
    </div>
  </div>
</div>
<div class="d-flex col-12 align-items-center flex-column flex-lg-row">
  <div
    class="col-lg-2 col-12 ms-lg-0 ps-lg-0 flex-row d-flex justify-content-between"
  >
    <a href="/"
      ><img
        src="../../assets/headerlogo.png"
        style="height: auto; margin-top: -3rem"
        alt="headerlogo"
      />
    </a>
    <div class="col text-end ham d-flex d-lg-none justify-content-end me-3">
      <div
        class="hamburger mt-3"
        [ngClass]="{ 'is-active': isHamburger }"
        type="button"
        aria-label="Menu"
        (click)="toggleHamburger()"
        *ngIf="!isHamburger"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        class="hamburger mt-2 font36 me-3 font-primary"
        [ngClass]="{ 'is-active': isHamburger }"
        type="button"
        aria-label="Close Menu"
        (click)="toggleHamburger()"
        *ngIf="isHamburger"
        style="transition: all 0.3s ease"
      >
        X
      </div>
      <div
        class="dropdown-content py-5"
        *ngIf="isHamburger"
        [ngClass]="{ 'dropdown-show': isHamburger }"
        (click)="toggleHamburger()"
        (mouseleave)="toggleHamburger()"
        style="min-height: 80vh"
      >
        <a class="font14 fw400" href="/">Home</a>
        <a class="font14 fw400" href="/aboutus">About Us</a>
        <a class="font14 fw400" href="/newproducts">New Products</a>
        <a class="font14 fw400" href="/bestseller">Best Seller</a>
        <a class="font14 fw400" href="/productcategory">Our Products</a>
        <!-- <a class="font14 fw400" href="/productcategory">Search By Category</a> -->
        <!-- <div class="font14 fw400">Our Brands</div> -->
        <a href="/contactus" class="font14 fw400">Contact Us</a>
        <a
          *ngIf="isLoggedIn()"
          href="/cart"
          class="cart-icon-container mx-1 mx-lg-3"
          style="filter: invert(1)"
        >
          <img src="../../assets/cartlogo.png" alt="Cart" />
          <span class="badge-pill badge bg-primary">{{ cartItemCount }}</span>
        </a>
        <a href="/myaccount" class="mb-2">My Account</a>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-lg-3 mt-4 me-0 me-lg-5 justify-content-lg-start justify-content-center d-flex"
  >
    <div class="col-lg-12 col-10 rounded12 d-flex">
      <input
        type="text"
        placeholder="Search products"
        name="search"
        class="p-2 ms-0 ms-lg-5 col-12 rounded12 search-bg"
        style="position: relative"
        [(ngModel)]="searchQuery"
        (input)="searchProducts()"
      />
      <img src="../../assets/Search.png" class="search-image" />
      <!-- <div class="search-results-container col-2"> -->
      <div
        *ngIf="searchResults.length > 0"
        class="search-results col-8 col-lg-3 ms-0 ms-lg-5 border-primary mt-4"
      >
        <div
          class="product-block two-line-text"
          *ngFor="let product of searchResults"
        >
          <div
            class="product-heading"
            (click)="
              navigateToProductDetails(product.productId, product.listingId)
            "
          >
            <ng-container
              *ngIf="
                product.productImageList && product.productImageList.length > 0
              "
            >
              <img
                class="rounded2"
                [src]="product.productImageList[0].imagePublicUrl"
                alt="Product Image"
                style="max-width: 2vw"
              />
            </ng-container>
            <ng-container
              *ngIf="
                !product.productImageList ||
                product.productImageList.length === 0
              "
            >
              <img
                src="../../../assets/no-images.jpg"
                alt=""
                style="max-width: 2vw"
              />
            </ng-container>
            {{ product.productHeading }}
          </div>
        </div>
      </div>
      <div
        *ngIf="searchResults.length <= 0 && searchQuery.length > 3"
        class="search-results col-8 col-lg-3 ms-0 ms-lg-5 border-primary mt-4 bg-light-grey-1 p-2 text-danger"
      >
        No Products found
      </div>
      <!-- <ng-template #noResults>
      <div class="no-results-message">No search results found.</div>
    </ng-template> -->
      <!-- </div> -->
    </div>
  </div>
  <div
    class="col-12 col-lg-3 mt-4 mb-4 mb-lg-0 me-0 justify-content-lg-start justify-content-center ms-0 ms-lg-2"
  >
    <app-google-map-search></app-google-map-search>
  </div>
  <div
    class="align-items-center col-2 d-lg-flex d-none justify-content-end ms-0 mt-4 ps-0 py-1"
  >
    <img
      src="../../assets/iso-quality.png"
      alt=""
      class="img-fluid"
      style="max-height: fit-content !important"
    />
  </div>
  <div
    class="align-items-center col-12 col-lg-2 d-lg-flex d-none gap-3 justify-content-end me-0 mt-4"
    style="margin-left: -5%"
  >
    <a
      *ngIf="isLoggedIn()"
      href="/cart"
      class="cart-icon-container mx-1 mx-lg-3"
    >
      <img src="../../assets/cartlogo.png" alt="Cart" />
      <span class="badge-pill badge bg-primary">{{ cartItemCount }}</span>
    </a>
    <a href="/myaccount" class="my-account-link"
      ><img src="../../assets/User.png" alt="user"
    /></a>
  </div>
</div>
<div
  class="d-lg-flex justify-content-lg-evenly mb-4 mt-4 pt-2 mx-0 px-0 d-none"
>
  <a class="font14 fw400" href="/">Home</a>
  <a class="font14 fw400" href="/aboutus">About Us</a>
  <a class="font14 fw400" href="/newproducts">New Products</a>
  <a class="font14 fw400" href="/bestseller">Best Seller</a>
  <a class="font14 fw400" href="/productcategory">Our Products</a>
  <div role="button" class="font14 fw400 a" (mouseenter)="openCategoryModal()">
    Search By Category &#x2228;
  </div>
  <div role="button" class="font14 fw400 a" (mouseenter)="getBrandList()">
    Our Brands &#x2228;
  </div>
  <a href="/contactus" class="font14 fw400">Contact Us</a>
</div>
<div
  class="position-fixed bg-white border-grey rounded-2"
  style="min-width: fit-content !important; top: 20%; left: 25%; z-index: 1000"
  *ngIf="showCatPopUp"
  role="button"
  (mouseleave)="showCatPopUp = false"
>
  <div class="d-flex flex-wrap" style="min-height: 30vh; max-width: 50vw">
    <div
      *ngFor="let cat of allCategories; let i = index"
      [ngClass]="{ 'bg-light-grey-1': i % 2 === 0 }"
      class="px-3 font14 py-3 fw500"
      style="
        min-width: 10vw;
        max-width: 10vw;
        flex: 1 0 calc(20% - 1rem);
        box-sizing: border-box;
        min-height: 30vh;
        text-transform: capitalize;
      "
    >
      <div
        role="button"
        (click)="goToCategory(cat.categoryName); showCatPopUp = false"
      >
        {{ cat.categoryName }}
      </div>
      <div class="border-top-grey">
        <div
          *ngFor="let subCat of cat.subCategoryNames"
          class="font13 fw400 py-1 d-flex flex-column gap-2"
        >
          <div role="button" (click)="goToSubCategory(subCat.id)">
            {{ subCat.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="position-fixed border-grey rounded-2"
  style="
    min-width: fit-content !important;
    top: 20%;
    left: 60%;
    z-index: 1000;
    background-color: #f2f2f2;
  "
  *ngIf="showBrandPopup"
  role="button"
  (mouseleave)="showBrandPopup = false"
>
  <div class="d-flex flex-row flex-wrap" style="min-height: 30vh">
    <div
      class="grid-container"
      style="
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 10px;
      "
    >
      <div
        *ngFor="let item of brandList; let i = index"
        class="px-3 font14 py-3 fw500"
        style="text-align: center"
      >
        <div role="button" (click)="goToBrand(item.id)">
          <img
            src="{{ item.brandLogoUrl }}"
            alt="{{ item.brandName }}"
            style="max-height: 2rem"
          />
        </div>
      </div>
    </div>
  </div>
</div>
