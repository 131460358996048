<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  Privacy Policy
</div>
<div
  id="privacy-policy"
  class="d-flex justify-content-center align-items-center flex-column col-12 gap-4 mt-5"
>
  <div class="col-lg-8 col-10" id="introduction">
    <!-- <h2>Privacy Policy</h2> -->
    <p>
      Welcome to Power Bihar. Your privacy is important to us, and we are
      committed to protecting the personal information you share with us. This
      Privacy Policy explains how we collect, use, and protect your information
      when you use our platform.
    </p>
  </div>

  <div class="col-lg-8 col-10" id="data-collection">
    <h3>Information We Collect</h3>
    <ul>
      <li>
        <strong>Personal Information:</strong> When you register or interact
        with our services, we may collect information such as your name, email
        address, phone number, and address.
      </li>
      <li>
        <strong>Location Data:</strong> We use Google Maps to enhance your
        experience. By using our services, you consent to the collection of
        location data, which helps us display relevant products and services.
      </li>
      <li>
        <strong>Usage Data:</strong> We may collect information about your
        interactions with our website, such as pages viewed, search queries, and
        time spent on the platform.
      </li>
    </ul>
  </div>

  <div class="col-lg-8 col-10" id="data-usage">
    <h3>How We Use Your Information</h3>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
      <li>To provide and improve our services.</li>
      <li>To personalize your experience on our platform.</li>
      <li>To analyze usage trends and enhance functionality.</li>
      <li>To comply with legal and regulatory obligations.</li>
    </ul>
  </div>

  <div class="col-lg-8 col-10" id="google-maps">
    <h3>Use of Google Maps</h3>
    <p>
      Power Bihar integrates Google Maps to provide location-based services. By
      using this functionality, you agree to the Google Maps
      <a
        href="https://www.google.com/intl/en-US_US/help/terms_maps.html"
        target="_blank"
        >Terms of Service</a
      >
      and Google's
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >. Google may collect location and usage data as part of this integration.
    </p>
  </div>

  <div class="col-lg-8 col-10" id="data-sharing">
    <h3>Sharing of Information</h3>
    <p>
      We do not sell your personal information. However, we may share your
      information with:
    </p>
    <ul>
      <li>
        Service providers and partners who assist in delivering our services.
      </li>
      <li>Law enforcement or regulatory bodies, if required by law.</li>
      <li>Google Maps, as part of location-based services.</li>
    </ul>
  </div>

  <div class="col-lg-8 col-10" id="data-protection">
    <h3>Data Protection</h3>
    <p>
      We implement industry-standard measures to secure your data. However, no
      system is entirely secure, and we cannot guarantee the absolute security
      of your information.
    </p>
  </div>

  <div class="col-lg-8 col-10" id="user-rights">
    <h3>Your Rights</h3>
    <p>As a user, you have the following rights:</p>
    <ul>
      <li>Access and update your personal information.</li>
      <li>
        Request deletion of your data, subject to legal and operational
        requirements.
      </li>
      <li>Withdraw consent for data collection at any time.</li>
    </ul>
  </div>

  <div class="col-lg-8 col-10" id="policy-updates">
    <h3>Changes to this Privacy Policy</h3>
    <p>
      We may update this Privacy Policy periodically. Any changes will be posted
      on this page, and your continued use of the platform constitutes
      acceptance of these changes.
    </p>
  </div>

  <div class="col-lg-8 col-10" id="contact">
    <h3>Contact Us</h3>
    <p>
      If you have any questions about this Privacy Policy, please contact us at:
    </p>
    <p>Email: contact&#64;brain-crowd.com</p>
    <p>Phone: +91-9035814717</p>
  </div>
</div>
