import { Component } from '@angular/core';
import { AboutusComponent } from '../aboutus/aboutus.component';
import { MapComponent } from '../map/map.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contactus',
  standalone: true,
  imports: [MapComponent],
  templateUrl: './contactus.component.html',
  styleUrl: './contactus.component.scss',
})
export class ContactusComponent {
  constructor(private title: Title, private meta: Meta) {}
  ngOnInit(): void {
    this.title.setTitle(
      'Contact Us | Power Bihar - Connect for Electrical Products & Services in Bihar'
    );
    // this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({
      property: 'og:title',
      content:
        'Contact Us | Power Bihar - Connect for Electrical Products & Services in Bihar',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Get in touch with Power Bihar for all your electrical needs. Whether you’re looking to buy high-quality electrical products or book skilled electricians in your area, our team is here to help. Contact us today for reliable support and personalized solutions in Bihar.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Contact Power Bihar, electrical products in Bihar, electrician services in Bihar, Bihar electrical solutions, get support Power Bihar, electrical marketplace Bihar, connect with Power Bihar.',
    });
  }
}
