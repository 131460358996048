import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProductDetailsDTO } from '../../dto/ProductDetailsDTO';

@Component({
  selector: 'app-product-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-rating.component.html',
  styleUrl: './product-rating.component.scss',
})
export class ProductRatingComponent implements OnInit, OnChanges {
  @Input() product: any;
  fiveWidth: number = 0;
  fourWidth: number = 0;
  threeWidth: number = 0;
  twoWidth: number = 0;
  oneWidth: number = 0;

  ngOnInit(): void {
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product'] && changes['product'].currentValue) {
      this.calculateWidth();
    }
  }

  calculateWidth() {
    this.oneWidth = 0;
    this.twoWidth = 0;
    this.threeWidth = 0;
    this.fourWidth = 0;
    this.fiveWidth = 0;
    if (this.product.avgRatings && this.product.ratings) {
      const totalCount = this.product.avgRatings.totalRating;
      this.product.ratings.forEach((response: any) => {
        const rating: number = response.rating;
        if (rating == 1) {
          this.oneWidth++;
        } else if (rating == 2) {
          this.twoWidth++;
        } else if (rating == 3) {
          this.threeWidth++;
        } else if (rating == 4) {
          this.fourWidth++;
        } else if (rating == 5) {
          this.fiveWidth++;
        }
      });
      this.oneWidth = (this.oneWidth / totalCount) * 100;
      this.twoWidth = (this.twoWidth / totalCount) * 100;
      this.threeWidth = (this.threeWidth / totalCount) * 100;
      this.fourWidth = (this.fourWidth / totalCount) * 100;
      this.fiveWidth = (this.fiveWidth / totalCount) * 100;
    }
  }
}
