import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-homepageoffers',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './homepageoffers.component.html',
  styleUrl: './homepageoffers.component.scss',
})
export class HomepageoffersComponent {
  currentLeftIndex = 0;
  currentRightIndex = 0;
  currentBottomRightIndex = 0;

  leftOfferPics: any[] = [
    {
      url:'./assets/homepageoffer/loffer1.png',
    },
    {
      url:'./assets/homepageoffer/loffer2.png',
    },
    {
      url:'./assets/homepageoffer/loffer3.png',
    }
  ];
  rtOfferPics: any[] = [
    {
      url:'./assets/homepageoffer/rtoffer1.png',
    },
    {
      url:'./assets/homepageoffer/rtoffer2.png',
    },
    {
      url:'./assets/homepageoffer/rtoffer3.png',
    }
  ];
  rbOfferPics: any[] = [
    {
      url:'./assets/homepageoffer/rboffer1.png',
    },
    {
      url:'./assets/homepageoffer/rboffer2.png',
    },
    {
      url:'./assets/homepageoffer/rboffer3.png',
    }
  ];
  goToLeftSlide(index: number) {
    this.currentLeftIndex = index;
  }

  goToRightSlide(index: number) {
    this.currentRightIndex = index;
  }

  goToBottomRightSlide(index: number) {
    this.currentBottomRightIndex = index;
  }
}
