import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-qa',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './product-qa.component.html',
  styleUrl: './product-qa.component.scss',
})
export class ProductQaComponent implements OnInit {
  @Input() prodQA: any[] = [];
  @Input() productId: any;
  @ViewChild('submitQAModal') modal: any;
  constructor(
    private modalService: NgbModal,
    private productService: ProductService
  ) {}
  initialQACount: number = 1;
  question: any = '';
  ngOnInit(): void {
    console.log('Received prodQA:', this.prodQA);

    if (!this.prodQA || this.prodQA.length === 0) {
      console.warn('prodQA is empty or not assigned!');
    } else if (!this.prodQA[0].productId) {
      console.warn('Product ID is missing in prodQA[0]!');
    }

    // this.initialQACount = 4;
  }
  viewMore() {
    if (this.initialQACount < this.prodQA.length - 1) {
      this.initialQACount = Math.min(
        this.initialQACount + 5,
        this.prodQA.length - 1
      );
    }
  }
  viewLess() {
    this.initialQACount = 1;
  }
  openQA() {
    this.modalService.open(this.modal);
  }
  submitQA(id: any) {
    if (!id) {
      console.error('Product ID is missing!');
      return;
    }

    if (!this.question.trim()) {
      console.warn('Question cannot be empty!');
      return;
    }

    this.productService.submitQA(id, this.question, null).subscribe((data) => {
      // Dynamically add the new question to prodQA
      this.prodQA.unshift({
        question: this.question,
        productId: id,
      });

      // Update initialQACount to ensure correct behavior of See More / See Less buttons
      this.initialQACount = Math.min(
        this.initialQACount + 1,
        this.prodQA.length
      );

      // Reset the question input
      this.question = '';
      this.modalService.dismissAll();
    });
  }
}
