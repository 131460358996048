import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aboutus.component.html',
  styleUrl: './aboutus.component.scss',
})
export class AboutusComponent {
  showVision = true;
  showMission = false;
  toggleDiv(name: any) {
    if (name == 'vision') {
      this.showVision = true;
      this.showMission = false;
    } else {
      this.showMission = true;
      this.showVision = false;
    }
  }
}
