<div class="d-flex col-12 align-items-center justify-content-center">
  <div class="sale col-lg-8 col-11 rounded6 px-5">
    <div
      class="d-flex justify-content-between align-items-center my-5 gap-4 flex-column flex-lg-row"
    >
      <div class="font24 fw400 lh35">Weekend Sale!</div>
      <div class="px-lg-3 px-0 orange-bg rounded4">
        <span class="px-5 font20 fw400 lh-1"
          ><app-countdown></app-countdown
        ></span>
      </div>
    </div>
    <div class="col-12 card-1">
      <app-product-card-sale></app-product-card-sale>
    </div>
  </div>
</div>
