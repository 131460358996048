<div class="d-flex col-12 justify-content-center font36 fw400 my-5">
  Our Brands
</div>
<div class="mt-5 scrollDiv">
  <div
    class="d-lg-flex justify-content-center align-items-center justify-content-lg-between bg-light-grey py-5 mx-5 mx-lg-0">
    <div class="col-lg-2" *ngFor="let item of brandList" style="max-height: 3rem">
      <div class="text-center my-4 my-lg-0" role="button" (click)="goToBrand(item.id)">
        <img src="{{ item.brandLogoUrl }}" alt="{{ item.brandName }}" style="max-height: 3rem" />
      </div>
    </div>
  </div>
</div>